import { Contract, Utxo, TransactionBuilder, ElectrumNetworkProvider, SignatureTemplate, Network, Unlocker } from 'cashscript';
import { encodeLockingBytecodeP2pkh, lockingBytecodeToCashAddress, hexToBin, binToHex, encodeTransaction, cashAddressToLockingBytecode, decodeTransaction, OpcodesBCH, encodeTransactionInput, encodeTokenPrefix, createVirtualMachineBCH, summarizeDebugTrace, stringifyDebugTraceSummary } from '@bitauth/libauth';
import { fetchHistory, fetchPendingTransactions, fetchBalance, fetchUnspentTransactionOutputs } from '@electrum-cash/protocol';
import toTokenAddress from "../functions/toTokenAddress";
import { MasterCategoryID } from '../constants/values'

interface ContractBadgerMintParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  usersAddress: string;
  contractBadgerMint: Contract | undefined;
  signTransaction: (options: any) => Promise<unknown>;
  badger: Utxo;
  hasDiscount: boolean;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function contractBadgerMinter({ electrumServer, usersAddress, contractBadgerMint, signTransaction, badger, hasDiscount, setError }: ContractBadgerMintParams) {
// Helper function to convert hex string to Uint8Array
function hexStringToUint8Array(hexString: string): Uint8Array {
  if (hexString.length % 2 !== 0) {
    throw new Error("Hex string must have an even length");
  }
  const array = new Uint8Array(hexString.length / 2);
  for (let i = 0; i < hexString.length; i += 2) {
    array[i / 2] = parseInt(hexString.substr(i, 2), 16);
  }
  return array;
}

  // Convert hex to big-endian and then to decimal
  const hexLEToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };
  function hexToUint8Array(hexString: any) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  if (electrumServer && contractBadgerMint && badger) {
    //const badgerID = {hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? '0'} 

    //Creating lockingBytecode for contract address
    const contractLockingBytecodeResult = cashAddressToLockingBytecode(contractBadgerMint.address);
    if (typeof contractLockingBytecodeResult === 'string') {
      throw new Error(`Failed to convert CashAddress to locking bytecode: ${contractLockingBytecodeResult}`);
    }
    //Creating lockingBytecode for usersAddress
    //const lockingBytecodeResult = cashAddressToLockingBytecode(usersAddress);
    const lockingBytecodeResult = cashAddressToLockingBytecode(usersAddress);
    if (typeof lockingBytecodeResult === 'string') {
      throw new Error(`Failed to convert CashAddress to locking bytecode: ${lockingBytecodeResult}`);
    }
    
    //Get users UTXOs
    const userUtxos = await electrumServer.getUtxos(usersAddress);
    console.log('user UTXOs:');
    console.log(userUtxos);

    let userUTXO: Utxo;
    let badgerCoinUTXO: Utxo | null = null;
    if (hasDiscount) {
      //Find pure BCH UTXO above 1502000
      userUTXO = userUtxos.find(
        utxo => utxo.satoshis >= (1502000n) && !utxo.token,
      )!; //'!' assumes will always be found
      console.log('selected pledge utxo: ');
      console.log(userUTXO);
  
      if (!userUTXO) {
        console.log('No compatible BCH UTXO found from users wallet');
        setError(`No UTXO found in your wallet with at least 0.01502 BCH`);
        return;
      }

      //Find 10 BadgerCoin UTXO
      badgerCoinUTXO = userUtxos.find(
        utxo => utxo.token?.category === MasterCategoryID   //must be badger categoryID
        && utxo.token?.amount >= 10                         //must have badgerCoins
        && !utxo.token?.nft?.commitment                     //must not have a commitment (be an NFT)
      )!; //'!' assumes will always be found
      console.log('selected badgerCoin utxo: ');
      console.log(badgerCoinUTXO);
  
      if (!userUTXO) {
        console.log('No compatible badgerCoin UTXO found from users wallet');
        setError(`No UTXO found in your wallet with at least 10 BadgerCoins`);
        return;
      }

    } else {  //does not have BadgerCoin discount
      //Find pure BCH UTXO above 3002000
      userUTXO = userUtxos.find(
        utxo => utxo.satoshis >= (3002000n) && !utxo.token,
      )!; //'!' assumes will always be found
      console.log('selected user utxo: ');
      console.log(userUTXO);
  
      if (!userUTXO) {
        console.log('No compatible BCH UTXO found from users wallet');
        setError(`No UTXO found in your wallet with at least 0.03002 BCH`);
        return;
      }
    }

    const badgerNFTDetails: TokenDetails = {     
      amount: badger.token?.amount!,  
      category: badger.token?.category!,  
      nft: {
        capability: badger.token?.nft?.capability!, 
        commitment: badger.token?.nft?.commitment!   
      }
    };

    //const privKey = '';   //cashonize
    //const userSig = new SignatureTemplate(privKey);

    const userSig = new SignatureTemplate(Uint8Array.from(Array(32)));           // empty signature as placeholder for building process. walletconnect will replace sig later
    const usersTokenAddress = toTokenAddress(usersAddress);
    //const adminAddress = 'bitcoincash:qpe09njvxvvzazp7jtktf9ya76f9jgqw7yxwk56zl4';
    //const adminAddress = 'bchtest:qpe09njvxvvzazp7jtktf9ya76f9jgqw7yzujnc4cf';
    //const adminAddress = 'bchtest:qzd2m8g9r59nas0tyq00fw0rylfq77amzscxapynpy';
    //const adminAddress = 'bitcoincash:qrz72v6nn0dvlrm6gfsxlx5nle5ycejwhcmhkh3wyy';  //extra paytaca wallet made just for NFT mints, didn't use
    const adminAddress = 'bitcoincash:qqnhqxy23rm4xcx3a9td0lefpmc7pat8su3j24rf6k';
    
    const transaction = contractBadgerMint?.functions.mint()
    console.log('hasDiscount: ', hasDiscount);
    if (hasDiscount == true) {
      if (badgerCoinUTXO != null) {
        transaction.from(badger)                               // badgerNFT utxo
        .fromP2PKH(userUTXO, userSig)                          // userPayment
        .fromP2PKH(badgerCoinUTXO, userSig)                    // badgerCoin discount
        .to(usersTokenAddress, badger.satoshis, badgerNFTDetails)        // send output0 back to contracts address with pledge minus miner fee
        .to(adminAddress, 1500000n)                            // send output1 to users tokenAddress with 1000sats and NFT details
        .withoutChange()                                       // disable automatic change output back to user (change handling below)
        .withoutTokenChange()                                  // disable automatic change output for unused input NFTs (allow implicit burn)

        const bchChange = userUTXO.satoshis - 1501000n;     // how many sats remain on users utxo after payment+removing 1000sats miner fee
        if (bchChange >= 1000n) {                          // if change is over dust limit then send it back to user. if not, miners keep it
          transaction.to(usersAddress, bchChange);         
        }

        const tokenChange = badgerCoinUTXO.token?.amount! - 10n;     // how many BadgerCoins remain on users utxo after removing 10
        if (tokenChange > 0n) {                                      // if BadgerCoin change remains
          const badgerCoinChangeDetails: TokenDetails = {     
            amount: tokenChange,  
            category: badgerCoinUTXO.token?.category!,  
          };
          transaction.to(usersTokenAddress, badgerCoinUTXO.satoshis, badgerCoinChangeDetails);  //implicit burns the 10 Badgercoins and returns rest to user
        }

      } else {
        console.log('discount was set but no badgerCoin UTXO was found');
        setError(`No UTXO found in your wallet with at least 10 BadgerCoins`);
        return undefined;
      }

    } else {
      transaction.from(badger)                                        // contractUTXO utxo
      //.fromP2PKH(userUTXO, userSig)                                   // used for privtekey signing
      .fromP2PKH(userUTXO, userSig)                                   // used for privtekey signing
      .to(usersTokenAddress, badger.satoshis, badgerNFTDetails)       // send badgerNFT to buyers address
      .to(adminAddress, 3000000n)                                     // send mint payment to admin address
      .withoutChange()                                                // disable automatic change output back to user (change handling below)
      .withoutTokenChange()                                           // disable automatic change output for unused input NFTs (allow implicit burn)
      //.withTime(0);
      
      const bchChange = userUTXO.satoshis - 3001000n;                 // how many sats remain on users utxo after removing 1000sats miner fee
      if (bchChange >= 1000n) {                                       // if change is over dust limit then send it back to user. if not, miners keep it
        transaction.to(usersAddress, bchChange);         
      }
    }
      
    //console.log(transaction);
    //const txid = await transaction.send();
    //console.log(txid);

    console.log('transaction pre-build: ');
    console.log(transaction);
    try {                                                             // build the transaction we created
      const rawTransactionHex = await transaction.build();                                  

      //for walletconnect
      const decodedTransaction = decodeTransaction(hexToBin(rawTransactionHex));            
      if (typeof decodedTransaction === "string") {
        setError(`No suitable utxos found for minting. Try to consolidate your utxos!`);
        throw ("No suitable utxos found for minting. Try to consolidate your utxos!");
      }

      //reset input sigs to empty for walletconnect
      decodedTransaction.inputs[1].unlockingBytecode = Uint8Array.from([]);
      if (decodedTransaction.inputs.length == 3) {  //reset optional badgercoin discount input
        decodedTransaction.inputs[2].unlockingBytecode = Uint8Array.from([]);
      }

      console.log('decodedTransaction: ');
      console.log(decodedTransaction);

      // construct new transaction object for SourceOutputs, for stringify & not to mutate current network provider 
      let listSourceOutputs: any = [];
      if (decodedTransaction.inputs.length == 3) {
        console.log('input length is 3 [BadgerCoin discount]');
        listSourceOutputs = [{
          ...decodedTransaction.inputs[0],
          lockingBytecode: (cashAddressToLockingBytecode(contractBadgerMint.address) as { bytecode: Uint8Array }).bytecode,
          valueSatoshis: BigInt(badger.satoshis),
          contract: {
            abiFunction: transaction.abiFunction,
            redeemScript: contractBadgerMint.redeemScript,
            artifact: contractBadgerMint.artifact,     
          }
        }, {
          ...decodedTransaction.inputs[1],
          lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
          valueSatoshis: BigInt(userUTXO.satoshis),
        }, {
          ...decodedTransaction.inputs[2],
          lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
          valueSatoshis: BigInt(badgerCoinUTXO!.satoshis),
          token: {
            amount: badgerCoinUTXO?.token?.amount,
            category: hexToBin(MasterCategoryID)
          }
        }];

      } else {
        console.log('length is 2');
        listSourceOutputs = [{
          ...decodedTransaction.inputs[0],
          lockingBytecode: (cashAddressToLockingBytecode(contractBadgerMint.address) as { bytecode: Uint8Array }).bytecode,
          valueSatoshis: BigInt(badger.satoshis),
          contract: {
            abiFunction: transaction.abiFunction,
            redeemScript: contractBadgerMint.redeemScript,
            artifact: contractBadgerMint.artifact,     
          }
        }, {
          ...decodedTransaction.inputs[1],
          lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
          valueSatoshis: BigInt(userUTXO.satoshis),
        }];
      }

      console.log('sourceOutputs:');
      console.log(listSourceOutputs);

      //create transaction object to give for signing
      const userPrompt = "Mint Badger #" + hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0") + " for " + (hasDiscount ? "0.015" : "0.03") + " BCH";
      const wcTransactionObj = {
        transaction: decodedTransaction,
        sourceOutputs: listSourceOutputs,
        broadcast: false,
        userPrompt: userPrompt
      };
      console.log(wcTransactionObj);

      //pass object to walletconnect for user to sign
      const signResult: any = await signTransaction(wcTransactionObj);

      return signResult;

    //for privatekey signing
      //console.log('finished cashStarterPledge()');
      //return rawTransactionHex;

    } catch (error) {
      console.log('managerInitialize(): tx build failed: ' + error);
      setError(`Unspecified error during contractBadgerMinter`);
    }
  }
}
  
export default contractBadgerMinter;