import React from 'react';
import styled from 'styled-components';

interface ColorChangingStatProps {
  value: number;
}

const interpolateColor = (color1: string, color2: string, factor: number) => {
  const int1 = parseInt(color1.slice(1), 16);
  const int2 = parseInt(color2.slice(1), 16);
  const r1 = (int1 >> 16) & 0xFF;
  const g1 = (int1 >> 8) & 0xFF;
  const b1 = int1 & 0xFF;
  const r2 = (int2 >> 16) & 0xFF;
  const g2 = (int2 >> 8) & 0xFF;
  const b2 = int2 & 0xFF;
  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));
  return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
};

const getColorForValue = (value: number) => {
  const normalizedValue = Math.max(0, Math.min(10, value)) / 10;
  return interpolateColor('#b30707', '#00bf2e', normalizedValue);
};

interface StyledStatProps {
  value: number;
}

const ColorChangingStat: React.FC<ColorChangingStatProps> = ({ value }) => {
  return (
    <StyledStat value={value}>
      {value}
    </StyledStat>
  );
};

const StyledStat = styled.div<StyledStatProps>`
  background-color: ${props => getColorForValue(props.value)};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
  font-weight: 700;
`;

export default ColorChangingStat;