import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BadgerGIF from '../../assets/img/badgerGIF.gif';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import BCHLogo500 from '../../assets/img/bch500.png';
import TelegramLogo from '../../assets/img/telegram.png';
import BadgerLogo from '../../assets/img/badgercoin.png';
import BadgerLogo100 from '../../assets/img/badgercoin100.png';
import HoneyLogo100 from '../../assets/img/honey100.png';
import HoneyLogo500 from '../../assets/img/honey512.png';
import SayoshiLogo from '../../assets/img/sayoshi100.png';
import BadgerBanner from '../../assets/img/badgerCoinBanner2.png';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract } from 'cashscript';
import ErrorNotification from '../../components/ErrorNotification';
import Spinner from '../../assets/img/spinner.gif';
import ScrollingUTXOs from '../../components/ScrollingUTXOs';
//blockchain stuff
import ArtifactBadgerContract from '../../contracts/BadgerStake/lockupFarmV29.json';
import contractBadgerUnlock from '../../functions/contractBadgerUnlock';
import contractBadgerLock from '../../functions/contractBadgerLock';
import contractBadgerWithdraw from '../../functions/contractBadgerWithdraw';
import { encodeCashAddress, stringify, decodeCashAddress } from '@bitauth/libauth';
import { AddressBadgerStake, MasterCategoryID } from '../../constants/values'

interface LockUtxo extends Utxo {
  startHeight: number;
  lockedBlocks: number;
  timeLeft: string;
}
interface SignMessageParams {
  message: string;
  userPrompt?: string;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [isPayoutAddressInvalid, setIsPayoutAddressInvalid] = useState(false);
  const [stringLockAmount, setStringLockAmount] = useState<string>("0");
  const [userStakeLength, setUserStakeLength] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [newFee, setNewFee] = useState<string>("");
  const [contractsOK, setContractsOK] = useState(false);
  const [contractBadger, setContractBadger] = useState<Contract>();
  const [numberMinted, setNumberMinted] = useState<number>(0);
  const [contractStakeUTXOs, setContractStakeUTXOs] = useState<Utxo[]>([]);
  const [userStakeUTXOs, setUserStakeUTXOs] = useState<LockUtxo[]>([]);
  const [otherStakeUTXOs, setOtherStakeUTXOs] = useState<LockUtxo[]>([]);
  const [userStakedAmount, setUserStakedAmount] = useState<BigInt>(0n);
  const [otherStakedAmount, setOtherStakedAmount] = useState<BigInt>(0n);
  const [totalStakes, setTotalStakes] = useState<number>(0);
  const [totalStakedAmount, setTotalStakedAmount] = useState<BigInt>(0n);
  const [badgersMinted, setBadgersMinted] = useState<number>(0);
  const [badgercoinSupply, setBadgercoinSupply] = useState<BigInt>(0n);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [blockHeight, setBlockHeight] = useState<number>(0);
  const [usersBadgerCoinBalance, setUsersBadgerCoinBalance] = useState<BigInt>(0n);
  const [usersHoneyBalance, setUsersHoneyBalance] = useState<BigInt>(0n);
  const [error, setError] = useState('');
  const [unlockPending, setUnlockPending] = useState(false);
  const [lockPending, setLockPending] = useState(false);
  const [keys, setKeys] = useState<number[]>(Array.from({ length: otherStakeUTXOs.length }, () => Math.random()));

  // Convert hex to big-endian and then to decimal
  const LEhexToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  function hexToUint8Array(hexString: any) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  function hexLEToBigInt(hexLE: string): bigint {
    // Convert the hex string from little-endian to big-endian
    const hexBE = hexLE.match(/.{2}/g)?.reverse().join('') ?? '0';
    // Convert the big-endian hex string directly to a BigInt
    return BigInt('0x' + hexBE);
  }

  //click Details to open campaign details page
  const handleDetailsClick = (id: number) => {
    navigate(`/campaign/${id}`);
  };

  // Converts a hex string to little-endian format
  const toLittleEndian = (hex: string, byteLength: number) => {
    const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
    const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
    return hexArray.join(''); // Join the array back into a string
  };

  //convert address to pubkeyhash
  function getAddressesPKH(address: string) {
    try {
      const decoded = decodeCashAddress(address);
      if (typeof decoded === 'string') {
        console.error('Invalid address format');  // Handle error - decoded should be an object for a valid address
        return null;
      }

      // Convert Uint8Array to a hexadecimal string
      const pubkeyhashHex = Array.from(decoded.payload)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return pubkeyhashHex;

    } catch (error) {
      console.error('Error decoding address:', error);
      return null;
    }
  }

  async function getCommitment() {
    const pubkeyhash = getAddressesPKH(usersAddress);
    if (pubkeyhash) {
      if (pubkeyhash.length != 40) {
        setIsPayoutAddressInvalid(true);
        return 'error';
      } else {
        setIsPayoutAddressInvalid(false);
      }

      //const blockHeight = await electrumServer.getBlockHeight() + 1; //when we were using tx.time for timelock
      const stakedBlocksHexLE = toLittleEndian(userStakeLength.toString(16), 2);

      const commitment = pubkeyhash + '000000000000000000000000000000000000' + stakedBlocksHexLE;
      console.log('getCommitment created: ', commitment);
      return commitment;
    } else {
      return 'error'
    }
  }

//////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

//////////////////////////////////////////////////
//////////Convert address to token address
//////////////////////////////////////////////////
  function toTokenAddress(address: string) {
    const addressInfo: any  = decodeCashAddress(address);
    const pkhPayoutBin = addressInfo.payload;
    const prefix = 'bitcoincash';
    const tokenAddress = encodeCashAddress(prefix, "p2pkhWithTokens", pkhPayoutBin);
    return tokenAddress;
  }

//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
useEffect(() => {
  async function checkAndCompileContracts() {
    if (contractsOK === false) {
      try {
        const compiledBadgerContract = await compileContract(ArtifactBadgerContract, []);
        setContractBadger(compiledBadgerContract);
        setContractsOK(true);

      } catch (error) {
        console.log('contract compiling error: ' + error);
      }
    }
  }

  checkAndCompileContracts();
}, []);

//////////////////////////////////////////////////
////////// UseEffect: Update users BadgerCoin & Honey balances
//////////////////////////////////////////////////
  useEffect(() => {
    let cashStarterUTXOs: Utxo[];

    async function getUTXOs() {
      if (!electrumServer) return;
      console.log('electrumServer detected');

      //delay to allow electrum to stabilize
      await new Promise(resolve => setTimeout(resolve, 2500));
        try {
          const blockHeight = await electrumServer.getBlockHeight();
          setBlockHeight(blockHeight);

          cashStarterUTXOs = await electrumServer.getUtxos(AddressBadgerStake);
          const filteredUTXOs = cashStarterUTXOs.filter( 
            utxo => utxo.token?.category == MasterCategoryID //only lockNFT's
              && utxo.token?.nft?.capability == 'mutable'    //exclude minting masterBadger
          );
          setContractStakeUTXOs(filteredUTXOs);

          //total number of utxos staking on contract
          const numberStakes = filteredUTXOs.length;
          setTotalStakes(numberStakes);

          //total amount of BCH on those utxos
          const totalBCHStaked = filteredUTXOs.reduce((acc, utxo) => acc + utxo.satoshis, 0n);
          setTotalStakedAmount(totalBCHStaked);

          //get all utxos on the contract and filter them into this users and all others
          let pubkeyhash: string;
          if (usersAddress != "") {
            pubkeyhash = getAddressesPKH(usersAddress)!;
          }

          const { yourUTXOs, otherUTXOs } = filteredUTXOs.reduce<{ yourUTXOs: Utxo[]; otherUTXOs: Utxo[] }>((acc, utxo) => {
            // Check if the UTXO matches the pubkeyhash
            if (utxo.token?.nft?.commitment.substring(0, 40) === pubkeyhash) {
                acc.yourUTXOs.push(utxo); // Add to matching array
            } else {
                acc.otherUTXOs.push(utxo); // Add to non-matching array
            }
            return acc;
          }, { yourUTXOs: [], otherUTXOs: [] }); // Initial accumulator with two empty arrays

          if (yourUTXOs.length) {
            //get the block each utxo was created, how long the lock is, and time remaining
            const usersUTXOs = yourUTXOs.map(async (utxo) => { 
              const response = await electrumServer.performRequest('blockchain.utxo.get_info', utxo.txid, utxo.vout);
              const startHeight: number = response.confirmed_height;
              const lockedBlocks = LEhexToDecimal(utxo.token?.nft?.commitment.substring(76,80) ?? "0");
              let timeLeft = 'Pending...'
              if (startHeight != null) {
                 timeLeft = await formatTime((startHeight + lockedBlocks), blockHeight);
              }
              
              return { ...utxo, startHeight, lockedBlocks, timeLeft };
            })
            const usersLockUTXOs = await Promise.all(usersUTXOs);
            setUserStakeUTXOs(usersLockUTXOs);

            const yourStakedBCH = usersLockUTXOs.reduce((acc, utxo) => acc + BigInt(utxo.satoshis), 0n);
            setUserStakedAmount(yourStakedBCH);
          }

          //get the block each other utxo was created, how long the lock is, and time remaining
          const notUsersUTXOs = otherUTXOs.map(async (utxo) => { 
            const response = await electrumServer.performRequest('blockchain.utxo.get_info', utxo.txid, utxo.vout);
            const startHeight: number = response.confirmed_height;
            const lockedBlocks = LEhexToDecimal(utxo.token?.nft?.commitment.substring(76,80) ?? "0");
            let timeLeft = 'Pending...'
            if (startHeight != null) {
                timeLeft = await formatTime((startHeight + lockedBlocks), blockHeight);
            }
            
            return { ...utxo, startHeight, lockedBlocks, timeLeft };
          })

          const otherLockUTXOs = await Promise.all(notUsersUTXOs);
          setOtherStakeUTXOs(otherLockUTXOs);

          const otherStakedBCH = otherLockUTXOs.reduce((acc, utxo) => acc + BigInt(utxo.satoshis), 0n);
          setOtherStakedAmount(otherStakedBCH);

        } catch (error) {
          console.error('Failed getUTXOs(): ', error);
        } 
    }

    async function getBadgerCoinSupply() {
      try {
        const masterBadgerUTXO = cashStarterUTXOs.find( 
          utxo => utxo.token?.category == MasterCategoryID //only lockNFT's
            && utxo.token?.nft?.capability == 'minting'    //only masterBadger
        );
        const remainingSupply = BigInt(9223372036854775807) - masterBadgerUTXO!.token?.amount!;
        setBadgercoinSupply(remainingSupply);

      } catch (error) {
        console.error('Failed getBadgerCoinSupply(): ', error);
      }
    }

    async function fetchData() {
      await getUTXOs();
      await getBadgerCoinSupply();
    }
    fetchData();
  }, [electrumServer, usersAddress]);

//////////////////////////////////////////////////
////////// UseEffect: Get users BadgerCoin balance
//////////////////////////////////////////////////
useEffect(() => {
  async function getBadgerCoinBalance() {
    if (!electrumServer || usersAddress == '') return;

    //delay to allow electrum to stabilize
    setTimeout(async () => {
      const userUTXOs: Utxo[] = await electrumServer.getUtxos(usersAddress);

      //////get BadgerCoins total
      const filteredUTXOs = userUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only badger category
          && utxo.token?.amount > 0n                     //has a token balance
      );
      //total amount of BadgerCoins on those utxos
      const badgerCoinBalance = filteredUTXOs.reduce((acc, utxo) => acc + utxo.token?.amount!, 0n);
      setUsersBadgerCoinBalance(badgerCoinBalance);

      //////get Honey total
      const honeyNFTs = userUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID          //only badger category
          && utxo.token?.nft?.commitment.substring(0,4) == '0000' //only ID 0
      );

      const honeyBalance = honeyNFTs.reduce((acc, nft) => {
        let honeyAmount = hexLEToBigInt(nft.token?.nft?.commitment.substring(4, 28) ?? "0");
        if (!honeyAmount) {
          return acc;
        }
        return acc + honeyAmount;
      }, 0n);
      setUsersHoneyBalance(honeyBalance);

    }, 2000); // Delay of 2 seconds
  }

  getBadgerCoinBalance();
}, [electrumServer, usersAddress]);

  async function formatTime(blocks: number, currentBlock: number): Promise<string> {
    //const blockHeight = await electrumServer.getBlockHeight();
    const blocksRemaining = Math.max((blocks - currentBlock), 0);

    if (blocksRemaining == 0) {
      return 'Available';
    }

    const totalMinutes = blocksRemaining * 10;
    const totalHours = totalMinutes / 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingHours - hours) * 60);

    let endDate = '';
    if (days > 0) {
      endDate += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
      endDate += `${hours}h `;
    }
    endDate += `${minutes}m`;

    return endDate;
  }

  /*
  const openModal = (utxo: CampaignUtxo) => {
    if (!modalOpen) {
       setSelectedData(utxo);
       setModalOpen(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedData(null);
    setModalOpen(false);
  };
*/
    
  async function signMessage({ message, userPrompt }: SignMessageParams): Promise<string | undefined> {
    const options = {
        message: message,
        userPrompt: userPrompt
      };

    console.log('signing message...');
    try {
        if (walletConnectInstance) {
        const params = JSON.parse(stringify(options));
        
        console.log('wc params:');
        console.log(options);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
                method: "bch_signMessage",
                params: params,
            },
        });
        console.log('signMessage result: ');
        console.log(result);
        return result;
        }
    } catch (error) {
        console.log('signMessage error: ' + error);
        return undefined;
    }
  }

  async function signTransaction(options: any) {
    console.log('signing transaction...');
    setError(`Transaction sent to your wallet for approval.`);
    try {
        if (walletConnectInstance) {
        const params = JSON.parse(stringify(options));
        console.log('wc params:');
        console.log(params);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
            method: "bch_signTransaction",
            params: params,
            },
        });
        return result;
        }
    } catch (error) {
        console.log('signTransation error: ' + error);
        return undefined;
    }
  }
  //Function to convert string to bigint
  function convertStringToBigInt(string: string): bigint {
    const bchAmount = parseFloat(string);                 //Parse the BCH string to a floating-point number
    const satoshis = Math.round(bchAmount * 100_000_000); //Convert BCH to satoshis and round the result to avoid precision issues
    return BigInt(satoshis);                              //Convert the satoshis to bigint for precise integer arithmetic
  }

  // Function to take users pledge string, verify only one decimal, remove all but numbers, set state
  const handleSetPledgeAmount = (value: string) => {
    let newValue = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

    // Check if there's a decimal point, and if so, limit the length after it to 8
    const decimalIndex = newValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = newValue.substring(0, decimalIndex);
      const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
      newValue = `${integerPart}.${decimalPart}`;
    }

    // Correct leading zeros in the integer part of the number
    if (decimalIndex !== 0) { // Skip if the string starts with a decimal point (e.g., ".5")
      newValue = newValue.replace(/^0+([0-9])/g, '$1');
    }

    // Special case: if the input is just "0" or starts with "0." like "0.12"
    if (newValue === "0" || newValue.startsWith("0.") && decimalIndex === 1) {
      newValue = newValue; // Keep it as is
    } else {
      // If newValue becomes empty or just ".", set it to "0"
      newValue = newValue === "" || newValue === "." ? "0" : newValue.replace(/^0+/, ''); // Remove leading zeros
    }

    setStringLockAmount(newValue);
  }

  // Function to take a number of blocks, verify with range, and set state
  const handleSetStakeLengthAmount = (blocks: string) => {
    let value = Number(blocks);
    // Ensure value is a number and within the desired range
    if (isNaN(value) || value && (value < 0 || value > 32767)) {
      console.log(`Lock length must be between 1 and 32767`);
      setError(`Lock length must be between 1 and 32767`);
      return;
    }
    if (value >= 0 && value <= 32767) {
      setUserStakeLength(value);
    }
  }

  // Function to lock users BCH into an NFT on the contract
  const handleLock = async (amount: string) => {
    console.log('handleLock:');

    const lockAmount = convertStringToBigInt(amount);
    console.log('lockAmount: ', lockAmount);
    if (userStakeLength <= 65536) {
      if (lockAmount >= 5000n) {  //need to have enough sats for unlock function
        const rewardTotal = (lockAmount * BigInt(userStakeLength) / 100000000n);
        if (rewardTotal >= 1n) {  //need to have 1 BadgerCoin as a reward
          setLockPending(true);
          
          const pubkeyhash = getAddressesPKH(usersAddress)!;
          
          if (electrumServer && amount != "") {
            const commitment: string = await getCommitment();

            const signResult = await contractBadgerLock({electrumServer, usersAddress, contractBadger, lockAmount, signMessage, signTransaction, commitment, userStakeLength, setError, pubkeyhash});
            if (signResult != undefined) {
              const rawTx = signResult.signedTransaction;
              console.log('signedTransaction from walletconnect: ');
              console.log(signResult);
        
              electrumServer.sendRawTransaction(rawTx).then((result: string) => {
                console.log('Broadcasted, txid: ' + result);
                setError(`Success: ${result}`);

              }).catch((error: Error) => {
                setError(`Broadcast error: ${error}`);
                console.error('Broadcast error:', error);
              });
            }
          } else {
            console.log('Please enter an amount of BCH to lock');
            setError(`Enter an amount of BCH to lock`);
          }
        } else {
          console.log('Lockups require to earn at least 1 BadgerCoin');
          setError(`Lockups require to earn at least 1 BadgerCoin`);
        }
      } else {
        console.log('Minimum lock amount is 0.00005 BCH');
        setError(`Minimum lock amount is 0.00005 BCH`);
      }
    } else {
      console.log(`Maximum # lock blocks is 65536 (~455 Days)`);
      setError(`Maximum # lock blocks is 65536 (~455 Days)`);
    }

    setLockPending(false);
  }

  // Function to unlock users locked BCH
  const handleUnlock = useCallback(async (lockUTXO: LockUtxo) => {
    console.log('handleUnlock():');
    setUnlockPending(true);

    if (electrumServer) {
      const signResult = await contractBadgerUnlock({electrumServer, usersAddress, contractBadger, toTokenAddress, signTransaction, setError, lockUTXO });
      
      if (signResult != undefined) {
        electrumServer.sendRawTransaction(signResult).then((result: string) => {
        console.log('Broadcasted, txid: ' + result);
        setError(`Success: ${result}`);
        
        const filteredUTXOs = userStakeUTXOs.filter(utxo => utxo !== lockUTXO);
        setUserStakeUTXOs(filteredUTXOs);

        }).catch((error: Error) => {
          setError(`Broadcast error: ${error}`);
          console.error('Broadcast error:', error);
        });
      }
    } else {
      console.log('Unknown Unlock error');
      setError(`Unknown Unlock error`);
    }
    setUnlockPending(false);
  }, [electrumServer, usersAddress, contractBadger, toTokenAddress, signTransaction, userStakeUTXOs, setError]);

  // Function to unlock users locked BCH
  const handleWithdraw = async () => {
    console.log('handleWithdraw():');

    if (electrumServer) {
      const signResult = await contractBadgerWithdraw({electrumServer, contractBadger, usersAddress, newFee, signTransaction, setError });
      if (signResult != undefined) {
        const rawTx = signResult.signedTransaction; //for walletconnect signing
        //const rawTx = signResult; //for privateKey signing
        console.log('signedTransaction from walletconnect: ');
        console.log(signResult);
  
        electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          console.log('Broadcasted, txid: ' + result);
          setError(`Success: ${result}`);
        }).catch((error: Error) => {
          setError(`Broadcast error: ${error}`);
          console.error('Broadcast error:', error);
        });
      }
    } else {
      console.log('handleInitializeManager if statement had a false');
      setError(`Unknown Withdraw error`);
    }
  }

const toggleCheck = () => setDiscountApplied(!discountApplied);

  return (

    <Container>

      <SlantedBeta>
        <SlantedBetaText>
          Not audited.
        </SlantedBetaText>
      </SlantedBeta>

      <ErrorNotification message={error} setError={setError} />

{/*   Staking Cards    */}
      <SlantedIntro lockDetected={userStakeUTXOs.length > 0}>
        <SlantedIntroContent>

          <StyledStakeCard>
            <StyledDistributed>
                BadgerCoins Distributed: <StyledSupplyText> {badgercoinSupply.toString()}</StyledSupplyText>
            </StyledDistributed>

            <StyledBadgerCoinBalance>
              Your: <StyledBadgerLogo title="BadgerCoins" /> {usersBadgerCoinBalance.toString()}
              <StyledHoneyLogo title="Honey" /> {usersHoneyBalance.toString()}
            </StyledBadgerCoinBalance>

            <StyledBlockHeight>
              Current Block <br/>
              {blockHeight}
            </StyledBlockHeight>
            
            <StyledStakeRow>

              <StyledPledgeLogo />

              <StyledPledgeAmount>
                <StyledTextAmount>
                  BCH to Lock
                </StyledTextAmount>
                <input
                  type="string"
                  placeholder="0.00123"
                  value={stringLockAmount}
                  onChange={(e) => handleSetPledgeAmount(e.target.value)}
                  style={{ backgroundColor: 'white', color: 'black' }}
                />
              </StyledPledgeAmount>

              <StyledPledgeAmount>
                <StyledTextBlocks>
                  # Blocks
                </StyledTextBlocks>
                <input
                  type="number"
                  placeholder="Blocks"
                  value={userStakeLength}
                  min="0"
                  max="32767"
                  onChange={(e) => handleSetStakeLengthAmount(e.target.value)}
                  style={{ backgroundColor: 'white', color: 'black' }}
                />
              </StyledPledgeAmount>

              {/* <PledgeButton disabled={lockPending} onClick={() => handleLock(stringLockAmount)}> */}
              <PledgeButton disabled={lockPending} isDisabled={lockPending} onClick={() => handleLock(stringLockAmount)}>
                {lockPending ? (
                  <StyledSpinner />
                ) : (
                  'Lock'
                )}
              </PledgeButton> 

            </StyledStakeRow>

            <StyledLogo logoUrl={BadgerBanner}/>

            <StyledTextReward>
                Earn: {((convertStringToBigInt(stringLockAmount) * BigInt(userStakeLength)) / 100000000n).toString()}
            </StyledTextReward>

            <StyledTextDays>
                = {(userStakeLength / 144).toFixed(2)} Days
            </StyledTextDays>
          </StyledStakeCard>

          {userStakeUTXOs.length > 0 && 
            <StyledLockedUTXOs>
              <StyledLockedUTXOTitle>Your Locked UTXOs</StyledLockedUTXOTitle>
              <StyledUTXORow>
                {[...userStakeUTXOs.values()].map((utxo, index) => (
                  <StyledLockedUTXO>
                    <StyledLockUTXOColumn>
                      <StyledLockUTXODescription>

                        <StyledLockedTXID>
                          <a href={"https://explorer.salemkode.com/tx/" + utxo.txid}>{utxo.txid.slice(0, 7) + '...' + utxo.txid.slice(57, 64)}</a>
                        </StyledLockedTXID>
                        {isNaN(utxo.startHeight + utxo.lockedBlocks) ? 'Pending Block...' : 'Unlocks: ' + (utxo.startHeight + utxo.lockedBlocks)} <br/>

                        <StyledLockAmountsRow>
                          <StyledPledgeLogo /> {parseFloat((Number(utxo.satoshis) / 100_000_000).toFixed(8))}
                        </StyledLockAmountsRow>

                        <StyledLockAmountsRow>
                          <StyledBadgerLogo /> {parseFloat((utxo.token?.amount!).toString())}
                        </StyledLockAmountsRow>
                        
                      </StyledLockUTXODescription>

                      <UnlockButton disabled={utxo.timeLeft != 'Available' && !unlockPending} isDisabled={utxo.timeLeft != 'Available'} onClick={() => handleUnlock(utxo)}>
                        {unlockPending ? (
                          <StyledSpinner />
                        ) : (
                          utxo.timeLeft == 'Available' ? 'Unlock' : utxo.timeLeft
                        )}
                      </UnlockButton> 
                    </StyledLockUTXOColumn>

                  </StyledLockedUTXO>
                ))}
              </StyledUTXORow>
            </StyledLockedUTXOs>
          }

        </SlantedIntroContent>

        <StyledTextStats>
          {usersAddress ? (
            <StyledUserAddress>
              Connected: <span style={{ color: '#5ccc60' }}>{`${usersAddress.substring(12, 22)} ... ${usersAddress.substring(usersAddress.length - 10)}`}</span>
            </StyledUserAddress>
            ) : (
              <></>
          )}
          <StyledNumberLocks>🔒's: {totalStakes.toString()}</StyledNumberLocks>
          <StyledOtherStats>🔒BCH: {parseFloat((Number(totalStakedAmount) / 100_000_000).toFixed(8))}</StyledOtherStats>
          <StyledOtherStats>Your 🔒BCH: {parseFloat((Number(userStakedAmount) / 100_000_000).toFixed(8))}</StyledOtherStats> 
        </StyledTextStats>
      </SlantedIntro>

      <SlantedUTXOScroller>
        <StyledLockedUTXOTitle>Locked UTXOs</StyledLockedUTXOTitle>
        {otherStakeUTXOs.length > 0 && 
          <StyledScrollerUTXOs>
            <ScrollingUTXOs otherStakeUTXOs={otherStakeUTXOs} unlockPending={unlockPending} handleUnlock={handleUnlock}/>
{/*
            <StyledScrollerUTXORow>
              {[...otherStakeUTXOs.values()].map((utxo, index) => (
                <StyledScrollerLockedUTXO key={keys[index]} onAnimationEnd={() => resetAnimation(index)}>
                  <StyledLockUTXOColumn>
                    <StyledLockUTXODescription>

                      <StyledLockedTXID>
                        <a href={"https://explorer.salemkode.com/tx/" + utxo.txid}>{utxo.txid.slice(0, 7) + '...' + utxo.txid.slice(57, 64)}</a>
                      </StyledLockedTXID>
                      {isNaN(utxo.startHeight + utxo.lockedBlocks) ? 'Pending Block...' : 'Unlocks: ' + (utxo.startHeight + utxo.lockedBlocks)} <br/>

                      <StyledLockAmountsRow>
                        <StyledPledgeLogo /> {parseFloat((Number(utxo.satoshis) / 100_000_000).toFixed(8))}
                      </StyledLockAmountsRow>

                      <StyledLockAmountsRow>
                        <StyledBadgerLogo /> {parseFloat((utxo.token?.amount!).toString())}
                      </StyledLockAmountsRow>
                      
                    </StyledLockUTXODescription>

                    <UnlockButton disabled={utxo.timeLeft != 'Available' && !unlockPending} isDisabled={utxo.timeLeft != 'Available'} onClick={() => handleUnlock(utxo)}>
                      {unlockPending ? (
                        <StyledSpinner />
                      ) : (
                        utxo.timeLeft == 'Available' ? 'Unlock' : utxo.timeLeft
                      )}
                    </UnlockButton> 
                  </StyledLockUTXOColumn>

                </StyledScrollerLockedUTXO>
              ))}
            </StyledScrollerUTXORow>
*/}
          </StyledScrollerUTXOs>
        }
      </SlantedUTXOScroller>
      
      {usersAddress == 'bitcoincash:qqnhqxy23rm4xcx3a9td0lefpmc7pat8su3j24rf6k' &&
        <StyledAdminCard>
          <input
            type="string"
            placeholder="newFee"
            value={newFee}
            onChange={(e) => setNewFee(e.target.value)}
            style={{ backgroundColor: 'white', color: 'black' }}
          />
          <PledgeButton onClick={() => handleWithdraw()}>Withdraw</PledgeButton> 
        </StyledAdminCard>
      }

      <StyledTextLeft>

        <StyledDivTitle>What is BadgerCoin?</StyledDivTitle>
        <StyledDivText>
          A proof-of-BitcoinCash-ownership meme coin. The <b><i>only way</i></b> to create BadgerCoins is by temporarily locking BCH into a contract for some amount of time. 
          <p>
          No premine, no developer reservation, no investors. 
          </p>
          When you 'Unlock' your BCH the earned BadgerCoins and a HoneyNFT are sent back to the same address that initially started the lock.
        </StyledDivText>
      </StyledTextLeft>

      <SlantedDescription>
        <StyledBadgerCoinImage />
        <StyledBCHImage />
        <StyledHoneyImage />

        <SlantedDescriptionContent>
        <StyledDivTitle>Any fees?</StyledDivTitle>
          <StyledDivText>
            Yes. Each lockup transaction will cost a miner fee + lock fee, currently 2000 satoshis in total.
            <ul>
              <li><b>Miner fee:</b> 1000 satoshis. Typical BCH transaction fee.</li>
              <li><b>Lock fee:</b> 1000 satoshis. Accumulates on the BadgerContract and can be withdrawn by the admin. The amount can also be increased by the admin to a maximum of 65,536 satoshis for
                new lockups. This is primarily to have the option of increasing the cost against spamming the contract if it becomes an issue.
              </li>
            </ul>
            In addition, when Unlocking 1000 satoshis are deducted directly from the locked BCH balance. This pays the miner fee, and allows <i>anyone</i> to unlock your coins, sending them
            back to your address.<br />
          </StyledDivText>
        </SlantedDescriptionContent>
      </SlantedDescription>

      <StyledTextRight>
        <StyledDivTitle>How are rewards calculated?</StyledDivTitle>
        <StyledDivText>
          Amount of BCH you lock, multiplied by the number of blocks locked for, divided by 1 BCH. Just a simple linear equation. There is no bonus when locking for longer periods. 
          1 BCH locked for one day would be:
          <p>
          100,000,000 * 144 / 100,000,000 = 144
          </p>
          This results in (1) BadgerCoin representing (1) BCH locked for (1) block. (1:1:1)
          <p />
          You also earn a HoneyNFT on each unlock which remembers how many BadgerCoins you earned for the unlock that created it.
        </StyledDivText>
      </StyledTextRight>

      <SlantedIntroRight>
        <SlantedIntroContentRight>
          <StyledDivTitle>Future plans?</StyledDivTitle>
          <StyledDivTextRow>
            <StyledDivText>
              10 BadgerCoins can be redeemed for a 50% discount on the mint price of a <a href="https://badgers.cash/Mint">HoneyBadger NFT</a>. Besides that, nothing is <i>currently</i> promised...
            </StyledDivText>
            <a href="https://badgers.cash/Mint"><StyledNFTProjectLogo logoUrl={BadgerGIF}/></a>
          </StyledDivTextRow>
        </SlantedIntroContentRight>
      </SlantedIntroRight>

      <StyledTextSayoshi>
        <StyledDivTitle>Who made this?</StyledDivTitle>
        <StyledTextRow>
          <StyledSayoshiLogo logoUrl={SayoshiLogo}/>
          <StyledDivText>
            Sayoshi Nakamario, one of the people behind <a href="https://HelpMe.cash">HelpMe.cash</a>. He first got involved in Bitcoin around 2011 and started learning to code in 2022,
            resulting in his first project, <a href="https://fogofwar.quest">Fog of War</a>, getting completed just as smartBCH imploded... 🤦‍♂️
            <p/>
            He decided to shelve the EVM work and has since been learning the CashTokens environment.
          </StyledDivText>
        </StyledTextRow>
      </StyledTextSayoshi>

      <StyledFooter>
        <StyledFooterContent>
        <a href="https://t.me/badgers_cash"><StyledTelegramLogo /></a>
        </StyledFooterContent>
      </StyledFooter>

      

{/*
    <StyledCards>
      <StyledHeaderTitle>NFT Projects</StyledHeaderTitle>

      <StyledNFTProjectWrapper>
        <StyledNFTProject>
          <NFTRow>
            <StyledProjectBox>
              <StyledTitle>HoneyBadgers</StyledTitle>
              <StyledNFTDescription>
                A collection of Bitcoin Cash Honeybadgers. <p />
                Mint a random HoneyBadger, with an optional discount, or view the Collection and mint a specific one at full price.
              </StyledNFTDescription>

              <StyledRow>
                <StyledDiscountRow>
                  <StyledDiscountButton discountApplied={discountApplied} onClick={toggleCheck} /> BadgerCoin Discount
                </StyledDiscountRow>
                <StyledPledgeLogo />
                <StyledPrice>{discountApplied ? 0.02 : 0.04}</StyledPrice>
                <MintButton disabled={true} onClick={() => handleLock(stringLockAmount)}>Random Mint</MintButton>                   
              </StyledRow>

            </StyledProjectBox>
          </NFTRow>

        <NFTRow>
          <StyledNFTProjectLogo logoUrl={BadgerGIF}/>
        </NFTRow>

        </StyledNFTProject>

        <ProgressBar>
          <Progress progress={(numberMinted / 10000) * 100} />
          <ProgressBarText>{((numberMinted / 10000) * 100).toFixed(2)}%</ProgressBarText>
          <ProgressBarSubText>Minted: {badgersMinted} / 10000</ProgressBarSubText>
        </ProgressBar>

      </StyledNFTProjectWrapper>
    </StyledCards>
  */}
{/*
    {modalOpen && <Modal onClose={closeModal} data={selectedData} contractCashStarter={contractCashStarter} contractManager={contractManager} />}
*/}
  </Container>
  );
};

interface LogoDivProps {
  logoUrl: string;
}
interface PledgeButtonProps {
  isDisabled?: boolean;
}
interface MintButtonProps {
  isactive?: boolean;
}
interface DetailsButtonProps {
  isactive?: boolean;
}
interface LockDetectedProps {
  lockDetected: boolean;
}

const scrollAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;
const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

// Styled Components
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #113c2b;
  border: 0px solid red;
`;
const SlantedBeta = styled.div`
  position: absolute;
  left: 10px;
  top: -160px;
  background-color: #be5b5b;
  height: 30px;
  width: 150px;
  color: #fff;
  font-size: 16px;
  font-weight: 700x;
  justify-content: center;
  border: 2px solid #fff;
  z-index: 1;
  @media (max-width: 750px) {
    font-size: 14px;
    height: 42px;
    width: 250px;
  }
`;
const SlantedBetaText = styled.div`
  position: relative;
  width: 100%;
  border: 0px solid green;
  top: 3px;
  text-align: center;
`;
const SlantedIntro = styled.div<LockDetectedProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0px;
  background-color: #4CAF50;
  height: ${(props) => (props.lockDetected ? '430px' : '250px')};
  width: 100%;
  transform: skewY(3deg);
  transform-origin: top left;
  color: #fff;
  font-size: 18px;
  font-weight: 700x;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  z-index: 0;
`;
const SlantedIntroContent = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  left: 0px;
  transform: skewY(-3deg);
  transform-origin: top left;
  border: 0px solid blue;
  z-index: 0;
`;
const SlantedUTXOScroller = styled.div`
  display: flex;
  background-color: #111;
  flex-direction: column;
  position: relative;
  left: 0px;
  top: 2px;
  height: 190px;
  width: 100%;
  transform: skewY(3deg);
  transform-origin: top left;
  color: #fff;
  font-size: 18px;
  font-weight: 700x;
  justify-content: top;
  align-items: right;
  border-bottom: 2px solid #fff;
  z-index: 0;
  overflow: hidden;
`;
const SlantedIntroRight = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0px;
  top: 240px;
  background-color: #4CAF50;
  height: 320px;
  width: 100%;
  transform: skewY(3deg);
  transform-origin: top left;
  color: #fff;
  font-size: 18px;
  font-weight: 700x;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  z-index: 0;
`;
const SlantedIntroContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0px;
  top: 30px;
  width: 70%;
  max-width: 1000px;
  transform: skewY(-3deg);
  transform-origin: top left;
  border: 0px solid blue;
  z-index: 0;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 620px) {
    width: 85%;
    top: 0px;
  }
`;
const SlantedDescription = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 180px;
  background-color: #4CAF50;
  height: 470px;
  width: 100%;
  transform: skewY(-3deg);
  transform-origin: top left;
  color: #fff;
  font-size: 18px;
  font-weight: 700x;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 2px solid #fff;
  z-index: 0;
  @media (max-width: 620px) {
    height: 500px;
  }
`;
const SlantedDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  max-width: 1000px;
  height: 100%;
  left: 0px;
  transform: skewY(3deg);
  transform-origin: top left;
  border: 0px solid blue;
  z-index: 0;
`;
const StyledTextLeft = styled.div`
  position: relative;
  transform-origin: top left;
  right: 0px;
  top: 80px;
  width: 80%;
  max-width: 1000px;
  border: 0px solid red;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;
const StyledTextRight = styled.div`
  position: relative;
  transform-origin: top left;
  right: 0px;
  top: 200px;
  width: 80%;
  max-width: 1000px;
  border: 0px solid red;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;
const StyledTextStats = styled.div`
  position: absolute;
  display: flex;
  transform-origin: top left;
  right: 2%;
  top: -34px;
  width: 800px;
  border: 2px solid #fff;
  border-bottom: 0px;
  z-index: 100;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  align-items: center;
  @media (max-width: 1300px) {
    width: 550px;
    font-size: 16px;
    top: -25px;
  }
  @media (max-width: 850px) {
    width: 400px;
    font-size: 12px;
    top: -20px;
  }
`;
const StyledTextSayoshi = styled.div`
  position: relative;
  transform-origin: top left;
  right: 0px;
  top: 320px;
  width: 80%;
  max-width: 1000px;
  border: 0px solid red;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  max-height: 840px;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledBlackBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000;
  height: 100%;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.7;
`;
const StyledCards = styled.div`
  border: 1px solid red;
  color: white;
  height: 100%;
  width: 90%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; // Horizontally center content
  position: relative;
  border-radius: 0px 0px 0px 0px;
  margin-top: 25px;
`;
const StyledStakeCard = styled.div`
  background-color: #111;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 150px;
  width: 600px;
  max-width: 600px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative; 
  border-radius: 20px 20px 0px 0px;
  margin-top: 35px;
  @media (max-width: 620px) {
    width: 400px;
  }
`;
const StyledLockedUTXOs = styled.div`
  background-color: #111;
  border: 2px solid #fff;
  margin: 0px;
  color: white;
  height: 190px;
  width: 600px;
  max-width: 600px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative; 
  border-radius: 0px 0px 20px 20px;
  margin-left: 5px;
  top: -10px;
  @media (max-width: 620px) {
    width: 400px;
  }
`;
const StyledScrollerUTXOs = styled.div`
  margin: 0px;
  color: white;
  height: 165px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  position: relative; 
  border: 0px solid blue;
`;
const StyledUTXORow = styled.div`
  border: 0px solid red;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative; 
  overflow-x: auto;  // Enables horizontal scrolling
  overflow-y: hidden;  // Keeps vertical overflow hidden
  @media (max-width: 620px) {
    width: 400px;
  }
`;
const StyledScrollerUTXORow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  border: 0px solid red;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative; 
  overflow-x: auto;  // Enables horizontal scrolling
  overflow-y: hidden;  // Keeps vertical overflow hidden
  @media (max-width: 620px) {
    width: 400px;
  }
`;
const StyledBadgerCoinBalance = styled.div`
  position: absolute;
  top: -35px;
  left: 0px;
  color: white;
  font-weight: 700;
  height: 25px;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid pink;
  z-index: 1000;
  @media (max-width: 620px) {
    width: 400px;
    top: -35px;
  }
`;
const StyledLockedUTXO = styled.div`
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 150px;
  width: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; 
  border-radius: 0px 0px 20px 20px;
  @media (max-width: 620px) {
    width: 150px;
  }
`;
const StyledScrollerLockedUTXO = styled.div`
display: inline-block;
margin-right: -4px;
animation: ${scrollAnimation} 10s linear infinite;
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 148px;
  width: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; 
  border-radius: 0px 0px 20px 20px;
  @media (max-width: 620px) {
    width: 150px;
  }
`;
const StyledAdminCard = styled.div`
  background-color: #000;
  border: 1px solid #00cc66;
  color: white;
  height: 70px;
  width: 130px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  top: -125px;
  left: 5px;
  opacity: 0.7;
`;
const StyledNFTProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #00cc66;
  margin: 5px;
`;
const StyledNFTProject = styled.div`
  background-color: #000;
  border: 0px solid #00cc66;
  color: white;
  height: 256px;
  width: 500px;
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Center horizontally */
  justify-content: center; // Horizontally center content
  overflow: hidden;
  position: relative; /* Add position relative to contain absolute child */
  border-radius: 0px 0px 0px 0px;
`;
const NFTRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border: 0px solid blue;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
`;
const StyledLockedUTXOTitle = styled.div`
  width: 100%;
  background-color: #113c2b;
  height: 25px;
  border-bottom: 2px solid #fff;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
  @media (max-width: 620px) {
    font-size: 14px;
  }
`;
const StyledDivTitle = styled.div`
  width: 100%;
  height: 50px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 38px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  @media (max-width: 620px) {
    font-size: 24px;
  }
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  border-bottom: 1px solid #0AC18E;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
`;
const StyledPrice = styled.div`
  display: flex;
  height: 30px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  text-decoration: none;
  color: #fff;
  z-index: 2;
`;
const StyledTextAmount = styled.div`
  display: flex;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  min-width: 100px;
  right: 10px;
  top: -20px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  @media (max-width: 615px) {
    background-color: #000;
    right: 12px;
  }
`;
const StyledTextBlocks = styled.div`
  display: flex;
  border: 0px solid yellow;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  left: 5px;
  top: -20px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
`;
const StyledTextDays = styled.div`
  display: flex;
  border: 0px solid yellow;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  right: 250px;
  bottom: 20px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  @media (max-width: 615px) {
    right: 150px;
    bottom: 35px;
  }
`;
const StyledBlockHeight = styled.div`
  display: flex;
  position: absolute;
  border: 0px solid yellow;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  right: 20px;
  top: 20px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  @media (max-width: 615px) {
    top: 107px;
    right: 10px;
  }
`;
const StyledHeaderTitle = styled.div`
  width: 100%;
  justify-content: center;
  border: 0px solid orange;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: #fff;
`;
const StyledProjectBox = styled.div`
  position: relative;
  width: 100%;
  height: 255px;
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border: 0px solid yellow;
`;
const StyledDescription = styled.div`
  position: relative;
  top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 150px;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 600;
`;
const StyledLockUTXODescription = styled.div`
  position: relative;
  width: 100%;
  height: 103px;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
const StyledNFTDescription = styled.div`
  position: relative;
  top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 150px;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 600;
`;
const StyledDivText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  position: relative;
  height: 100%;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  padding: 5px;
  border: 0px solid red;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  a {
    color: inherit; /* Optional: makes the link color match the text color */
    text-decoration: underline; /* Optional: ensures links are underlined */
    display: inline; /* Ensures links do not break flow */
    &:hover {
      color: #09af81; // Hover link color
    }
  }
  ul li {
    text-align: left;
  }
  p {
    text-align: center; /* Centers text in p tags */
  }
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 620px) {
    font-size: 16px;
  }
`;
const StyledDivTextRow = styled.div`
  display: flex;
  flex-direction: row;
  border: 0px solid white;
`;
const StyledText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  position: relative;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 5px;
  border: 0px solid red;
  a {
    color: inherit; /* Optional: makes the link color match the text color */
    text-decoration: underline; /* Optional: ensures links are underlined */
    display: inline; /* Ensures links do not break flow */
    &:hover {
      color: #09af81; // Hover link color
    }
  }
  ul li {
    font-size: 14px;
  }
`;
const StyledWhatIs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  width: 80%;
  max-width: 750px;
  border: 1px solid #0AC18E;
  border-radius: 50px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
  opacity: 1.0;
`;
const StyledFooterRow = styled.div`
  position: absolute;
  height: 25px;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #0AC18E;
  z-index: 2;
`;
const StyledNumberLocks = styled.div`
  position: relative;
  height: 100%;
  width: 20%;
  display: flex;
  z-index: 2;
  justify-content: center;
  border: 0px solid red;
`;
const StyledOtherStats = styled.div`
  position: relative;
  height: 100%;
  width: 40%;
  display: flex;
  z-index: 2;
  justify-content: center;
  border: 0px solid red;
`;
const StyledRow = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 2;
  border: 0px solid pink;
  justify-content: center;
  bottom: 0px;
`;
const StyledTextRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 2;
  border: 0px solid pink;
  justify-content: center;
`;
const StyledStakeRow = styled.div`
  position: absolute;
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  border: 0px solid pink;
  justify-content:center;
  top: 85px;
  flex-wrap: wrap;
  @media (max-width: 620px) {
    top: 70px;
  }
`;
const StyledTextReward = styled.div`
  display: flex;
  border: 0px solid yellow;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  left: 140px;
  bottom: 20px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  @media (max-width: 615px) {
    left: 40px;
    bottom: 35px;
  }
`;
const StyledDistributed = styled.div`
  position: absolute;
  bottom: 0px;
  padding-left: 10px;
  display: flex;
  border: 0px solid yellow;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  @media (max-width: 615px) {
    font-size: 14px;
  }
`;
const StyledSupplyText = styled.div`
  color: #4caf50;
  padding-left: 5px;
`;
const StyledLockAmountsRow = styled.div`
  z-index: 2;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  height: 30px;
  gap: 10px;
  border: 0px solid pink;
  justify-content: left;
  align-items: center;
  text-align: center;
  margin-top: 5px;
`;
const StyledLockedTXID = styled.div`
  z-index: 2;
  background-color: #4CAF50;
  color: #fff;
  display: flex;
  height: 20px;
  gap: 10px;
  border-bottom: 2px solid #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    background-color: #fff;
    a {
      color: #4CAF50;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
  }
`;
const StyledLockUTXOColumn = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  z-index: 2;
  border: 0px solid pink;
  flex-wrap: wrap;
`;
const StyledDiscountRow = styled.div`
  position: absolute;
  width: 100%;
  top: -25px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  border: 0px solid pink;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`;
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  display: flex;
  border: 0px solid red;
`;
const StyledPledgeLogo = styled.div`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: flex;
  border: 0px solid red;
`;
const StyledBadgerLogo = styled.div`
  position: relative;
  background-image: url(${BadgerLogo100});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid red;
  @media (max-width: 620px) {
    height: 30px;
    width: 30px;
  }
`;
const StyledHoneyLogo = styled.div`
  position: relative;
  background-image: url(${HoneyLogo100});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid blue;
  margin-left: 20px;
  @media (max-width: 620px) {
    height: 30px;
    width: 30px;
  }
`;
const StyledTelegramLogo = styled.div`
  position: relative;
  background-image: url(${TelegramLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  display: flex;
  border: 0px solid red;
  @media (max-width: 620px) {
    height: 30px;
    width: 30px;
  }
`;
const StyledBadgerCoinImage = styled.div`
  position: absolute;
  background-image: url(${BadgerLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
  left: 0px;
  top: -420px;
  display: flex;
  border: 0px solid red;
  opacity: 0.15;
  @media (max-width: 1200px) {
    top: -400px;
  }
  @media (max-width: 620px) {
    top: -450px;
  }
`;
const StyledHoneyImage = styled.div`
  position: absolute;
  background-image: url(${HoneyLogo500});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;
  left: 20px;
  top: 515px;
  display: flex;
  border: 0px solid red;
  opacity: 0.10;
  @media (max-width: 1200px) {
    top: 485px;
  }
  @media (max-width: 620px) {
    top: 515px;
  }
`;
const StyledBCHImage = styled.div`
  position: absolute;
  background-image: url(${BCHLogo500});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
  right: 0px;
  top: 20px;
  display: flex;
  border: 0px solid red;
  opacity: 0.15;
`;
const StyledPledgeAmount = styled.div`
  position: relative;
  right: 0px;
  top: 5px;
  height: 20px;
  display: flex;
  width: 110px;
  border: 0px solid red;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  color: #fff;
  input { 
    width: 100%;
    border: inherit;
    font-size: inherit;
    color: inherit;
}
`;
const PledgeButton = styled.button<PledgeButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isDisabled ? '#666' : '#4caf50')};
  color: ${(props) => (props.isDisabled ? '#ccc' : '#fff')};
  border: 2px solid ${(props) => (props.isDisabled ? '#222' : '#4caf50')};
  border-radius: 20px;
  font-size: 18px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  z-index: 10;
  width: 110px;
  min-width: 50px;
  height: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#666' : '#6ac76d;')};
  }
  @media (max-width: 550px) {
    width: 100px;
  }
  @media (max-width: 490px) {
    left: 0px;
    width: 100px;
  }
`
const UnlockButton = styled.button<PledgeButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isDisabled ? '#666' : '#4caf50')};
  color: ${(props) => (props.isDisabled ? '#ccc' : '#fff')};
  border: 2px solid ${(props) => (props.isDisabled ? '#222' : '#4caf50')};
  border-radius: 20px;
  font-size: 18px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  z-index: 10;
  width: 90%;
  min-width: 100px;
  height: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#666' : '#6ac76d;')};
  }

`
const MintButton = styled.button<MintButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border: 2px solid #0AC18E;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  cursor: not-allowed;
  z-index: 10;
  width: 135px;
  min-width: 50px;
  height: 32px;

  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
  }
  @media (max-width: 550px) {
    width: 100px;
  }
  @media (max-width: 490px) {
    width: 100px;
  }
`
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  width: 500px;
  height: 100px;
  border: 0px solid orange;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  z-index: 1;
  @media (max-width: 620px) {
    width: 400px;
    height: 80px;
  }
`;
const StyledSayoshiLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  position: relative;
  width: 100px;
  height: 100px;
  border: 0px solid orange;
  display: flex;
  overflow: hidden;
  z-index: 2;
  flex-shrink: 0;
  margin-top: 5px;
`;
const StyledNFTProjectLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  position: relative;
  width: 256px;
  height: 256px;
  border: 2px solid #fff;
  display: flex;
  overflow: hidden;
  z-index: 1;
  box-shadow: 10px 10px 5px rgba(0,0,0,0.4);
  @media (max-width: 1200px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 620px) {
    width: 150px;
    height: 150px;
  }
`;
const StyledFooter = styled.div`
  display: flex;
  height: 320px;
  width: 100%;
  position: relative;
  top: 320px;
  background: #113c2b;
  border: 0px solid yellow;
  justify-content: center;
  align-items: flex-start;
`;
const StyledFooterContent = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  top: 20px;
  border: 0px solid red;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
export const StyledDiscountButton = styled.button<{ discountApplied: boolean }>`
  background-color: ${(props) => (props.discountApplied ? '#0AC18E' : '#f0f0f0')};
  border: 1px solid #0AC18E;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;

 &::after {
    content: '${(props) => (props.discountApplied ? "\\2713" : "")}'; // Unicode checkmark
    color: white;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -55%);
  }
`;
const StyledUserAddress = styled.div`
  position: absolute;
  color: white;
  width: 100%;
  height: 20px;
  top: -30px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 18px;
    top: -26px;
  }
  @media (max-width: 850px) {
    font-size: 16px;
    top: -23px;
  }
`
export default Home;
