import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import BadgerGIF from '../../assets/img/badgerGIF.gif';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import BadgerLogo from '../../assets/img/badgercoin.png';
import BadgerLogo100 from '../../assets/img/badgercoin100.png';
import BadgerBanner from '../../assets/img/badgerCoinBanner.png';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract } from 'cashscript';
import Modal from '../../components/Modal';
import Spinner from '../../assets/img/spinner.gif';
//blockchain stuff
import ArtifactBadgerContract from '../../contracts/lockupFarm.json';
import ArtifactBadgerMarket from '../../contracts/BadgerMarket/badgerMarket.json';
import ArtifactBadgerManager from '../../contracts/BadgerMarketBouncer/badgerMarketBouncer.json';
import contractBadgerUnlock from '../../functions/contractBadgerUnlock';
import contractBadgerLock from '../../functions/contractBadgerLock';
import marketBuyBadger from '../../functions/marketBuyBadger';
import marketListBadger from '../../functions/marketListBadger';
import { encodeCashAddress, stringify, decodeCashAddress } from '@bitauth/libauth';
import { AddressBadgerStake, MasterCategoryID, AddressBadgerMarket } from '../../constants/values'

interface CampaignUtxo extends Utxo {
  name: string;
  description: string;
  logo: string;
  endDate: string;
}

const Market: React.FC = () => {
  const navigate = useNavigate();
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [campaigns, setCampaigns] = useState<Utxo[]>([]);
  const [badgersOnMarket, setBadgersOnMarket] = useState<Utxo[]>([]);
  const [badgerMarketUTXOsFetched, setBadgerMarketUTXOsFetched] = useState(true);
  const [expiredCampaigns, setExpiredCampaigns] = useState<Utxo[]>([]);
  const [campaignsMap, setCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [expiredCampaignsMap, setExpiredCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [isPayoutAddressInvalid, setIsPayoutAddressInvalid] = useState(false);
  const [selectedCampaignID, setSelectedCampaignID] = useState<string>('');
  const [stringLockAmount, setStringLockAmount] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  //compiled contracts
  const [contractsOK, setContractsOK] = useState(false);
  const [contractBadger, setContractBadger] = useState<Contract>();
  const [contractBadgerMarket, setContractBadgerMarket] = useState<Contract>();
  const [contractBadgerManager, setContractBadgerManager] = useState<Contract>();
  const [numberMinted, setNumberMinted] = useState<number>(0);
  const [userStakedAmount, setUserStakedAmount] = useState<number>(0);
  const [badgersMinted, setBadgersMinted] = useState<number>(0);
  const [discountApplied, setDiscountApplied] = useState(false);

  // Convert hex to big-endian and then to decimal
  const hexLEToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  // Convert LE to BE
  const LEtoBE = (hex: string) => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return bigEndianHex;
  };
  function hexToUint8Array(hexString: any) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  //click Details to open campaign details page
  const handleDetailsClick = (id: number) => {
    navigate(`/campaign/${id}`);
  };

  // Converts a hex string to little-endian format
  const toLittleEndian = (hex: string, byteLength: number) => {
    const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
    const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
    return hexArray.join(''); // Join the array back into a string
  };

  //convert address to pubkeyhash
  function getAddressesPKH(address: string) {
    try {
      const decoded = decodeCashAddress(address);
      if (typeof decoded === 'string') {
        console.error('Invalid address format');  // Handle error - decoded should be an object for a valid address
        return null;
      }

      // Convert Uint8Array to a hexadecimal string
      const pubkeyhashHex = Array.from(decoded.payload)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return pubkeyhashHex;

    } catch (error) {
      console.error('Error decoding address:', error);
      return null;
    }
  }

  async function getCommitment() {
    const pubkeyhash = getAddressesPKH(usersAddress);
    if (pubkeyhash) {
      if (pubkeyhash.length != 40) {
        setIsPayoutAddressInvalid(true);
        return 'error';
      } else {
        setIsPayoutAddressInvalid(false);
      }

      const blockHeight = await electrumServer.getBlockHeight();
      const startBlockHexLE = toLittleEndian(parseInt(blockHeight).toString(16), 4);

      const commitment = pubkeyhash + '00000000000000000000000000000000' + startBlockHexLE;
      console.log('getCommitment created: ', commitment);
      return commitment;
    } else {
      return 'error'
    }
  }

//////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

//////////////////////////////////////////////////
//////////Convert address to token address
//////////////////////////////////////////////////
  function toTokenAddress(address: string) {
    console.log('toTokenAddress() called for address: ' + address);
    const addressInfo: any  = decodeCashAddress(address);
    const pkhPayoutBin = addressInfo.payload;
    const prefix = 'bitcoincash';
    const tokenAddress = encodeCashAddress(prefix, "p2pkhWithTokens", pkhPayoutBin);
    console.log('toTokenAddress() converted it to: ' + tokenAddress);
    return tokenAddress;
  }

{/*
//////////////////////////////////////////////////
  useEffect(() => {
    async function getBadgerMarketUTXOs() {
      if (!electrumServer) return;
      console.log('electrumServer detected');

      //delay to allow electrum to stabilize
      setTimeout(async () => {
        const badgerMarketUTXOs: Utxo[] = await electrumServer.getUtxos(AddressBadgerMarket);
        const filteredMarketUTXOs = badgerMarketUTXOs.filter( 
          utxo => utxo.token?.category == MasterCategoryID //only badgerNFT's
            && utxo.token?.nft?.capability == 'mutable' //only mutable ones
        );
        setBadgersOnMarket(filteredMarketUTXOs);  //save filtered UTXOs to campaigns
        setBadgerMarketUTXOsFetched(true);

      }, 2500); // Delay of 2.5 seconds
    }

    getBadgerMarketUTXOs();
  }, [electrumServer]);
*/}
//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
useEffect(() => {
  console.log('1. useEffect called');
  async function checkAndCompileContracts() {
    console.log('2. compiling contracts...');
    if (contractsOK === false) {
      try {
        const compiledBadgerContract = await compileContract(ArtifactBadgerContract, []);
        setContractBadger(compiledBadgerContract);
        const compiledBadgerMarket = await compileContract(ArtifactBadgerMarket, []);
        setContractBadgerMarket(compiledBadgerMarket);
        const compiledBadgerManager = await compileContract(ArtifactBadgerManager, []);
        setContractBadgerManager(compiledBadgerManager);
        setContractsOK(true);
        console.log('3. contracts OK');
      } catch (error) {
        console.log('contract compiling error: ' + error);
      }
    }
  }

  checkAndCompileContracts();
}, []);

  /*
  const openModal = (utxo: CampaignUtxo) => {
    if (!modalOpen) {
       setSelectedData(utxo);
       setModalOpen(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedData(null);
    setModalOpen(false);
  };
*/
  async function signTransaction(options: any) {
    console.log('signing transaction...');
    try {
        if (walletConnectInstance) {
        const params = JSON.parse(stringify(options));
        console.log('wc params:');
        console.log(params);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
            method: "bch_signTransaction",
            params: params,
            },
        });
        return result;
        }
    } catch (error) {
        console.log('signTransation error: ' + error);
        return undefined;
    }
  }
  //Function to convert string to bigint
  function convertStringToBigInt(string: string): bigint {
    const bchAmount = parseFloat(string);                 //Parse the BCH string to a floating-point number
    const satoshis = Math.round(bchAmount * 100_000_000); //Convert BCH to satoshis and round the result to avoid precision issues
    return BigInt(satoshis);                              //Convert the satoshis to bigint for precise integer arithmetic
  }

  // Function to lock users BCH into an NFT on the contract
  const handleBuyBadger = async (badger: Utxo) => {
    console.log('handleBuyBadger:');
    if (electrumServer) {

      const signResult = await marketBuyBadger({electrumServer, usersAddress, contractBadgerMarket, toTokenAddress, signTransaction, badger});
      if (signResult != undefined) {
        const rawTx = signResult.signedTransaction;
        //const rawTx = signResult;
        console.log('signedTransaction from walletconnect: ');
        console.log(signResult);
  
        // Ask user for confirmation before broadcasting
        if (window.confirm("Do you want to proceed with broadcasting the transaction?")) {
          // User clicked OK
          electrumServer.sendRawTransaction(rawTx).then((result: string) => {
              console.log('Broadcasted, txid: ' + result);
              // Depending on your setup, you might need to alert or indicate to the user that the transaction was successful.
          }).catch((error: Error) => {
              alert('Broadcast error:');
              console.error('Broadcast error:', error);
          });
        } else {
          // User clicked Cancel
          alert('Minting transaction cancelled');
          console.log('Minting transaction cancelled');
        }
      }
    } else {
      console.log('handleInitializeManager if statement had a false');
    }
  }

  const handleListBadger = async (badger: Utxo, listPrice: string) => {
    console.log('handleListBadger():');

    const pubkeyhash = getAddressesPKH(usersAddress);
    const price = BigInt(listPrice);
    if (price >= 1000n) {

      if (electrumServer && badger && pubkeyhash != null) {
        const signResult = await marketListBadger({electrumServer, usersAddress, contractBadgerManager, toTokenAddress, signTransaction, badger, price, pubkeyhash });
        //const signResult = '';
        if (signResult != undefined) {
          const rawTx = signResult.signedTransaction; //for walletconnect signing
          //const rawTx = signResult; //for privateKey signing
          console.log('signedTransaction from walletconnect: ');
          console.log(signResult);

          // Ask user for confirmation before broadcasting
          if (window.confirm("Do you want to proceed with broadcasting the transaction?")) {
            // User clicked OK
            electrumServer.sendRawTransaction(rawTx).then((result: string) => {
                console.log('Broadcasted, txid: ' + result);
                // Depending on your setup, you might need to alert or indicate to the user that the transaction was successful.
            }).catch((error: Error) => {
                alert('Broadcast error:');
                console.error('Broadcast error:', error);
            });
          } else {     // User clicked Cancel
            alert('Minting transaction cancelled');
            console.log('Minting transaction cancelled');
          }
        }
      } else {
        console.log('handleInitializeManager if statement had a false');
      }

    } else {
      alert('List Price must be at least 1000sats');
      console.log('List Price must be at least 1000sats');
    }
  }


  const openModal = () => {
    if (!modalOpen) {
       setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>

      <StyledMarketHeader>
        <StyledTextBackground />
        <StyledHeaderTitle>Market</StyledHeaderTitle>
        <StyledHeaderSubTitle>Badgers listed for sale by users</StyledHeaderSubTitle>
        <ListButton disabled={true} onClick={() => openModal()}>List</ListButton> 
      </StyledMarketHeader>

      <StyledConnected>
        {usersAddress ? (
          <StyledUserAddress>
            Connected: <span style={{ color: '#5ccc60' }}>{`${usersAddress.substring(12, 22)} ... ${usersAddress.substring(usersAddress.length - 10)}`}</span>
          </StyledUserAddress>
          ) : (
            <></>
        )}
      </StyledConnected>

    {/*   BadgerNFT Cards    */}
      <StyledCards>

      {badgersOnMarket.length > 0 ? (
        [...badgersOnMarket.values()].map((badger, index) => (
          <StyledStakeCard>
            <StyledBuyOverlay>
              <StyledBuyBackground />
              <PledgeButton onClick={() => handleBuyBadger(badger)}>Buy</PledgeButton> 
            </StyledBuyOverlay>
                          
            <StyledStakeRow>
              <StyledDescription>ID: {hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}</StyledDescription>
              <StyledDescription>Sats: {hexLEToDecimal(badger.token?.nft?.commitment.substring(40,50) ?? "0")}</StyledDescription>
            </StyledStakeRow>

        {/*    <StyledLogo logoUrl={require(`../../assets/img/badgers/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`)}/>   */}
            <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`}/>

          </StyledStakeCard>
        ))
      ) : (
        badgerMarketUTXOsFetched ? (
          'Marketplace not yet launched'
        ) : (
          <StyledSpinner />
        )
      )}

      </StyledCards>

      {modalOpen && <Modal onClose={closeModal} contractBadgerMarket={contractBadgerMarket} handleListBadger={handleListBadger}/>}

    </Container>
  );
};

interface LogoDivProps {
  url: string;
}
interface PledgeButtonProps {
  isactive?: boolean;
}
interface ListButtonProps {
  isactive?: boolean;
}
interface DetailsButtonProps {
  isactive?: boolean;
}

const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

// Styled Components
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #000;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81;
    }
  }
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  max-height: 840px;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledTextBackground = styled.div`
  position: absolute;
  top: -2px;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  z-index: 0;
  background-color: #113c2b;
  opacity: 1;
  border-radius: 0px 0px 50px 50px;
`;
const StyledListButtonContainer = styled.div`
  position: relative;
  top: -50px;
  left: -230px;
  width: 130px;
  height: 50px;
  display: flex;
  flex-direction: column;
  border: 2px solid #0AC18E;
  z-index: 0;
  background-color: #000;
  opacity: 0.7;
  border-radius: 20px 20px 20px 20px;
  justify-content: center;
  align-items: center;
`;
const StyledMarketHeader = styled.div`
  border-top: 2px solid #fff;
  color: white;
  height: 50px;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  border-radius: 0px 0px 0px 0px;
`;
const StyledCards = styled.div`
  border: 0px solid red;
  color: white;
  height: 100%;
  width: 90%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; // Horizontally center content
  position: relative;
  border-radius: 0px 0px 0px 0px;
    margin-top: 10px;
`;
const StyledBuyOverlay = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: row;
  z-index: 4;
  border: 0px solid pink;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s, visibility 0.3s;
`;
const StyledBuyBackground = styled.div`
  position: absolute;
  top: 0px;
  background-color: #000;
  opacity: 0.4;
  height: 200px;
  width: 100%;
  display: flex;
  z-index: 3;
`;
const StyledStakeCard = styled.div`
  background-color: #000;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 250px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  &:hover ${StyledBuyOverlay} {
    opacity: 1;
    visibility: visible;
  }
`;
const StyledNFTProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #00cc66;
  margin: 5px;
`;
const StyledNFTProject = styled.div`
  background-color: #000;
  border: 0px solid #00cc66;
  color: white;
  height: 256px;
  width: 500px;
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Center horizontally */
  justify-content: center; // Horizontally center content
  overflow: hidden;
  position: relative; /* Add position relative to contain absolute child */
  border-radius: 0px 0px 0px 0px;
`;
const NFTRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border: 0px solid blue;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  border-bottom: 1px solid #0AC18E;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
`;
const StyledPrice = styled.div`
  display: flex;
  height: 30px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  text-decoration: none;
  color: #fff;
  z-index: 2;
`;
const StyledText = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
  width: 50%;
  height: 18px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  right: 0px;
  top: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  padding-right: 5px;
  z-index: 2;
`;
const StyledName = styled.div`
  width: 50%;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  padding-right: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #fff;
`;
const StyledHeaderTitle = styled.div`
  width: 100%;
  justify-content: center;
  border: 0px solid orange;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  top: -14px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  opacity: 1.0;
  z-index: 1;
`;
const StyledHeaderSubTitle = styled.div`
  width: 100%;
  justify-content: center;
  border: 0px solid orange;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: #fff;
  z-index: 10;
  top: -12px;
`;
const StyledDescription = styled.div`
  position: relative;
  padding-left: 10px;
  padding-top: 3px;
  width: 100%;
  height: 80px;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 600;
`;
const StyledFooterRow = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #0AC18E;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  z-index: 2;
`;
const StyledRow = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 2;
  border: 0px solid pink;
  justify-content: center;
  bottom: 0px;
`;
const StyledStakeRow = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  border: 1px solid pink;
  justify-content: right;
  bottom: 0px;
  right: 5px;
`;
const PledgeButton = styled.button<PledgeButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border: 2px solid #0AC18E;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 110px;
  min-width: 50px;
  height: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
  }
  @media (max-width: 550px) {
    width: 100px;
  }
  @media (max-width: 490px) {
    left: 0px;
    width: 100px;
  }
`
const ListButton = styled.button<ListButtonProps>`
  position: relative;
  left: -110px;
  top: -61px;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
    background-color: #222;
  color: #fff;
  border: 2px solid #0AC18E;
    border: 2px solid #666;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
    cursor: not-allowed;
  z-index: 10;
  width: 90px;
  min-width: 50px;
  height: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    background-color: #222;
  }
  @media (max-width: 550px) {
    width: 100px;
  }
  @media (max-width: 490px) {
    left: 0px;
    width: 100px;
  }
`
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: absolute;
  width: 200px;
  height: 200px;
  border-bottom: 2px solid #fff;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
const StyledNFTProjectLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: relative;
  width: 256px;
  height: 256px;
  border: 0px solid orange;
  border-left: 1px solid #0AC18E;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
export const StyledDiscountButton = styled.button<{ discountApplied: boolean }>`
  background-color: ${(props) => (props.discountApplied ? '#0AC18E' : '#f0f0f0')};
  border: 1px solid #0AC18E;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;

 &::after {
    content: '${(props) => (props.discountApplied ? "\\2713" : "")}'; // Unicode checkmark
    color: white;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -55%);
  }
`;
const StyledConnected = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  top: -32px;
  margin-right: 10px;
  border: 0px solid red;
  z-index: 100;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  align-items: center;
  @media (max-width: 1300px) {
    font-size: 16px;
    top: -25px;
  }
  @media (max-width: 850px) {
    font-size: 12px;
  }
`;
const StyledUserAddress = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 850px) {
    font-size: 16px;
  }
`
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  display: flex;
  border: 0px solid red;
`;
export default Market;
