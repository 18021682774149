import { decodeCashAddress, encodeCashAddress } from '@bitauth/libauth';

function toTokenAddress(address: string) {
    console.log('toTokenAddress() called for address: ' + address);
    const addressInfo: any  = decodeCashAddress(address);
    const pkhPayoutBin = addressInfo.payload;

    let prefix: 'bchtest' | 'bitcoincash' | 'bchreg';
    if (address.slice(0, 7) == 'bchtest') {
        prefix = 'bchtest';
    } else {
        prefix = 'bitcoincash';
    }
    
    const tokenAddress = encodeCashAddress(prefix, "p2pkhWithTokens", pkhPayoutBin);
    console.log('toTokenAddress() converted it to: ' + tokenAddress);
    return tokenAddress;
}

export default toTokenAddress;