import React, { useState, useEffect } from 'react';
import styled, { keyframes} from 'styled-components';
import { Link } from 'react-router-dom';
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';
import { Utxo, Contract } from 'cashscript';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { encodeCashAddress } from '@bitauth/libauth';
import { AddressBadgerStake, MasterCategoryID, AddressBadgerMarket } from '../../constants/values'
import BCHLogo from '../../assets/img/bch.png';

interface CampaignUtxo extends Utxo {
  name: string;
  description: string;
  logo: string;
  endDate: string;
}

interface ModalProps {
  onClose: () => void;
  contractBadgerMarket: Contract | undefined;
  handleListBadger: (badger: Utxo, stringListPrice: string) => Promise<void>;
}

const Modal: React.FC<ModalProps> = ({ onClose, contractBadgerMarket, handleListBadger }) => {
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [badgerNFTs, setBadgerNFTs] = useState<Utxo[]>([]);
  const [selectedNFT, setSelectedNFT] = useState<Utxo | null>();
  const [stringListPrice, setStringListPrice] = useState<string>("");

  const closeModal = () => {
    onClose();
  };

  const formatSatoshiToBCH = (satoshis: bigint) => {
    const bch = Number(satoshis) / 100000000;
    return bch.toFixed(8) + ' BCH';
  };
  const LEtoBE = (hex: string) => {
    console.log(hex);
    // Convert hex to big-endian and then to decimal
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    console.log(bigEndianHex);
    return bigEndianHex;
  };
  function hexToUint8Array(hexString: any) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes;
  }
  const hexLEToDecimal = (hex: string): number => {
    // Convert hex to big-endian and then to decimal
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  // Function to take users pledge string, verify only one decimal, remove all but numbers, set state
  const handleSetPledgeAmount = (value: string) => {
    let newValue = value.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

    // Check if there's a decimal point, and if so, limit the length after it to 8
    const decimalIndex = newValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = newValue.substring(0, decimalIndex);
      const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
      newValue = `${integerPart}.${decimalPart}`;
    }

    setStringListPrice(newValue !== "" ? newValue : "");
  }

//////////////////////////////////////////////////
  useEffect(() => {
    async function getBadgerNFTs() {
      if (!electrumServer || usersAddress == '') return;
      console.log('electrumServer detected');

      //delay to allow electrum to stabilize
      setTimeout(async () => {
        const userUTXOs: Utxo[] = await electrumServer.getUtxos(usersAddress);
        const badgerNFTs = userUTXOs.filter( 
          utxo => utxo.token?.category == MasterCategoryID //only badgerNFT's
          && utxo.token?.nft?.capability == 'none' //only mutable ones
        );
        setBadgerNFTs(badgerNFTs);  //save filtered UTXOs to campaigns
          console.log('finished');
          console.log(badgerNFTs.length);
      }, 2000); // Delay of 2 seconds
    }

    getBadgerNFTs();
  }, [electrumServer]);
//////////////////////////////////////////////////

  return (
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={closeModal} />

        <StyledTitle>BadgerNFT's in your wallet</StyledTitle>
        <StyledSubTitle>Select one, set a price, then click List</StyledSubTitle>

        <StyledHeaderRow>
          <StyledPledgeLogo />
          <StyledPledgeAmount>
            <input
              type="string"
              placeholder="List Price"
              value={stringListPrice}
              onChange={(e) => handleSetPledgeAmount(e.target.value)}
              style={{ backgroundColor: 'white', color: 'black' }}
            />
          </StyledPledgeAmount>
        </StyledHeaderRow>

        <ListButton onClick={() => handleListBadger(selectedNFT!, stringListPrice)}>List</ListButton>

        <StyledNFTContainer>
          {[...badgerNFTs.values()].map((badger, index) => (

            <StyledStakeCard onClick={()=>{setSelectedNFT(badger)}} isselected={selectedNFT === badger} key={index}>
              {/*  <StyledLogo logoUrl={require(`../../assets/img/badgers/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`)}/>  */}
              <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`}/>

              <StyledBadgerStatsRow>
                <StyledDescription>ID: {hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}</StyledDescription>
              </StyledBadgerStatsRow>

            </StyledStakeCard>
          ))}
        </StyledNFTContainer>

    </ModalContent>
  );
};

interface MenuButtonProps {
  isactive?: boolean;
}
interface StyledStakeCardProps {
  isselected: boolean;
}
interface LogoDivProps {
  url: string;
}
interface ListButtonProps {
  isactive?: boolean;
}

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const MenuButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#09af81' : '#000')};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #0AC18E;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #004295;
  }
`
const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 90%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: 1px solid #0AC18E;
  flex-direction: column; /* Stack content vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: green;
  border-radius: 40px 40px 0px 40px;
  padding: 1.5rem;    /* Add padding if needed to prevent content sticking to edges */

  @media (max-width: 832px) { /* You can adjust the breakpoint as needed */
    width: 450px;
    height: 400px;
  }

`;
const StyledTitle = styled.div`
  position: absolute;
  top: 5px;
  right: 105px;
  border: 0px solid #0AC18E;
  width: 250px;
  color: white;
  justify-content: center;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
`; 
const StyledSubTitle = styled.div`
  position: absolute;
  top: 28px;
  right: 100px;
  border: 0px solid #0AC18E;
  width: 250px;
  text-align: right;
  color: white;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`; 
const StyledDescription = styled.div`
  border: 0px solid #0AC18E;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: white;
`; 
const CloseButton = styled.button`
  background-image: url(${ImageClose});
  background-size: cover;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  width: 75px;
  height: 45px;
  border: 0px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  z-index: 10;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;
const StyledNFTContainer = styled.div`
display: flex;
flex-wrap: wrap;
overflow-x: auto;
overflow-y: auto;
background-color: #000;
border: 0px solid #0AC18E;
margin: 1px auto;
padding: 1px;
color: white;
height: 95%;
width: 100%;
max-width: 700px;
min-width: 450px;
display: flex;
flex-direction: row;
align-items: flex-start;
position: relative;
top: 30px;
gap: 7px;
`;
const StyledStakeCard = styled.div<StyledStakeCardProps>`
  background-color: #000;
  border: 1px solid ${(props) => (props.isselected ? '#fff' : '#0AC18E')};
  color: white;
  height: 230px;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 0px 0px;
`;
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: relative;
  width: 200px;
  height: 200px;
  border-bottom: 1px solid #fff;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
const StyledHeaderRow = styled.div`
  position: absolute;
  left: 50px;
  top: 10px;
  height: 40px;
  width: 160px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  border: 0px solid pink;
`;
const StyledBadgerStatsRow = styled.div`
  position: relative;
  left: 5px;
  bottom: 0px;
  height: 20px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border: 0px solid pink;
`;
const ListButton = styled.button<ListButtonProps>`
  position: absolute;
  left: 230px;
  top: 10px;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border: 2px solid #0AC18E;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
  width: 110px;
  min-width: 50px;
  height: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
  }
  @media (max-width: 550px) {
    width: 100px;
  }
  @media (max-width: 490px) {
    left: 0px;
    width: 100px;
  }
`
const StyledPledgeAmount = styled.div`
  position: relative;
  right: 0px;
  top: 5px;
  height: 20px;
  display: flex;
  width: 110px;
  border: 0px solid red;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  color: #fff;
  input { 
    width: 100%;
    border: inherit;
    font-size: inherit;
    color: inherit;
}
`;
const StyledPledgeLogo = styled.div`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: flex;
  border: 0px solid red;
`;
export default Modal;
