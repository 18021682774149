import { Contract, Utxo, ElectrumNetworkProvider } from 'cashscript';
import { encodeCashAddress, binToHex, bigIntToVmNumber } from '@bitauth/libauth';

interface LockUtxo extends Utxo {
  startHeight: number;
  lockedBlocks: number;
  timeLeft: string;
}

interface ContractBadgerUnlockParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  usersAddress: string;
  contractBadger: Contract | undefined;
  toTokenAddress: (address: any) => string;
  signTransaction: (options: any) => Promise<unknown>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  lockUTXO: LockUtxo;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function contractBadgerUnlock({ electrumServer, usersAddress, contractBadger, toTokenAddress, signTransaction, setError, lockUTXO }: ContractBadgerUnlockParams) {
  if (electrumServer && contractBadger) { // require electrumServer has been setup, connected to blockchain, and contractBadger is compiled
    // Convert LEhex to BEhex to decimal
    const LEhexToDecimal = (hex: string): number => { 
      const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
      return parseInt(bigEndianHex, 16);
    };
    function hexToUint8Array(hexString: any) {
      const bytes = new Uint8Array(hexString.length / 2);
      for (let i = 0; i < bytes.length; i++) {
          bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
      }
      return bytes;
    }
    const toLittleEndian = (hex: string, byteLength: number) => {
      const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
      const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
      return hexArray.join(''); // Join the array back into a string
    };
  
    //Get all utxos on contract      
    const badgerContractUTXOs = await contractBadger.getUtxos(); 
    console.log('badgerContract utxos:');
    console.log(badgerContractUTXOs);

    console.log('selected lockNFT UTXO: ');
    console.log(lockUTXO);

    //Derive lockUTXO owners address from embedded pubkeyhash
    const ownersPKH = lockUTXO.token?.nft?.commitment.substring(0,40);
    const pkhUint8Array = hexToUint8Array(ownersPKH);
    const prefix = 'bitcoincash';
    const ownersAddress = encodeCashAddress(prefix, 'p2pkh', pkhUint8Array);
    const ownersTokenAddress = encodeCashAddress(prefix, 'p2pkhWithTokens', pkhUint8Array);
    
    console.log('owners address: ', ownersAddress);
    console.log('owners tokenAddress: ', ownersTokenAddress);

  //##  Build transaction        
  //##########################################

    //badgerToken details
    const badgerTokenDetails: TokenDetails = {          
      amount: lockUTXO.token?.amount!,  
      category: lockUTXO.token?.category!,  
      //nft: {
        //capability: 'none',
        //commitment: ''   
      //}
    };

    //phantomToken details
    const honeyTokenDetails: TokenDetails = {          
      amount: 0n,  
      category: lockUTXO.token?.category!,  
      nft: {
        capability: 'none',
        commitment: '0000' + binToHex(bigIntToVmNumber(lockUTXO.token?.amount!)) 
      }
    };
    console.log('Asking to unlock: ', lockUTXO.satoshis + 'sats to pubkeyhash: ' + ownersPKH);

    const lockAge = LEhexToDecimal(lockUTXO.token?.nft?.commitment.substring(76, 80) ?? "0");
    
    let transaction: any;
    try {
      transaction = contractBadger?.functions.unlock()                      
        .from(lockUTXO)                                                 // lockUTXO
        .to(ownersTokenAddress, 1000n, badgerTokenDetails)              // send earned BadgerCoin to users tokenAddress
        .to(ownersTokenAddress, 1000n, honeyTokenDetails)
        .to(ownersAddress, lockUTXO.satoshis - 3000n)                   // send locked BCH back to user
        .withoutChange()                                                // disable automatic change output back to user (see next)
        .withoutTokenChange()
        .withAge(lockAge)

        console.log('constructed transaction:');
        console.log(transaction);

        //const debugged = transaction.debug();
        //console.log(debugged);

        //const txid = await transaction.send();
        //console.log(txid);

        //if (txid) {
        //  setError(`Success: ${txid}`);
        //}

    } catch (error) {
      if (error instanceof Error) {
        setError(`Error: ${error.message}`);
        console.error('Transaction failed:', error);
      } else {
        setError('An unknown error occurred');
        console.error('Transaction failed:', error);
      }
    }

    try {      // build the transaction we created
      const rawTransactionHex = await transaction.build();   
      return rawTransactionHex
      
    } catch (error) {
      if (error instanceof Error) {
        setError(`tx build failed: ${error.message}`);
        console.error('tx build failed:', error);
      } else {
        setError('An unknown tx build error occurred');
        console.error('tx build failed:', error);
      }
    }
  }
}
  
export default contractBadgerUnlock;