import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Sidebar from '../../components/SideBar'; 
import ErrorNotification from '../../components/ErrorNotification';
import ColorChangingStat from '../../components/ColorChangingStat';
import { MasterCategoryID, AddressBadgerMint } from '../../constants/values'
import { Utxo, Contract } from 'cashscript';
import Spinner from '../../assets/img/spinner.gif';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import BCHLogo from '../../assets/img/bch.png';

interface Category {
  name: string;
  questions: { question: string; answer: string; answerVisible: boolean }[];
}

interface StyledAnswerProps {
  visible: boolean;
}
const Wallet = () => {
  const [badgersInWallet, setBadgersInWallet] = useState<Utxo[]>([]);
  const [honeyInWallet, setHoneyInWallet] = useState<Utxo[]>([]);
  const [badgerMarketUTXOsFetched, setBadgerMarketUTXOsFetched] = useState(false);
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const entriesPerPage = 24;
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * entriesPerPage;
  const endIndex = Math.min(startIndex + entriesPerPage, badgersInWallet.length);
  const [totalPages, setTotalPages] = useState(0);
  const [inputPage, setInputPage] = useState('');
  const [error, setError] = useState('');

  // Convert hex to big-endian and then to decimal
  const hexLEToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  //////////////////////////////////////////////////
////////// Initial UseEffect: Get Badger UTXOs listed for mint
//////////////////////////////////////////////////
useEffect(() => {
  async function getBadgerMintUTXOs() {
    if (!electrumServer || usersAddress == '') return;

    //delay to allow electrum to stabilize
    setTimeout(async () => {
      //get all badgerNFTs in users wallet
      const usersBadgerUTXOs: Utxo[] = await electrumServer.getUtxos(usersAddress);
      const badgerNFTs = usersBadgerUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only badgerNFT's
          && utxo.token?.nft?.capability == 'none' //only mutable ones
          && utxo.token?.nft?.commitment.slice(0,4) != '0000'
      );
      setBadgersInWallet(badgerNFTs);
      const totalPages = Math.ceil(badgerNFTs.length / entriesPerPage);

      //get all honeyNFTs in users wallet
      const honeyNFTs = usersBadgerUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only badgerNFT's
          && utxo.token?.nft?.capability == 'none' //only mutable ones
          && utxo.token?.nft?.commitment.slice(0,4) == '0000'
      );
      setHoneyInWallet(honeyNFTs);

      setTotalPages(totalPages)
      setBadgerMarketUTXOsFetched(true);

    }, 2500); // Delay of 2.5 seconds
  }

  getBadgerMintUTXOs();
}, [electrumServer]);

const goToPreviousPage = () => {
  setCurrentPage(Math.max(0, currentPage - 1));
};
const goToNextPage = () => {
    setCurrentPage(Math.min(totalPages - 1, currentPage + 1));
};
const goToPage = () => {
  const pageNumber = parseInt(inputPage, 10);
  if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber - 1); // Adjust for 0-based index
      setInputPage(''); // Clear input
  } else {
      alert('Invalid page number. Please enter a number between 1 and ' + totalPages);
  }
};
const handleInputChange = (e: any) => {
  setInputPage(e.target.value);
};

  return (
    <Container>
      <ErrorNotification message={error} setError={setError} />

      {badgersInWallet.length > 0 && 
        <>
          <StyledText>
            <ArrowButton onClick={goToPreviousPage}>{'<'}</ArrowButton>
            <div>{currentPage + 1} of {totalPages}</div>
            <ArrowButton onClick={goToNextPage}>{'>'}</ArrowButton>
          </StyledText>
          <InputContainer>
            <PageInput
              type="number"
              value={inputPage}
              onChange={handleInputChange}
              placeholder={``}
              min="1"
              max={totalPages}
            />
            <GoButton onClick={goToPage}>Go to Page</GoButton>
          </InputContainer>
        </>
        }

    <StyledTitle>Your Wallet</StyledTitle>  

    <WalletNFTs>
      {honeyInWallet.length > 0 ? (
        honeyInWallet.map((badger, index) => (
          <StyledHoneyCard>
            <StyledNFTCard> 
              <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0")}.png`}/>     
              <StyledTitle>HoneyNFT</StyledTitle>       
              <StyledStakeRow>
                <StyledDescription>Amount:</StyledDescription>
                <StyledStat>{hexLEToDecimal(badger.token?.nft?.commitment.slice(4,12) ?? "0")}</StyledStat>
              </StyledStakeRow>

                {/*    <StyledLogo logoUrl={require(`../../assets/img/badgers/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`)}/>   */}
                {/*    <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.substring(0,4) ?? "0")}.png`}/>   */}
            
            </StyledNFTCard> 
          </StyledHoneyCard>
        ))
      ) : (
        usersAddress != '' ? (
          badgerMarketUTXOsFetched ? (
            <></>
          ) : (
            <StyledSpinner />
          )
        ) : (
          <></>
        )
      )}
<br/>
      {badgersInWallet.length > 0 ? (
        badgersInWallet.map((badger, index) => (
          <StyledStakeCard>
            
            <StyledStatsColumn>
              <StyledStatsEntry>
                <StyledStatTitle>Level</StyledStatTitle>
                <StyledStat>{hexLEToDecimal(badger.token?.nft?.commitment.slice(4,6) ?? "0")}</StyledStat>
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Strength</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(6,8) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Stamina</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(8,10) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Agility</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(10,12) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Wisdom</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(12,14) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Attack</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(14,16) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Defence</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(16,18) ?? "0")} />
              </StyledStatsEntry>
            </StyledStatsColumn>

            <StyledNFTCard> 
              <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0")}.png`}/>            
              <StyledStakeRow>
                <StyledDescription>ID: {hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0")}</StyledDescription>
              </StyledStakeRow>

                {/*    <StyledLogo logoUrl={require(`../../assets/img/badgers/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`)}/>   */}
                {/*    <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.substring(0,4) ?? "0")}.png`}/>   */}
            
            </StyledNFTCard> 
          </StyledStakeCard>
        ))
      ) : (
        usersAddress != '' ? (
          badgerMarketUTXOsFetched ? (
            'No HoneyBadger NFTs detected on your wallet 😞'
          ) : (
            <StyledSpinner />
          )
        ) : (
          'Connect your wallet first'
        )
      )}
    </WalletNFTs>
    </Container>
  );
};

interface LogoDivProps {
  url: string;
}

// Styled Components
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  border-top: 2px solid #fff;
  background: #000;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81;
    }
  }
`;
const WalletNFTs = styled.div`
justify-content: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
height: 100%;
width: 100%;
color: white;
text-align: left;
border: 0px solid red;
`;
const StyledBuyOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: row;
  z-index: 4;
  border: 0px solid pink;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s, visibility 0.3s;
`;
const StyledStakeCard = styled.div`
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 260px;
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  &:hover ${StyledBuyOverlay} {
    opacity: 1;
    visibility: visible;
  }
`;
const StyledHoneyCard = styled.div`
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 260px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  &:hover ${StyledBuyOverlay} {
    opacity: 1;
    visibility: visible;
  }
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  border-bottom: 0px solid #0AC18E;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
`;
const StyledPledgeLogo = styled.div`
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  border: 0px solid red;
`;

const StyledDescription = styled.div`
  position: relative;
  padding-left: 0px;
  width: 58%;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 700;
`;
const StyledStakeRow = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 0px solid yellow;
  bottom: 0px;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
`;
const StyledNFTCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  font-size: 14px;
`;
const StyledStatsColumn = styled.div`
  position: relative;
  height: 100%;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  border-right: 2px solid #f8f8f8;
  font-size: 14px;
`;
const StyledStatsEntry = styled.div`
  position: relative;
  height: 37px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  border-bottom: 0px solid #f8f8f8;
  font-size: 14px;
`;
const StyledStatTitle = styled.div`
  position: relative;
  height: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
  font-size: 12px;
`;
const StyledStat = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-size: 14px;
  font-weight: 700;
  background: #000;
`;
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: relative;
  width: 200px;
  height: 200px;
  border-bottom: 2px solid #fff;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
export const StyledDiscountButton = styled.button<{ discountApplied: boolean }>`
  background-color: ${(props) => (props.discountApplied ? '#0AC18E' : '#f0f0f0')};
  border: 1px solid #0AC18E;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;

 &::after {
    content: '${(props) => (props.discountApplied ? "\\2713" : "")}'; // Unicode checkmark
    color: white;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -55%);
  }
`;
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  display: flex;
  border: 0px solid red;
`;
const StyledButtonSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid red;
`;
const StyledText = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 5px;
  width: 50%;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  gap: 5px;
`;
const ArrowButton = styled.button`
    background: #4CAF50;
    color: #fff;
    border: 1px solid #f8f8f8;
    border-radius: 45px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    width: 27px;
    height: 27px;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #6ac76d;
    }
`;
const InputContainer = styled.div`
  position: relative;
  right: 0px;
  display: flex;
  align-items: center;
  width: 175px;
  border: 0px solid red;
  gap: 5px;
  margin-top: 10px;
`;
const GoButton = styled.button`
  background: #4CAF50;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  height: 30px;
  width: 120px;
  border: 1px solid white;
  border-radius: 10px;
  &:hover {
    background-color: #6ac76d;
  }
`;
const PageInput = styled.input`
  border: 0px solid yellow;
  width: 60px;
  height: 24px;
  font-size: 18px;
`;
export default Wallet;
