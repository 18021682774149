//Testing whether 2 NFT inputs is causing the failure

import { Contract, Utxo, ElectrumNetworkProvider, SignatureTemplate, MockNetworkProvider, randomUtxo } from 'cashscript';
import { hexToBin, cashAddressToLockingBytecode, OpcodesBCH, decodeTransaction, createVirtualMachineBCH, summarizeDebugTrace, stringifyDebugTraceSummary, sha256 } from '@bitauth/libauth';
import { AddressBadgerStake, AddressTokensBadgerStake, MasterCategoryID } from '../constants/values'

interface ContractBadgerWithdrawParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  contractBadger: Contract | undefined;
  usersAddress: string;
  newFee: string;
  signTransaction: (options: any) => Promise<unknown>;
  setError: React.Dispatch<React.SetStateAction<string>>;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function contractBadgerWithdraw({ electrumServer, contractBadger, usersAddress, newFee, signTransaction, setError }: ContractBadgerWithdrawParams) {
  if (electrumServer && contractBadger) { // require electrumServer has been setup, connected to blockchain, and contractBadger is compiled

    // Converts a hex string to little-endian format
    const toLittleEndian = (hex: string, byteLength: number) => {
      const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
      const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
      return hexArray.join(''); // Join the array back into a string
    };

  ///// Get contract masterUTXO /////
    //Get all utxos on contract      
    const badgerContractUTXOs = await contractBadger.getUtxos(); 
    console.log('badgerContract utxos:');
    console.log(badgerContractUTXOs);

    //Find masterNFT
    const masterBadgerUTXO: Utxo = badgerContractUTXOs.find(
      utxo => utxo.token?.category === MasterCategoryID
      && utxo.token?.nft?.capability == 'minting' //is the masterBadger
    )!; //'!' assumes will always be found
    console.log('selected masterBadger UTXO: ');
    console.log(masterBadgerUTXO);

  ///// Get UTXO from users wallet (must be admin wallet) /////
    // Get users UTXO for transaction fee         
    const userUtxos = await electrumServer.getUtxos('bitcoincash:qqnhqxy23rm4xcx3a9td0lefpmc7pat8su3j24rf6k');
    console.log('get user UTXOs: ');
    console.log(userUtxos);

    //filter to only those that have 3000sats and no tokens/NFTs on them
    const userValidUtxos = userUtxos.filter(
      val => !val.token && val.satoshis >= 3000,
    )
    console.log('valid user UTXOs: ');
    console.log(userValidUtxos);

    //select first valid found utxo
    const userUTXO = userValidUtxos[0];
    if (!userUTXO) {
      setError(`No UTXOs with at least 3000+ satoshis found.`);
      console.log('No utxos with 3000+ sats found.');
      return;
    }
    console.log('selected user UTXO: ');
    console.log(userUTXO);

  //##  Build transaction        
  //##########################################
    const existingCommitment = masterBadgerUTXO.token?.nft?.commitment;
    const restCommitment = existingCommitment?.substring(4, 80);
    const newFeeNumber = parseInt(newFee, 10)
    const newCommitment = toLittleEndian(newFeeNumber.toString(16), 2) + restCommitment;

    console.log('newFee: ', newFee)
    console.log('newCommitment: ', newCommitment);

    const masterDetails: TokenDetails = {          
      amount: masterBadgerUTXO.token?.amount!,  
      category: masterBadgerUTXO.token?.category!,  
      nft: {
        capability: masterBadgerUTXO.token?.nft?.capability!, 
        commitment: newCommitment   
      }
    };

  const privKey = '';     
  const userSig = new SignatureTemplate(privKey);
  //const userSig = new SignatureTemplate(Uint8Array.from(Array(32))); 
  const newFeeBigInt = BigInt(newFee);
  const totalWithdraw = masterBadgerUTXO.satoshis + userUTXO.satoshis - 2000n;

    let transaction: any;
    try {
      transaction = contractBadger?.functions.withdraw(newFeeBigInt)                      
        .from(masterBadgerUTXO)                                                     // masterBadgerUTXO
        .fromP2PKH(userUTXO, userSig)                                               // utxo from user wallet
        .to(contractBadger.tokenAddress, 1000n, masterDetails)                      // send masterBadgerUTXO back to contract
        .to("bitcoincash:qqnhqxy23rm4xcx3a9td0lefpmc7pat8su3j24rf6k", totalWithdraw)  // fees to admin
        .withoutChange()                                                            // disable automatic change output back to user (see next)
        .withoutTokenChange()

        console.log(transaction);

        //const debugged = transaction.debug();
        //console.log(debugged);

        const txid = await transaction.send();
        console.log(txid);

    } catch (error) {
      if (error instanceof Error) {
        setError(`Error: ${error.message}`);
        console.error('Transaction failed:', error);
      } else {
        setError('An unknown error occurred');
        console.error('Transaction failed:', error);
      }
    }

    try {                                                                         // build the transaction we created
      const rawTransactionHex = await transaction.build();   
console.log(rawTransactionHex);


      const decodedTransaction = decodeTransaction(hexToBin(rawTransactionHex));            
      if (typeof decodedTransaction === "string") {
        throw ("No suitable utxos found for minting. Try to consolidate your utxos!");
      }
      decodedTransaction.inputs[1].unlockingBytecode = Uint8Array.from([]);     // set the to-be-walletconnect-signed input to empty again
      console.log('decodedTransaction: ');
      console.log(decodedTransaction);

      // construct new transaction object for SourceOutputs, for stringify & not to mutate current network provider 
      const listSourceOutputs = [{
        ...decodedTransaction.inputs[0],
        lockingBytecode: (cashAddressToLockingBytecode(contractBadger.tokenAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(masterBadgerUTXO.satoshis),
        contract: {
          abiFunction: (transaction as any).abiFunction,        // 'as any' type assertion to bypass typescript checking
          redeemScript: (contractBadger as any).redeemScript,
          artifact: (contractBadger as any).artifact,           // 'as any' type assertion to bypass typescript checking
        }
      }, {
        ...decodedTransaction.inputs[1],
        lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(userUTXO.satoshis),
      }];

      //create transaction object to give for signing
      const wcTransactionObj = {
        transaction: decodedTransaction,
        sourceOutputs: listSourceOutputs,
        broadcast: false,
        userPrompt: "Withdraw " + (masterBadgerUTXO.satoshis - 2000n) + "sats from BadgerContract" 
      };
      console.log(wcTransactionObj);

      //pass object to walletconnect for user to sign
      const signResult: any = await signTransaction(wcTransactionObj);

      return signResult; //for walletconnect
      //return rawTransactionHex  //for privateKey signing
      
    } catch (error) {
      if (error instanceof Error) {
        setError(`tx build failed: ${error.message}`);
        console.error('tx build failed:', error);
      } else {
        setError('An unknown tx build error occurred');
        console.error('tx build failed:', error);
      }
    }
  }
}
  
export default contractBadgerWithdraw;