import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import BadgerLogo100 from '../../assets/img/badgercoin100.png';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract } from 'cashscript';
import Modal from '../../components/Modal';
import Spinner from '../../assets/img/spinner.gif';
//blockchain stuff
import ArtifactBadgerMint from '../../contracts/BadgerMint/c_badgerMintV3.json';
import contractBadgerMinter from '../../functions/contractBadgerMinter';
import { stringify, decodeCashAddress } from '@bitauth/libauth';
import { MasterCategoryID, AddressBadgerMint } from '../../constants/values'
import ErrorNotification from '../../components/ErrorNotification';
import ColorChangingStat from '../../components/ColorChangingStat';

const Mint: React.FC = () => {
  const navigate = useNavigate();
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  const [discount, setDiscount] = useState(false);
  const [badgersOnMarket, setBadgersOnMarket] = useState<Utxo[]>([]);
  //const [badgersOnMarket, setBadgersOnMarket] = useState<Array<number>>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const entriesPerPage = 24;
  const startIndex = currentPage * entriesPerPage;
  const endIndex = Math.min(startIndex + entriesPerPage, badgersOnMarket.length);
  const [inputPage, setInputPage] = useState('');

  const [badgerMarketUTXOsFetched, setBadgerMarketUTXOsFetched] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');
  //compiled contracts
  const [contractsOK, setContractsOK] = useState(false);
  const [contractBadgerMint, setContractBadgerMint] = useState<Contract>();
  const [usersBadgerCoinBalance, setUsersBadgerCoinBalance] = useState(0n);
  const [badgerPrice, setBadgerPrice] = useState(0.03);
  const [txPending, setTxPending] = useState(false);

  // Convert hex to big-endian and then to decimal
  const hexLEToDecimal = (hex: string): number => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  // Convert LE to BE
  const LEtoBE = (hex: string) => { 
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return bigEndianHex;
  };
  function hexToUint8Array(hexString: any) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes;
  }

  //Function to convert string to bigint
  function convertStringToBigInt(string: string): bigint {
    const bchAmount = parseFloat(string);                 //Parse the BCH string to a floating-point number
    const satoshis = Math.round(bchAmount * 100_000_000); //Convert BCH to satoshis and round the result to avoid precision issues
    return BigInt(satoshis);                              //Convert the satoshis to bigint for precise integer arithmetic
  }

  // Converts a hex string to little-endian format
  const toLittleEndian = (hex: string, byteLength: number) => {
    const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
    const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
    return hexArray.join(''); // Join the array back into a string
  };

  //convert address to pubkeyhash
  function getAddressesPKH(address: string) {
    try {
      const decoded = decodeCashAddress(address);
      if (typeof decoded === 'string') {
        console.error('Invalid address format');  // Handle error - decoded should be an object for a valid address
        return null;
      }

      // Convert Uint8Array to a hexadecimal string
      const pubkeyhashHex = Array.from(decoded.payload)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return pubkeyhashHex;

    } catch (error) {
      console.error('Error decoding address:', error);
      return null;
    }
  }

//////////Compile an artifact into a CashScript Contract object
  async function compileContract(contractArtifact: any, args: any[]) {
    const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
    return contract;
  }

//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
useEffect(() => {
  console.log('1. useEffect called');
  async function checkAndCompileContracts() {
    console.log('2. compiling contracts...');
    if (contractsOK === false) {
      try {
        const compiledBadgerMint = await compileContract(ArtifactBadgerMint, []);
        setContractBadgerMint(compiledBadgerMint);
        setContractsOK(true);
        console.log('3. contracts OK');
      } catch (error) {
        console.log('contract compiling error: ' + error);
      }
    }
  }

  checkAndCompileContracts();
}, []);

//////////////////////////////////////////////////
////////// Initial UseEffect: Get Badger UTXOs listed for mint
//////////////////////////////////////////////////
useEffect(() => {
  async function getBadgerMintUTXOs() {
    if (!electrumServer) return;

    //delay to allow electrum to stabilize
    setTimeout(async () => {
      const badgerMintUTXOs: Utxo[] = await electrumServer.getUtxos(AddressBadgerMint);
      const filteredMintUTXOs = badgerMintUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only badgerNFT's
          && utxo.token?.nft?.capability == 'none' //only mutable ones
      );
      setBadgersOnMarket(filteredMintUTXOs);

      /*
                // Populate temp array with numbers from 1 to 10000
                const newArray = Array.from({ length: 10000 }, (_, index) => index + 1);
                setBadgersOnMarket(newArray);

                const totalPages = Math.ceil(newArray.length / entriesPerPage);
      */
      const totalPages = Math.ceil(filteredMintUTXOs.length / entriesPerPage);

      setTotalPages(totalPages)
      setBadgerMarketUTXOsFetched(true);

    }, 2500); // Delay of 2.5 seconds
  }

  getBadgerMintUTXOs();
}, [electrumServer]);

//////////////////////////////////////////////////
////////// UseEffect: Get users BadgerCoin balance
//////////////////////////////////////////////////
useEffect(() => {
  async function getBadgerCoinBalance() {
    if (!electrumServer || usersAddress == '') return;

    //delay to allow electrum to stabilize
    setTimeout(async () => {
      const userUTXOs: Utxo[] = await electrumServer.getUtxos(usersAddress);

      //////get BadgerCoins total
      const filteredUTXOs = userUTXOs.filter( 
        utxo => utxo.token?.category == MasterCategoryID //only badger category
          && utxo.token?.amount > 0n                     //has a token balance
      );
      //total amount of BadgerCoins on those utxos
      const badgerCoinBalance = filteredUTXOs.reduce((acc, utxo) => acc + utxo.token?.amount!, 0n);
      setUsersBadgerCoinBalance(badgerCoinBalance);

    }, 2000); // Delay of 2 seconds
  }

  getBadgerCoinBalance();
}, [electrumServer, usersAddress]);

  /*
  const openModal = (utxo: CampaignUtxo) => {
    if (!modalOpen) {
       setSelectedData(utxo);
       setModalOpen(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedData(null);
    setModalOpen(false);
  };
*/
  async function signTransaction(options: any) {
    console.log('signing transaction...');
    try {
        if (walletConnectInstance) {
        const params = JSON.parse(stringify(options));
        console.log('wc params:');
        console.log(params);
        const result = await walletConnectInstance.request({
            chainId: connectedChain,
            topic: walletConnectSession.topic,
            request: {
            method: "bch_signTransaction",
            params: params,
            },
        });
        return result;
        }
    } catch (error) {
        console.log('signTransation error: ' + error);
        return undefined;
    }
  }

  // Function to mint a badgerNFT
  const handleMint = async (badger: Utxo) => {
    console.log('handleMint: ');

    if (electrumServer && usersAddress != '') {
      setTxPending(true);
      const hasDiscount = discount;
      const signResult = await contractBadgerMinter({electrumServer, usersAddress, contractBadgerMint, signTransaction, badger, hasDiscount, setError});
      if (signResult != undefined) {
        const rawTx = signResult.signedTransaction;
        //const rawTx = signResult;
        console.log('signedTransaction from walletconnect: ');
        console.log(signResult);
  
        electrumServer.sendRawTransaction(rawTx).then((result: string) => {
          setError(`Success: ${result}`);
          console.log('Broadcasted, txid: ' + result);
        }).catch((error: Error) => {
          setError('Error: ' + error);
          console.log('Broadcast error: ' + error);
          setTxPending(false);
        });
      }

    } else {
      setError('Error. Is your wallet connected?');
      console.log('handleMint does not detect electrum or a user address');
    }

    setTxPending(false);
  }

  const openModal = () => {
    if (!modalOpen) {
       setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = (event: any) => {
    setDiscount(event.target.checked);
    setBadgerPrice(badgerPrice == 0.03 ? 0.015 : 0.03);
  };

  const goToPreviousPage = () => {
    setCurrentPage(Math.max(0, currentPage - 1));
  };

  const goToNextPage = () => {
      setCurrentPage(Math.min(totalPages - 1, currentPage + 1));
  };

  const goToPage = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber - 1); // Adjust for 0-based index
        setInputPage(''); // Clear input
    } else {
        alert('Invalid page number. Please enter a number between 1 and ' + totalPages);
    }
  };

const handleInputChange = (e: any) => {
  setInputPage(e.target.value);
};

const getColorForValue = (value: number) => {
  // Ensure the value is between 0 and 10
  const normalizedValue = Math.max(0, Math.min(10, value));
  
  // Calculate the color components
  const red = Math.round(255 * (1 - normalizedValue / 10));
  const green = Math.round(255 * (normalizedValue / 10));
  
  return `rgb(${red}, ${green}, 0)`;
};

  return (
    <Container>
      <ErrorNotification message={error} setError={setError} />

      <StyledMarketHeader>
        <StyledTextBackground />
        <StyledHeaderTitle title={"Mint a new Badger"}>{badgersOnMarket.length}/10000 Badgers Available</StyledHeaderTitle>
        <StyledHeaderSubTitle></StyledHeaderSubTitle>

        <StyledBalanceContainer>
          Your <StyledBadgerLogo title="BadgerCoins" />: {usersBadgerCoinBalance.toString()}
        </StyledBalanceContainer>

        <StyledDiscountContainer>
          <StyledCheckbox 
            disabled={usersBadgerCoinBalance < 0n}
            checked={discount}
            onChange={handleCheckboxChange}
          />
          10 <StyledBadgerLogo /> for 50% Discount
        </StyledDiscountContainer>

      </StyledMarketHeader>

      <StyledConnected>
        {usersAddress ? (
          <StyledUserAddress>
            Connected: <span style={{ color: '#5ccc60' }}>{`${usersAddress.substring(12, 22)} ... ${usersAddress.substring(usersAddress.length - 10)}`}</span>
          </StyledUserAddress>
          ) : (
            <></>
        )}
      </StyledConnected>

      {badgersOnMarket.length > 0 && 
      <>
        <StyledText>
          <ArrowButton onClick={goToPreviousPage}>{'<'}</ArrowButton>
          <div>{currentPage + 1} of {totalPages}</div>
          <ArrowButton onClick={goToNextPage}>{'>'}</ArrowButton>
        </StyledText>
        <InputContainer>
          <PageInput
            type="number"
            value={inputPage}
            onChange={handleInputChange}
            placeholder={``}
            min="1"
            max={totalPages}
          />
          <GoButton onClick={goToPage}>Go to Page</GoButton>
        </InputContainer>
      </>
      }

    {/*   BadgerNFT Cards    */}
      <StyledCards>

      {badgersOnMarket.length > 0 ? (
        badgersOnMarket.slice(startIndex, endIndex).map((badger, index) => (
          <StyledStakeCard>
            
            <StyledStatsColumn>
              <StyledStatsEntry>
                <StyledStatTitle>Level</StyledStatTitle>
                <StyledStat>{hexLEToDecimal(badger.token?.nft?.commitment.slice(4,6) ?? "0")}</StyledStat>
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Strength</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(6,8) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Stamina</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(8,10) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Agility</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(10,12) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Wisdom</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(12,14) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Attack</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(14,16) ?? "0")} />
              </StyledStatsEntry>
              <StyledStatsEntry>
                <StyledStatTitle>Defence</StyledStatTitle>
                <ColorChangingStat value={hexLEToDecimal(badger.token?.nft?.commitment.slice(16,18) ?? "0")} />
              </StyledStatsEntry>
            </StyledStatsColumn>

            <StyledNFTCard> 
              <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0")}.png`}/>            
              <StyledStakeRow>
                <StyledDescription>ID: {hexLEToDecimal(badger.token?.nft?.commitment.slice(0,4) ?? "0")}</StyledDescription>
                <StyledPledgeLogo /> {badgerPrice}    
              </StyledStakeRow>

              
              <MintButton onClick={() => handleMint(badger)}>
                {txPending ? (
                  <StyledButtonSpinner />
                ) : (
                  'Mint'
                )}
              </MintButton>

                {/*    <StyledLogo logoUrl={require(`../../assets/img/badgers/${hexLEToDecimal(badger.token?.nft?.commitment.substring(74,80) ?? "0")}.png`)}/>   */}
                {/*    <StyledLogo url={`https://badgers.cash/honeybadgers/icons/${hexLEToDecimal(badger.token?.nft?.commitment.substring(0,4) ?? "0")}.png`}/>   */}
            
            </StyledNFTCard> 
          </StyledStakeCard>
        ))
      ) : (
        badgerMarketUTXOsFetched ? (
          'No HoneyBadger NFTs available to adopt 😞'
        ) : (
          <StyledSpinner />
        )
      )}

      </StyledCards>
    </Container>
  );
};

interface LogoDivProps {
  url: string;
}
interface MintButtonProps {
  isactive?: boolean;
}
interface DetailsButtonProps {
  isactive?: boolean;
}
interface StyledStatProps {
  value: number;
}
const sheen = keyframes`
  0% {
    left: -29%;
  }
  100% {
    left: 200%;
  }
`;

const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

// Styled Components
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #000;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81;
    }
  }
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  max-height: 840px;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledTextBackground = styled.div`
  position: absolute;
  top: -2px;
  width: 500px;
  height: 50px;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  z-index: 0;
  background-color: #113c2b;
  opacity: 1;
  border-radius: 0px 0px 50px 50px;
`;
const StyledMarketHeader = styled.div`
  border-top: 2px solid #fff;
  color: white;
  height: 50px;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  border-radius: 0px 0px 0px 0px;
`;
const StyledCards = styled.div`
  border: 0px solid red;
  color: white;
  height: 100%;
  width: 90%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; // Horizontally center content
  position: relative;
  border-radius: 0px 0px 0px 0px;
  margin-top: 10px;
`;
const StyledBuyOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: row;
  z-index: 4;
  border: 0px solid pink;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s, visibility 0.3s;
`;
const StyledBuyBackground = styled.div`
  position: absolute;
  top: 0px;
  background-color: #000;
  opacity: 0.4;
  height: 200px;
  width: 100%;
  display: flex;
  z-index: 3;
`;
const StyledStakeCard = styled.div`
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 260px;
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  &:hover ${StyledBuyOverlay} {
    opacity: 1;
    visibility: visible;
  }
`;
const StyledNFTProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #00cc66;
  margin: 5px;
`;
const StyledNFTProject = styled.div`
  background-color: #000;
  border: 0px solid #00cc66;
  color: white;
  height: 256px;
  width: 500px;
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Center horizontally */
  justify-content: center; // Horizontally center content
  overflow: hidden;
  position: relative; /* Add position relative to contain absolute child */
  border-radius: 0px 0px 0px 0px;
`;
const NFTRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border: 0px solid blue;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
`;
const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  border: 0px solid red;
  border-bottom: 1px solid #0AC18E;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  text-align: center;
`;
const StyledPrice = styled.div`
  display: flex;
  height: 30px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  text-decoration: none;
  color: #fff;
  z-index: 2;
`;
const StyledPledgeLogo = styled.div`
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  border: 0px solid red;
`;
const StyledText = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 5px;
  width: 50%;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  gap: 5px;
`;
const StyledName = styled.div`
  width: 50%;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  padding-right: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #fff;
`;
const StyledHeaderTitle = styled.div`
  width: 100%;
  justify-content: center;
  border: 0px solid orange;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: -14px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  opacity: 1.0;
  z-index: 1;
  margin-bottom: 4px;
`;
const StyledHeaderSubTitle = styled.div`
  width: 100%;
  justify-content: center;
  border: 0px solid pink;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  top: -15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  z-index: 10;
`;
const StyledDescription = styled.div`
  position: relative;
  padding-left: 0px;
  width: 58%;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 700;
`;
const StyledPriceRow = styled.div`
  display: flex;
  flex-direction: row;
  border: 0px solid red;
  height: 30px;
  width: 150px;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;
const StyledFooterRow = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #0AC18E;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  z-index: 2;
`;
const StyledRow = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 2;
  border: 0px solid pink;
  justify-content: center;
  bottom: 0px;
`;
const StyledStakeRow = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 0px solid yellow;
  bottom: 0px;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
`;
const StyledNFTCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  font-size: 14px;
`;
const StyledStatsColumn = styled.div`
  position: relative;
  height: 100%;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  border-right: 2px solid #f8f8f8;
  font-size: 14px;
`;
const StyledStatsEntry = styled.div`
  position: relative;
  height: 37px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  border-bottom: 0px solid #f8f8f8;
  font-size: 14px;
`;
const StyledStatTitle = styled.div`
  position: relative;
  height: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
  font-size: 12px;
`;
const StyledStat = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-size: 14px;
  font-weight: 700;
  background: #000;
`;
const MintButton = styled.button<MintButtonProps>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border-top: 1px solid #f8f8f8;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  height: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    border-top: 1px solid #fff;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 75%);
    animation: ${sheen} 2.0s infinite linear;
  }
`
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: relative;
  width: 200px;
  height: 200px;
  border-bottom: 2px solid #fff;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
const StyledNFTProjectLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.url});
  background-size: cover;
  position: relative;
  width: 256px;
  height: 256px;
  border: 0px solid orange;
  border-left: 1px solid #0AC18E;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
export const StyledDiscountButton = styled.button<{ discountApplied: boolean }>`
  background-color: ${(props) => (props.discountApplied ? '#0AC18E' : '#f0f0f0')};
  border: 1px solid #0AC18E;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;

 &::after {
    content: '${(props) => (props.discountApplied ? "\\2713" : "")}'; // Unicode checkmark
    color: white;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -55%);
  }
`;
const StyledConnected = styled.div`
  position: absolute;
  display: flex;
  right: 0px;
  top: -34px;
  margin-right: 10px;
  border: 0px solid red;
  z-index: 100;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  align-items: center;
  @media (max-width: 1300px) {
    font-size: 16px;
    top: -25px;
  }
  @media (max-width: 850px) {
    font-size: 12px;
  }
`;
const StyledUserAddress = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 850px) {
    font-size: 16px;
  }
`
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  display: flex;
  border: 0px solid red;
`;
const StyledButtonSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid red;
`;
const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-right: 2px;

  // Hiding the default checkbox appearance
  appearance: none;
  width: 25px; // Set the size of the checkbox
  height: 25px;
  background: #ffd6d6;
  border: 2px solid #ff4848;
  border-radius: 4px;
  &:hover {
    border-color: #14a557;
  }
  &:checked {
    background-color: #14a557;
    border-color: white;
  }

  // Focus styles (when clicked and focused)
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,0,255,0.5);
  }

  // Before and After pseudo-elements can be used to style checkmark or 'X'
  &:checked::after {
    content: '\\2714'; // Unicode character for checkmark
    color: white;
    position: absolute;
    left: 4px;
    top: -2px;
    font-size: 16px;
  }
`;
const StyledBadgerLogo = styled.div`
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  background-image: url(${BadgerLogo100});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: flex;
  border: 0px solid red;
  @media (max-width: 620px) {
    height: 25px;
    width: 25px;
  }
`;
const StyledDiscountContainer = styled.div`
  position: relative;
  left: 35px;
  top: -17px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 0px solid red;
  @media (max-width: 620px) {
    width: 220px;
    font-size: 14px;
    left: 20px;
  }
`;
const StyledBalanceContainer = styled.div`
  position: relative;
  left: 0px;
  top: -17px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 0px solid yellow;
  @media (max-width: 620px) {
    font-size: 14px;
    width: 200px;
    left: 0px;
  }
`;
const ArrowButton = styled.button`
    background: #4CAF50;
    color: #fff;
    border: 1px solid #f8f8f8;
    border-radius: 45px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    width: 27px;
    height: 27px;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #6ac76d;
    }
`;
const InputContainer = styled.div`
  position: relative;
  right: 0px;
  display: flex;
  align-items: center;
  width: 175px;
  border: 0px solid red;
  gap: 5px;
  margin-top: 10px;
`;
const GoButton = styled.button`
  background: #4CAF50;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  height: 30px;
  width: 120px;
  border: 1px solid white;
  border-radius: 10px;
  &:hover {
    background-color: #6ac76d;
  }
`;
const PageInput = styled.input`
  border: 0px solid yellow;
  width: 60px;
  height: 24px;
  font-size: 18px;
`;
const SlantedBeta = styled.div`
  position: absolute;
  left: 0px;
  top: -160px;
  background-color: #be5b5b;
  height: 30px;
  width: 520px;
  color: #fff;
  font-size: 16px;
  font-weight: 700x;
  justify-content: center;
  border: 2px solid #fff;
  z-index: 1;
  @media (max-width: 750px) {
    font-size: 14px;
    height: 42px;
    width: 250px;
  }
`;
const SlantedBetaText = styled.div`
  position: relative;
  width: 100%;
  border: 0px solid green;
  top: 3px;
  text-align: center;
`;
export default Mint;
