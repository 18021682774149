//testing 1NFT input for unlock()

import { Contract, Utxo, ElectrumNetworkProvider, SignatureTemplate } from 'cashscript';
import { hexToBin, cashAddressToLockingBytecode,  decodeTransaction } from '@bitauth/libauth';
import { AddressBadgerStake,  MasterCategoryID } from '../constants/values'

interface SignMessageParams {
  message: string;
  userPrompt?: string;
}

interface ContractBadgerLockParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  usersAddress: string;
  contractBadger: Contract | undefined;
  lockAmount: bigint;
  signMessage: (params: SignMessageParams) => Promise<string | undefined>;
  signTransaction: (options: any) => Promise<unknown>;
  commitment: string;
  userStakeLength: number;
  setError: React.Dispatch<React.SetStateAction<string>>;
  pubkeyhash: string;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function contractBadgerLock({ electrumServer, usersAddress, contractBadger, lockAmount, signMessage, signTransaction, commitment, userStakeLength, setError, pubkeyhash }: ContractBadgerLockParams) {
  if (electrumServer && contractBadger) { // require electrumServer has been setup, connected to blockchain, and contractBadger is compiled

    function hexLEToBigInt(hexLE: string): bigint {
      const hexBE = hexLE.match(/.{2}/g)?.reverse().join('') ?? '0';        // Convert the hex string from little-endian to big-endian
      return BigInt('0x' + hexBE);                                          // Convert the big-endian hex string directly to a BigInt
    }

    //Get all utxos on contract      
    const badgerContractUTXOs = await contractBadger.getUtxos(); 
    console.log('badgerContract utxos:');
    console.log(badgerContractUTXOs);

    //Find masterNFT
    const masterBadgerUTXO: Utxo = badgerContractUTXOs.find(
      utxo => utxo.token?.category === MasterCategoryID
      && utxo.token?.nft?.capability == 'minting' //is the masterBadger
    )!; //'!' assumes will always be found
    console.log('selected masterBadger UTXO: ');
    console.log(masterBadgerUTXO);

    // Get users UTXO for transaction          
    const userUtxos = await electrumServer.getUtxos(usersAddress);
    console.log('get user UTXOs: ');
    console.log(userUtxos);

    //filter to only those that have (lockAmount + 2000)+ sats and no tokens/NFTs on them
    const userValidUtxos = userUtxos
    .filter(val => !val.token && val.satoshis >= lockAmount + 2000n)
    console.log('valid user UTXOs: ');
    console.log(userValidUtxos);

    //select first valid found utxo
    const userUTXO = userValidUtxos[0];
    if (!userUTXO) {
      setError(`No UTXOs with at least ` + (lockAmount + 2000n) + ` satoshis found. You may need to consolidate your UTXOs.`);
      console.log(`No UTXOs with at least ` + (lockAmount + 2000n) + ` satoshis found. You may need to consolidate your UTXOs.`);
      return;
    }
    console.log('selected user UTXO: ');
    console.log(userUTXO);

  //##  Build transaction        
  //##########################################

  /*
    //get users signature so contract can prove pubkeyhash ownership
    const signedMessageBase64: any = await signMessage({ message: 'Claim transaction for campaign', userPrompt: 'Claiming campaign' });
      //paytaca claim() fails here, shows on phone fine: 
      //Object { context: "client" } Error: Missing or invalid. Decoded payload on topic 9fb1d5794301c642d3d92a3084a70e8d7d252c37f085422c51b877dd4fd695e9 is not identifiable as a JSON-RPC request or a response.
    
    const binaryString = window.atob(signedMessageBase64);

    // Create a Uint8Array from the binary string
    const signedMessageByteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        signedMessageByteArray[i] = binaryString.charCodeAt(i);
    }
    console.log('signedMessageByteArray:');
    console.log(signedMessageByteArray);
*/
    const rewardAmount = (lockAmount * BigInt(userStakeLength)) / 100000000n;   //badgerProduction contract
    //const rewardAmount = (lockAmount * BigInt(userStakeLength));    //badgerTest contract
    console.log('rewardAmount: ', rewardAmount);
    console.log('userStakeLength: ', userStakeLength);
    //contractNFT details
    const masterDetails: TokenDetails = {          
      amount: masterBadgerUTXO.token?.amount! - rewardAmount, 
      category: masterBadgerUTXO.token?.category!,  
      nft: {
        capability: masterBadgerUTXO.token?.nft?.capability!, 
        commitment: masterBadgerUTXO.token?.nft?.commitment!   
      }
    };

    const lockDetails: TokenDetails = {
      amount: rewardAmount,
      category: masterBadgerUTXO.token?.category!,  
      nft: {
        capability: 'mutable', 
        commitment: commitment  //finalCommitment
      }
    };
    
    const stakeFee = hexLEToBigInt(masterBadgerUTXO.token?.nft?.commitment.substring(0, 4) ?? "0")
    console.log('stakeFee is: ' + stakeFee);
    const stakeLength = BigInt(userStakeLength);
    //const pubkeyhash = commitment.substring(0, 40);
    const userSig = new SignatureTemplate(Uint8Array.from(Array(32)));            // empty signature as placeholder for building process. walletconnect will replace sig later
    console.log('user asking to lock: ', lockAmount + 'sats to pubkey: ' + pubkeyhash);

let transaction: any;
    try {
      transaction = contractBadger?.functions.lock(lockAmount, stakeLength, pubkeyhash)                      
        .from(masterBadgerUTXO)                                                     // masterBadgerUTXO utxo
        .fromP2PKH(userUTXO, userSig)                                               // users chosen utxo + placeholder empty userSignature
        .to(contractBadger.tokenAddress, masterBadgerUTXO.satoshis + stakeFee, masterDetails)  // send masterBadgerUTXO back to contract
        .to(contractBadger.tokenAddress, lockAmount, lockDetails)                   // create immutable badgerNFT to contract with users locked BCH
        .withoutChange()                                                            // disable automatic change output back to user (see next)
        .withoutTokenChange()

      const changeAmount = (userUTXO.satoshis - (lockAmount + 2000n));              // how many sats remain on users utxo after removing 2000sats for NFT + miner fee
      if (changeAmount >= 600n) transaction.to(usersAddress, changeAmount);         // if change is over dust limit then send it back to user. if not, miners will keep it
      
      console.log('constructed transaction:');
      console.log(transaction);

      //const debugged = transaction.debug();
      //console.log(debugged);

      //const txid = await transaction.send();
      //console.log(txid);

    } catch (error) {
      if (error instanceof Error) {
        setError(`Error: ${error.message}`);
        console.error('Transaction failed:', error);
      } else {
        setError('An unknown error occurred');
        console.error('Transaction failed:', error);
      }
    }

    try {                                                                         // build the transaction we created
      const rawTransactionHex = await transaction!.build();                                  
      const decodedTransaction = decodeTransaction(hexToBin(rawTransactionHex));            
      if (typeof decodedTransaction === "string") {
        throw ("No suitable utxos found for minting. Try to consolidate your utxos!");
      }
      decodedTransaction.inputs[1].unlockingBytecode = Uint8Array.from([]);     // set the to-be-walletconnect-signed input to empty again
      console.log('decodedTransaction: ');
      console.log(decodedTransaction);

      // construct new transaction object for SourceOutputs, for stringify & not to mutate current network provider 
      const listSourceOutputs = [{
        ...decodedTransaction.inputs[0],
        lockingBytecode: (cashAddressToLockingBytecode(contractBadger.address) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(masterBadgerUTXO.satoshis),
        contract: {
          abiFunction: (transaction as any).abiFunction,        // 'as any' type assertion to bypass typescript checking
          redeemScript: (contractBadger as any).redeemScript,
          artifact: (contractBadger as any).artifact,           // 'as any' type assertion to bypass typescript checking
        }
      }, {
        ...decodedTransaction.inputs[1],
        lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(userUTXO.satoshis),
      }];

      //create transaction object to give for signing
      const wcTransactionObj = {
        transaction: decodedTransaction,
        sourceOutputs: listSourceOutputs,
        broadcast: false,
        userPrompt: "Lock " + lockAmount + "sats for " + userStakeLength + " blocks"
      };
      console.log(wcTransactionObj);

      //pass object to walletconnect for user to sign
      const signResult: any = await signTransaction(wcTransactionObj);

      return signResult;
        
    } catch (error) {
      if (error instanceof Error) {
        setError(`tx build failed: ${error.message}`);
        console.error('tx build failed:', error);
      } else {
        setError('An unknown tx build error occurred');
        console.error('tx build failed:', error);
      }
    }
  }
}
  
export default contractBadgerLock;