//BadgerContract V1
//export const AddressBadgerStake = 'bitcoincash:p06awgcwey864a9xquqf6tzfd43t6tq76y2r7vd7z9zxrlhr8sfd70a5n66p9';
//export const AddressTokensBadgerStake = 'bitcoincash:r06awgcwey864a9xquqf6tzfd43t6tq76y2r7vd7z9zxrlhr8sfd7awgjrmcw';
//export const MasterCategoryID = 'b243bfceff4d26579ad39448d3cd04eef711d1717668e08266391f18d508b56d'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '6db508d5181f396682e0687671d111f7ee04cdd34894d39a57264dffcebf43b2'; //LittleEndian - vm script use

//BadgerContract V2
//export const AddressBadgerStake = 'bitcoincash:p0qfan66lzgjynlz5dylklfs22a8v5rfud8v0j6c5wxvh5ypfvc665dsl0tts';
//export const AddressTokensBadgerStake = 'bitcoincash:r0qfan66lzgjynlz5dylklfs22a8v5rfud8v0j6c5wxvh5ypfvc66x7v7k2jm';
//export const MasterCategoryID = '3d330f340d6815adbe8602655249c3c56b77edfca22ad1b3a1985d06f5f498d5'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'd598f4f5065d98a1b3d12aa2fced776bc5c34952650286bead15680d340f333d'; //LittleEndian - vm script use

//BadgerContract V3
//export const AddressBadgerStake = 'bitcoincash:pdn6gw4tceufaykt3wrc6c0awla9ad30wspej83f88d9jlrxet80287yz98w4';
//export const AddressTokensBadgerStake = 'bitcoincash:rdn6gw4tceufaykt3wrc6c0awla9ad30wspej83f88d9jlrxet8024dcruxh7';
//export const MasterCategoryID = 'b5f8d2a368c47fbfe762d0b97976ea07c73825782435e35d99773095ee766e20'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '206e76ee953077995de33524782538c707ea7679b9d062e7bf7fc468a3d2f8b5'; //LittleEndian - vm script use

//BadgerContract V4
//export const AddressBadgerStake = 'bitcoincash:pvz0kntcvywv09l6keh6aya6l57w2k5jpczk626sxdglx6p9lhte7z28xn6vf';
//export const AddressTokensBadgerStake = 'bitcoincash:rvz0kntcvywv09l6keh6aya6l57w2k5jpczk626sxdglx6p9lhte7sem82m4z';
//export const MasterCategoryID = '20ce326a4ea0b2477c1cabc550c71caf0a17d4765214c92c7072210b40b39776'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '7697b3400b2172702cc9145276d4170aaf1cc750c5ab1c7c47b2a04e6a32ce20'; //LittleEndian - vm script use

//BadgerContract V5
//export const AddressBadgerStake = 'bitcoincash:p0ycvrkzvs8al29v3wxdlhk43aw0k9t5r9gzvqffage3x7sha84wqwxhqt2ry';
//export const AddressTokensBadgerStake = 'bitcoincash:r0ycvrkzvs8al29v3wxdlhk43aw0k9t5r9gzvqffage3x7sha84wqu4tpjt60';
//export const MasterCategoryID = '3f89daade6f8a91ae032a861c3cbed0656ed7bfdd595565a1d8b11d60718c530'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '30c51807d6118b1d5a5695d5fd7bed5606edcbc361a832e01aa9f8e6adda893f'; //LittleEndian - vm script use

//BadgerContract V6
//export const AddressBadgerStake = 'bitcoincash:pwn5g65lk8macc95lcrdsy3n3dddnrv74sup4y5cqpymau6j0dnk2sxy0fkcc';
//export const AddressTokensBadgerStake = 'bitcoincash:rwn5g65lk8macc95lcrdsy3n3dddnrv74sup4y5cqpymau6j0dnk2z4cwshpn';
//export const MasterCategoryID = '2f0d0971ebbdf54d24a758cf383f3b95db1a96f8df43baab17e7dad00ca5c8be'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'bec8a50cd0dae717abba43dff8961adb953b3f38cf58a7244df5bdeb71090d2f'; //LittleEndian - vm script use

//BadgerContract V7 - publicTest account (mathieau)
//export const AddressBadgerStake = 'bitcoincash:pd2xy2kyfx6k4gkc3ze27h6s22axwq49t0fuldgsqgkf2822h4en5q86dpvd9';
//export const AddressTokensBadgerStake = 'bitcoincash:rd2xy2kyfx6k4gkc3ze27h6s22axwq49t0fuldgsqgkf2822h4en5j5xvcd5w';
//export const AddressBadgerMarket = 'bitcoincash:pd2xy2kyfx6k4gkc3ze27h6s22axwq49t0fuldgsqgkf2822h4en5q86dpvd9';//placeholder
//export const AddressTokensBadgerMarket = 'bitcoincash:pd2xy2kyfx6k4gkc3ze27h6s22axwq49t0fuldgsqgkf2822h4en5q86dpvd9';//placeholder
//export const MasterCategoryID = '8e71c3ea495d04c606430bd73a286046771982e70e21d29233f5e413b7e99f71'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '719fe9b713e4f53392d2210ee78219774660283ad70b4306c6045d49eac3718e'; //LittleEndian - vm script use

//BadgerContract V8 - fixed cashscript debugging
//export const AddressBadgerStake = 'bitcoincash:p070mg8qlhky87mdlg9n2zna9dp898vc5ja7llcc7ekzl9z32dj6wh4lk7f9y';
//export const AddressTokensBadgerStake = 'bitcoincash:r070mg8qlhky87mdlg9n2zna9dp898vc5ja7llcc7ekzl9z32dj6w9xrh8gu0';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = 'f7e46ee0744c9083ebb130d3a9befc3a79e7c6c62417064803d0ef98230bd79f'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '9fd70b2398efd00348061724c6c6e7793afcbea9d330b1eb83904c74e06ee4f7'; //LittleEndian - vm script use

//BadgerContract V9 - 1NFT input test [WORKED!]
//export const AddressBadgerStake = 'bitcoincash:p0lxwzk2plg7s8ec29y6jsakylele9h3lqq2s9np466veve09a7myc3gjz4kz';
//export const AddressTokensBadgerStake = 'bitcoincash:r0lxwzk2plg7s8ec29y6jsakylele9h3lqq2s9np466veve09a7my2z5nm50f';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '997147eedf413bce3b396813419a4036e2e6f46264f15bd8535999ab57eff4b6'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'b6f4ef57ab995953d85bf16462f4e6e236409a411368393bce3b41dfee477199'; //LittleEndian - vm script use

//BadgerContract V10 - 1NFT with reward-per-block pre-determined [contract requires 1BCH locked for 1 day minimum. oops]
//export const AddressBadgerStake = 'bitcoincash:p03vzc7wlx79xr8mc3dqcq03905f06qcxdusvens6n56nzj6guydjl5t6v6sn';
//export const AddressTokensBadgerStake = 'bitcoincash:r03vzc7wlx79xr8mc3dqcq03905f06qcxdusvens6n56nzj6guydjd8hm4mfc';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '590e5e3ef5978fa4e88904ce13774f7d02f7b1070909033367f42b6d3a87815d'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '5d81873a6d2bf4673303090907b1f7027d4f7713ce0489e8a48f97f53e5e0e59'; //LittleEndian - vm script use

//BadgerContract V11 - 1NFT with reward-per-block pre-determined
//export const AddressBadgerStake = 'bitcoincash:pvfrplpyw0k3txn8wfd0pe55fq4yp3zcpfnh7x5rht68r02h9l4r7vqmnjxcn';
//export const AddressTokensBadgerStake = 'bitcoincash:rvfrplpyw0k3txn8wfd0pe55fq4yp3zcpfnh7x5rht68r02h9l4r77n8jt8pc';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = 'b8f6ca81ceda9b278b04966e21899ee21c867f203c6021141b06df55cc8a7158'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '58718acc55df061b1421603c207f861ce29e89216e96048b279bdace81caf6b8'; //LittleEndian - vm script use

//BadgerContract V12 - 1NFT with reward-per-block pre-determined (real contract?)
//export const AddressBadgerStake = 'bitcoincash:pd0nw5pw2all4r22qq0hwax9fu4xkwqgu3nmg0xmeanw9vd0jkch2877vh0un';
//export const AddressTokensBadgerStake = 'bitcoincash:rd0nw5pw2all4r22qq0hwax9fu4xkwqgu3nmg0xmeanw9vd0jkch24dzdww9c';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '85a8cb26e469b79574941afcba57ecb7f8269859d4f497728301d776f91497ec'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'ec9714f976d701837297f4d4599826f8b7ec57bafc1a947495b769e426cba885'; //LittleEndian - vm script use

//BadgerContract V13
//export const AddressBadgerStake = 'bitcoincash:p0xhw9gd904uetvd6phakd225wjw24rpvr5dlf58gpe26zq27m94cme8pmud6';
//export const AddressTokensBadgerStake = 'bitcoincash:r0xhw9gd904uetvd6phakd225wjw24rpvr5dlf58gpe26zq27m94cf2mqza53';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '1df39ed49bc7d4608ac0ab9994b0535c0b32041253ad3bbd2a48ec4d5d713207'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '0732715d4dec482abd3bad531204320b5c53b09499abc08a60d4c79bd49ef31d'; //LittleEndian - vm script use

//BadgerContract V14
//export const AddressBadgerStake = 'bitcoincash:pd5xm3sx22zekgkwj44l5u0qv43cuhv0rymzfvs9tlv4eug4ak307eft9dvck';
//export const AddressTokensBadgerStake = 'bitcoincash:rd5xm3sx22zekgkwj44l5u0qv43cuhv0rymzfvs9tlv4eug4ak307t6hy5dpa';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '78d2eb5ed332ec29ff0c9b310879c50a857ef1855bafcccd382bbec75bafab2f'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '2fabaf5bc7be2b38cdccaf5b85f17e850ac57908319b0cff29ec32d35eebd278'; //LittleEndian - vm script use

//BadgerContract V15 - withdraw scrap checkSig, uses input1.lockingBytecode
//export const AddressBadgerStake = 'bitcoincash:pwmwr079fdefysy6guxnz6f66ernraef80m6r0q8y6kxarh9m5ahuu958fe99';
//export const AddressTokensBadgerStake = 'bitcoincash:rwmwr079fdefysy6guxnz6f66ernraef80m6r0q8y6kxarh9m5ahuwkgxscuw';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '50b1d358fb8cc32545b826aeae6f7f2366ad4906c31285712bece240bb4dd7c9'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'c9d74dbb40e2ec2b718512c30649ad66237f6faeae26b84525c38cfb58d3b150'; //LittleEndian - vm script use

//BadgerContract V16 - withdraw scrap checkSig, uses input1.lockingBytecode
//export const AddressBadgerStake = 'bitcoincash:pvntan876xxcnh3kw70kzlwerzpsazeldta90usj6qe7dcc4wkgl75kzstep0';
//export const AddressTokensBadgerStake = 'bitcoincash:rvntan876xxcnh3kw70kzlwerzpsazeldta90usj6qe7dcc4wkgl7x973jccy';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '7b407c046380573da0fcda15584611810720b9289382a280d65a5964099ac6bf'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'bfc69a0964595ad680a2829328b920078111465815dafca03d578063047c407b'; //LittleEndian - vm script use

//BadgerContract V17 - 'real' contract with 1:1:1
//export const AddressBadgerStake = 'bitcoincash:p0yzmq59hsawrejas2zxmfcsn3wjjlr6ax5sgjyct6pxd8xlq02fs0kumjew9';
//export const AddressTokensBadgerStake = 'bitcoincash:r0yzmq59hsawrejas2zxmfcsn3wjjlr6ax5sgjyct6pxd8xlq02fsa9q6tchw';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = '4330c0ab77af2eea1eec509ff5d8577472c042340fb19574ca5730174a07c6b6'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'b6c6074a173057ca7495b10f3442c0727457d8f59f50ec1eea2eaf77abc03043'; //LittleEndian - vm script use

//BadgerContract V17Test - not divided by 100m
//export const AddressBadgerStake = 'bitcoincash:pdkr8qvk83r7v80ytkvhy9m9h90ldk6fh2ug3chgymy99ckywlzjz4llq86ug';
//export const AddressTokensBadgerStake = 'bitcoincash:rdkr8qvk83r7v80ytkvhy9m9h90ldk6fh2ug3chgymy99ckywlzjz8vrp7m9r';
//export const AddressBadgerMarket = '';//placeholder
//export const AddressTokensBadgerMarket = '';//placeholder
//export const MasterCategoryID = 'cdffc3c4386d59717aabda86f97d11fc35ca61ffc6612f480bc0963187e9184b'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '4b18e9873196c00b482f61c6ff61ca35fc117df986daab7a71596d38c4c3ffcd'; //LittleEndian - vm script use

//BadgerContract V18
//export const AddressBadgerStake = 'bitcoincash:pvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uv3vs8ch7t';
//export const AddressTokensBadgerStake = 'bitcoincash:rvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uvrlvxpk8q';
//export const AddressBadgerMarket = 'bitcoincash:pdvgsqpadryfdfc8msz8gaahv6rq5dfn2qv3c3la7catd9cujzh02693q6vfq';//placeholder
//export const AddressTokensBadgerMarket = 'bitcoincash:rdvgsqpadryfdfc8msz8gaahv6rq5dfn2qv3c3la7catd9cujzh02gkdprdst';//placeholder
//export const MasterCategoryID = '62c64414e4d8007d756c179932d5d2097481ffb1aa021c42f7e050fb05eafcb1'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'b1fcea05fb50e0f7421c02aab1ff817409d2d53299176c757d00d8e41444c662'; //LittleEndian - vm script use

//BadgerContract V19 - market testing
//export const AddressBadgerStake = 'bitcoincash:pvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uv3vs8ch7t';
//export const AddressTokensBadgerStake = 'bitcoincash:rvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uvrlvxpk8q';
//export const AddressBadgerMarket = 'bitcoincash:pdvgsqpadryfdfc8msz8gaahv6rq5dfn2qv3c3la7catd9cujzh02693q6vfq';//placeholder
//export const AddressTokensBadgerMarket = 'bitcoincash:rdvgsqpadryfdfc8msz8gaahv6rq5dfn2qv3c3la7catd9cujzh02gkdprdst';//placeholder
//export const MasterCategoryID = 'b626b002e57008d417694073d505f819f89eba7d61c51c627fce457b1ae8e1fa'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'fae1e81a7b45ce7f621cc5617dba9ef819f805d573406917d40870e502b026b6'; //LittleEndian - vm script use

//BadgerContract V20 - split market into manager/market
//export const AddressBadgerStake = 'bitcoincash:pvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uv3vs8ch7t';
//export const AddressTokensBadgerStake = 'bitcoincash:rvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uvrlvxpk8q';
//export const AddressBadgerMarket = 'bitcoincash:pdjsz9pj75nur4a9n84e7ql5nwlvv5jrragkmp3ma8eqxrr0k6x8jemjygxm3';
//export const AddressTokensBadgerMarket = 'bitcoincash:rdjsz9pj75nur4a9n84e7ql5nwlvv5jrragkmp3ma8eqxrr0k6x8jtgw938z6';
//export const AddressBadgerManager = 'bitcoincash:pddwg0saf9qdfxxsh0cpafchfz4ddpxtwhmd4n2zdv827q47805c60my95xep';
//export const AddressTokensBadgerManager = 'bitcoincash:rddwg0saf9qdfxxsh0cpafchfz4ddpxtwhmd4n2zdv827q47805c6agcyd8q2';
//export const MasterCategoryID = 'b626b002e57008d417694073d505f819f89eba7d61c51c627fce457b1ae8e1fa'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'fae1e81a7b45ce7f621cc5617dba9ef819f805d573406917d40870e502b026b6'; //LittleEndian - vm script use

//BadgerContract V21 - split market into manager/market, added 0.01% listing fee
//export const AddressBadgerStake = 'bitcoincash:pvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uv3vs8ch7t';
//export const AddressTokensBadgerStake = 'bitcoincash:rvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uvrlvxpk8q';
//export const AddressBadgerMarket = 'bitcoincash:p09n2n9s5nxnsmj9std7r0denpt9rsnuxqqalsjpsu5lf3yu24em2zwve4uuq';
//export const AddressTokensBadgerMarket = 'bitcoincash:r09n2n9s5nxnsmj9std7r0denpt9rsnuxqqalsjpsu5lf3yu24em2sascva9t';   //address hash: cb354cb0a4cd386e4582dbe1bdb9985651c27c3001dfc2418729f4c49c5573b5
//export const AddressBadgerManager = 'bitcoincash:pvk7w48ge6x080rjl238307dwkyu7qgw8utnvw9fx9wzc3vvf94qyrxqwqyy0';
//export const AddressTokensBadgerManager = 'bitcoincash:rvk7w48ge6x080rjl238307dwkyu7qgw8utnvw9fx9wzc3vvf94qy34u0e9ay';
//export const MasterCategoryID = 'b626b002e57008d417694073d505f819f89eba7d61c51c627fce457b1ae8e1fa'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'fae1e81a7b45ce7f621cc5617dba9ef819f805d573406917d40870e502b026b6'; //LittleEndian - vm script use

//BadgerContract V22 - split market into manager/market, added 0.01% listing fee, removed list()'s change output value requirement
//export const AddressBadgerStake = 'bitcoincash:pvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uv3vs8ch7t';
//export const AddressTokensBadgerStake = 'bitcoincash:rvdx2fvvlvqauxj2n3yepr3jnanugpem75sgpa5d722q3r3dpm9uvrlvxpk8q';
//export const AddressBadgerMarket = 'bitcoincash:p09n2n9s5nxnsmj9std7r0denpt9rsnuxqqalsjpsu5lf3yu24em2zwve4uuq';
//export const AddressTokensBadgerMarket = 'bitcoincash:r09n2n9s5nxnsmj9std7r0denpt9rsnuxqqalsjpsu5lf3yu24em2sascva9t';   //address hash: cb354cb0a4cd386e4582dbe1bdb9985651c27c3001dfc2418729f4c49c5573b5
//export const AddressBadgerManager = 'bitcoincash:pvh30vqmlsv802kvrx6fp5ajqj2fywsylrw89nd9hwgpjhmf4qduu7zk66yhq';
//export const AddressTokensBadgerManager = 'bitcoincash:rvh30vqmlsv802kvrx6fp5ajqj2fywsylrw89nd9hwgpjhmf4qduuv32mr9wt';
//export const MasterCategoryID = 'b626b002e57008d417694073d505f819f89eba7d61c51c627fce457b1ae8e1fa'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'fae1e81a7b45ce7f621cc5617dba9ef819f805d573406917d40870e502b026b6'; //LittleEndian - vm script use

//BadgerContract V23 - testing badgercoin staking, doesnt include / 100000000 for coins
//export const AddressBadgerStake = 'bitcoincash:p03t78frxf795clthrv9qg0vhz0jxt89p57n40ynemn4avamhmgakzn43zzpu';  
//export const AddressTokensBadgerStake = 'bitcoincash:r03t78frxf795clthrv9qg0vhz0jxt89p57n40ynemn4avamhmgaksqfsmrch';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerManager = '';
//export const AddressTokensBadgerManager = '';
//export const MasterCategoryID = 'eedecdd6539a6cd6ac55848592f41d3804f40774c2bf54ad9672100ea24a6e39'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '396e4aa20e107296ad54bfc27407f404381df492858455acd66c9a53d6cddeee'; //LittleEndian - vm script use

//BadgerContract V24 - doesnt include / 100000000 for coins, includes phantomNFT for unlock (broken unlock fee, oops)
//export const AddressBadgerStake = 'bitcoincash:pvsacsjzzqgwxj8y8wy924lxf9lr85udu0mhsh4s03fxmx7zaufjs3qm690cx';  
//export const AddressTokensBadgerStake = 'bitcoincash:rvsacsjzzqgwxj8y8wy924lxf9lr85udu0mhsh4s03fxmx7zaufjsrn8muwpd';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerManager = '';
//export const AddressTokensBadgerManager = '';
//export const MasterCategoryID = '22e925dffe021500e7fbd4bf30c71b740634540fb01d49ed6b334e125368ccdf'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'dfcc6853124e336bed491db00f543406741bc730bfd4fbe7001502fedf25e922'; //LittleEndian - vm script use

//BadgerContract V25 - doesnt include / 100000000 for coins, includes phantomNFT for unlock (fixed unlock fee)
//export const AddressBadgerStake = 'bitcoincash:p04pr8kztcvlq7h9mf046d73qhe5ur7jal9huyxfr4j7n6qgtlndgjar7gyrd';  
//export const AddressTokensBadgerStake = 'bitcoincash:r04pr8kztcvlq7h9mf046d73qhe5ur7jal9huyxfr4j7n6qgtlndgqwll396x';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerManager = '';
//export const AddressTokensBadgerManager = '';
//export const MasterCategoryID = '61b83919231b6bf81e56c8254ac776f93cd5385c2c13022eeeb5f9150e25ceae'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'aece250e15f9b5ee2e02132c5c38d53cf976c74a25c8561ef86b1b231939b861'; //LittleEndian - vm script use

//BadgerContract V26 - doesnt include / 100000000 for coins, includes honeyNFT for unlock (fixed unlock fee)
//export const AddressBadgerStake = 'bitcoincash:pw36putpcz4ngql7hacyhfgem0q2gjj2lwqttjl5psjlr6raemg95exk6zpmk';  
//export const AddressTokensBadgerStake = 'bitcoincash:rw36putpcz4ngql7hacyhfgem0q2gjj2lwqttjl5psjlr6raemg95t42mmqza';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerManager = '';
//export const AddressTokensBadgerManager = '';
//export const MasterCategoryID = '24a2ce12292dabef2d5e1e5912a9281872e62f4e1ccc74f32ce025bcdb1c897d'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '7d891cdbbc25e02cf374cc1c4e2fe6721828a912591e5e2defab2d2912cea224'; //LittleEndian - vm script use

//BadgerContract V27 - doesnt include / 100000000 for coins, includes honeyNFT for unlock (fixed unlock fee), market active
//export const AddressBadgerStake = 'bitcoincash:pwhg4z5fvy00c4efegzqmtumnc7x2lj580ztgrwq9pfjagfey6pjq84y0h7d8';  
//export const AddressTokensBadgerStake = 'bitcoincash:rwhg4z5fvy00c4efegzqmtumnc7x2lj580ztgrwq9pfjagfey6pjq4xcwwl5v';
//export const AddressBadgerMarket = 'bitcoincash:pvumpluy2tfkdfz4hqwhrxrlmn5az4zuchrl4m7xx23gguxtk9n5ze8wghccp';
//export const AddressTokensBadgerMarket = 'bitcoincash:rvumpluy2tfkdfz4hqwhrxrlmn5az4zuchrl4m7xx23gguxtk9n5zt5jfwep2';   //address hash: 39b0ff8452d366a455b81d71987fdce9d1545cc5c7faefc632a28470cbb16741
//export const AddressBadgerMarketBouncer = 'bitcoincash:pw6227snfftdcv09uts68ywydd5zmge4nzmee3rlr30e52gxrcghu3rk47gql';
//export const AddressTokensBadgerMarketBouncer = 'bitcoincash:rw6227snfftdcv09uts68ywydd5zmge4nzmee3rlr30e52gxrcghurs258fe5';
//export const AddressBadgerMint = 'bitcoincash:pwydg6y7mk0w7g3amam2axfddlrh89y0fvevymqyxjtvs0sl8n6hwqzn9nw65';
//export const AddressTokensBadgerMint = 'bitcoincash:rwydg6y7mk0w7g3amam2axfddlrh89y0fvevymqyxjtvs0sl8n6hwj30y20rl';
//export const MasterCategoryID = '4ef6bc807724d5b0b6bdbfe2f4a96d404bdf5a5d1ca86bcba6f19f2b9fadb76c'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '6cb7ad9f2b9ff1a6cb6ba81c5d5adf4b406da9f4e2bfbdb6b0d5247780bcf64e'; //LittleEndian - vm script use

//BadgerContract V28 - doesnt include / 100000000 for coins, includes honeyNFT for unlock (fixed unlock fee), market active
//export const AddressBadgerStake = 'bitcoincash:pv68x8ewx428zkcyesyga6jrgu3faxmfnfp89vvaea00nj8f56fjj90p5qrnn';  
//export const AddressTokensBadgerStake = 'bitcoincash:rv68x8ewx428zkcyesyga6jrgu3faxmfnfp89vvaea00nj8f56fjjhua4ez2c';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerMarketBouncer = '';
//export const AddressTokensBadgerMarketBouncer = '';
//export const AddressBadgerMint = 'bitcoincash:p0u0twg98cquaar6ts5ft6ytee75vcthlste9r3zqggh0fdy06rwx9zqzps52'; //address hash: f8f5b9053e01cef47a5c2895e88bce7d466177fc17928e22021177a5a47e86e3
//export const AddressTokensBadgerMint = 'bitcoincash:r0u0twg98cquaar6ts5ft6ytee75vcthlste9r3zqggh0fdy06rwxh3urc3dp';
//export const MasterCategoryID = 'f9c37d69c2a83235257faf12d3cffd2f2263f7b9277c549af0f8a196e1fca970'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '70a9fce196a1f8f09a547c27b9f763222ffdcfd312af7f253532a8c2697dc3f9'; //LittleEndian - vm script use

//BadgerContract V29 - PRODUCTION! includes / 100000000 for coins, includes honeyNFT for unlock (fixed unlock fee), market active
//export const AddressBadgerStake = 'bitcoincash:pvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgl3tq982v';  
//export const AddressTokensBadgerStake = 'bitcoincash:rvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgdzhpuxn8';
//export const AddressBadgerMarket = '';
//export const AddressTokensBadgerMarket = '';   //address hash: 
//export const AddressBadgerMarketBouncer = '';
//export const AddressTokensBadgerMarketBouncer = '';
//export const AddressBadgerMint = 'bitcoincash:pv0lu94x0haccdezhwmfu624fmyuzsuujcujteugsu8cct98mrtvjsncczf3w'; //address hash: 1ffe16a67dfb8c3722bbb69e69554ec9c1439c963925e788870f8c2ca7d8d6c9
//export const AddressTokensBadgerMint = 'bitcoincash:rv0lu94x0haccdezhwmfu624fmyuzsuujcujteugsu8cct98mrtvjzqyemgg9'; //address hash: 1ffe16a67dfb8c3722bbb69e69554ec9c1439c963925e788870f8c2ca7d8d6c9
//export const MasterCategoryID = '242f6ecedb404c743477e35b09733a56cacae34f3109d5cee1cbc1d5630affd7'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'd7ff0a63d5c1cbe1ced509314fe3caca563a73095be37734744c40dbce6e2f24'; //LittleEndian - vm script use

//export const AddressBadgerMint = 'bchtest:pdlf5l09mzf67z8t3zhgt0ggamsdprw5jnv485dnp4aqg05837792g0wg44qy'; //CHIPNET address hash: 7e9a7de5d893af08eb88ae85bd08eee0d08dd494d953d1b30d7a043e878fbc55
//export const AddressTokensBadgerMint = 'bchtest:rdlf5l09mzf67z8t3zhgt0ggamsdprw5jnv485dnp4aqg058377926ujfv5e0'; //CHIPNET address hash: 7e9a7de5d893af08eb88ae85bd08eee0d08dd494d953d1b30d7a043e878fbc55
//export const MasterCategoryID = 'ff7f9443b121bd2e8d5d195d26af8c1ccbf4fd8274f63826966a2691203c57ef'; //CHIPNET BigEndian - frontend use
//export const MasterCategoryIDLE = 'ef573c2091266a962638f67482fdf4cb1c8caf265d195d8d2ebd21b143947fff'; //CHIPNET LittleEndian - vm script use

//BadgerContract V30 - fixed minting contract badgercoin needed for discount amount
export const AddressBadgerStake = 'bitcoincash:pvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgl3tq982v';  
export const AddressTokensBadgerStake = 'bitcoincash:rvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgdzhpuxn8';
export const AddressBadgerMarket = '';
export const AddressTokensBadgerMarket = '';   //address hash: 
export const AddressBadgerMarketBouncer = '';
export const AddressTokensBadgerMarketBouncer = '';
export const AddressBadgerMint = 'bitcoincash:p0e0aydsrzxryetm4u7y62s5gy70gmur7fn4ljtm0wk5l6yc3nghzd72j7pt7'; //address hash: f2fe91b0188c32657baf3c4d2a14413cf46f83f2675fc97b7bad4fe8988cd171
export const AddressTokensBadgerMint = 'bitcoincash:r0e0aydsrzxryetm4u7y62s5gy70gmur7fn4ljtm0wk5l6yc3nghzldkn8qj4'; //address hash: f2fe91b0188c32657baf3c4d2a14413cf46f83f2675fc97b7bad4fe8988cd171
export const MasterCategoryID = '242f6ecedb404c743477e35b09733a56cacae34f3109d5cee1cbc1d5630affd7'; //BigEndian - frontend use
export const MasterCategoryIDLE = 'd7ff0a63d5c1cbe1ced509314fe3caca563a73095be37734744c40dbce6e2f24'; //LittleEndian - vm script use


