import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Utxo } from 'cashscript';
import BadgerLogo100 from '../../assets/img/badgercoin100.png';
import BCHLogo from '../../assets/img/bch.png';
import Spinner from '../../assets/img/spinner.gif';

interface LockUtxo extends Utxo {
  startHeight: number;
  lockedBlocks: number;
  timeLeft: string;
}

interface ScrollingUTXOsProps {
  otherStakeUTXOs: LockUtxo[]; // Define the prop's expected type
  unlockPending: boolean;
  handleUnlock: (lockUTXO: LockUtxo) => Promise<void>; // or just () => void if it does not need to be async
}

//const ScrollingUTXOs: React.FC<ScrollingUTXOsProps> = ({ otherStakeUTXOs, unlockPending, handleUnlock }) => {
const ScrollingUTXOs = React.memo<ScrollingUTXOsProps>(({ otherStakeUTXOs, unlockPending, handleUnlock }) => {
  const [elements, setElements] = useState([...otherStakeUTXOs]);
{/*
  useEffect(() => {
    console.log("ScrollingUTXOs rendered");
    console.log(otherStakeUTXOs.length);
  });
*/}
  useEffect(() => {
    const interval = setInterval(() => {
      setElements((prevElements) => {
        const nextElements = [...prevElements, prevElements[0]]; // Move the first item to the end
        return nextElements.slice(1); // Remove the first item
      });
    }, 4000); // Interval should match the animation duration

    return () => clearInterval(interval);
  }, [elements.length]);

////////////////////////////////////////
  interface ScrollerLockedUTXOProps {
    utxo: LockUtxo;
    children?: React.ReactNode;
  }
  // Create a new React component that renders StyledScrollerLockedUTXO
const ScrollerLockedUTXO = React.memo(({ utxo, children }: ScrollerLockedUTXOProps) => {
  return (
    <StyledScrollerLockedUTXO>
      {children}
    </StyledScrollerLockedUTXO>
  );
}, (prevProps, nextProps) => {
  // This function is a comparison function that tells React.memo when to re-render
  // Here, we assume that `utxo` object has a `txid` field that is unique and stable
  return prevProps.utxo.txid === nextProps.utxo.txid;
});

  return (
    <StyledScrollerUTXORow>
      {elements.map((utxo, index) => (
        <ScrollerLockedUTXO utxo={utxo} key={utxo.txid}>
  {/*      <StyledScrollerLockedUTXO key={utxo.txid}> */}
          <StyledLockUTXOColumn>
            <StyledLockUTXODescription>

              <StyledLockedTXID>
                <a href={"https://explorer.salemkode.com/tx/" + utxo.txid}>{utxo.txid.slice(0, 7) + '...' + utxo.txid.slice(57, 64)}</a>
              </StyledLockedTXID>
              {isNaN(utxo.startHeight + utxo.lockedBlocks) ? 'Pending Block...' : 'Unlocks: ' + (utxo.startHeight + utxo.lockedBlocks)} <br/>

              <StyledLockAmountsRow>
                <StyledPledgeLogo /> {parseFloat((Number(utxo.satoshis) / 100_000_000).toFixed(8))}
              </StyledLockAmountsRow>

              <StyledLockAmountsRow>
                <StyledBadgerLogo /> {parseFloat((utxo.token?.amount!).toString())}
              </StyledLockAmountsRow>
              
            </StyledLockUTXODescription>

            <UnlockButton disabled={utxo.timeLeft != 'Available' && !unlockPending} isDisabled={utxo.timeLeft != 'Available'} onClick={() => handleUnlock(utxo)}>
              {unlockPending ? (
                <StyledSpinner />
              ) : (
                utxo.timeLeft == 'Available' ? 'Unlock' : utxo.timeLeft
              )}
            </UnlockButton> 
          </StyledLockUTXOColumn>
        </ScrollerLockedUTXO>
      ))}
    {/*    </StyledScrollerLockedUTXO> */}

    </StyledScrollerUTXORow>
  );
//};
},  (prevProps, nextProps) => {
  return prevProps.unlockPending === nextProps.unlockPending &&
         prevProps.otherStakeUTXOs === nextProps.otherStakeUTXOs &&
         prevProps.handleUnlock === nextProps.handleUnlock;
});

interface PledgeButtonProps {
  isDisabled?: boolean;
}
const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-106%);
  }
`;

const StyledScrollerUTXORow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  border: 0px solid red;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  position: relative; 
`;
const StyledScrollerLockedUTXO = styled.div`
display: inline-block;
animation: ${scroll} 4s linear infinite;
&:last-child {
  margin-right: 100%; /* Adjust this value based on your item widths */
}
  background-color: #113c2b;
  border: 2px solid #fff;
  margin: 5px;
  color: white;
  height: 148px;
  width: 150px;
  min-width: 150px;
  display: flex;
flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; 
  border-radius: 0px 0px 20px 20px;
  @media (max-width: 620px) {
    width: 150px;
  }
`;
const StyledLockUTXOColumn = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  z-index: 2;
  border: 0px solid pink;
  flex-wrap: wrap;
`;
const StyledLockUTXODescription = styled.div`
  position: relative;
  width: 100%;
  height: 103px;
  border: 0px solid blue;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
const StyledLockedTXID = styled.div`
  z-index: 2;
  background-color: #4CAF50;
  color: #fff;
  display: flex;
  height: 20px;
  gap: 10px;
  border-bottom: 2px solid #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    background-color: #fff;
    a {
      color: #4CAF50;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
  }
`;
const StyledLockAmountsRow = styled.div`
  z-index: 2;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  height: 30px;
  gap: 10px;
  border: 0px solid pink;
  justify-content: left;
  align-items: center;
  text-align: center;
  margin-top: 5px;
`;
const StyledPledgeLogo = styled.div`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: flex;
  border: 0px solid red;
`;
const StyledBadgerLogo = styled.div`
  position: relative;
  background-image: url(${BadgerLogo100});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: flex;
  border: 0px solid red;
`;
const UnlockButton = styled.button<PledgeButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isDisabled ? '#666' : '#4caf50')};
  color: ${(props) => (props.isDisabled ? '#ccc' : '#fff')};
  border: 2px solid ${(props) => (props.isDisabled ? '#222' : '#4caf50')};
  border-radius: 20px;
  font-size: 18px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  z-index: 10;
  width: 90%;
  min-width: 100px;
  height: 30px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#666' : '#6ac76d;')};
  }

`
const StyledSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  display: flex;
  border: 0px solid red;
`;

export default ScrollingUTXOs;