import React, { FC, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './Pages/Home';
import Market from './Pages/Market';
import Mint from './Pages/Mint';
import FAQ from './Pages/FAQ';
import Wallet from './Pages/Wallet';
import { BlockchainProvider } from './components/Context/BlockchainContext';

const App: FC = () =>  {
  return (
    <BlockchainProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Mint" element={<Mint />} />
            <Route path="/Market" element={<Market />} />
            <Route path="/FAQ" element={<FAQ />} /> 
            <Route path="/Wallet" element={<Wallet />} />
          </Routes>
        </div>
       {/* <Footer /> */}
      </Router>
    </BlockchainProvider>
  );
}
  
  export default App;