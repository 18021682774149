import { Contract, Utxo, ElectrumNetworkProvider, SignatureTemplate } from 'cashscript';
import { hexToBin, cashAddressToLockingBytecode, OpcodesBCH, decodeTransaction, createVirtualMachineBCH, summarizeDebugTrace, stringifyDebugTraceSummary, encodeCashAddress } from '@bitauth/libauth';
import { AddressBadgerMarket, AddressTokensBadgerMarket, MasterCategoryID } from '../constants/values'

interface MarketListBadgerParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  usersAddress: string;
  contractBadgerManager: Contract | undefined;
  toTokenAddress: (address: any) => string;
  signTransaction: (options: any) => Promise<unknown>;
  badger: Utxo;
  price: bigint;
  pubkeyhash: string;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function marketListBadger({ electrumServer, usersAddress, contractBadgerManager, toTokenAddress, signTransaction, badger, price, pubkeyhash }: MarketListBadgerParams) {
console.log(pubkeyhash);

    //Creating lockingBytecode for contract address
    const contractLockingBytecodeResult = cashAddressToLockingBytecode(contractBadgerManager?.tokenAddress!);
    if (typeof contractLockingBytecodeResult === 'string') {
      throw new Error(`Failed to convert CashAddress to locking bytecode: ${contractLockingBytecodeResult}`);
    }
    //Creating lockingBytecode for destination address
    const lockingBytecodeResult = cashAddressToLockingBytecode(usersAddress);
    if (typeof lockingBytecodeResult === 'string') {
      throw new Error(`Failed to convert CashAddress to locking bytecode: ${lockingBytecodeResult}`);
    }


    const LEtoBE = (hex: string) => { 
      const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
      return bigEndianHex;
    };

    const toLittleEndian = (hex: string, byteLength: number) => {
      const paddedHex = hex.padStart(byteLength * 2, '0');          // Pad the string to the byteLength with zeros
      const hexArray = paddedHex.match(/.{2}/g)?.reverse() ?? [];   // Match every two characters (1 byte), reverse the array if not null, or default to empty array
      
      return hexArray.join(''); // Join the array back into a string
    };

    function hexLEToBigInt(hexLE: string): bigint {
      const hexBE = hexLE.match(/.{2}/g)?.reverse().join('') ?? '0';  // Convert the hex string from little-endian to big-endian
      return BigInt('0x' + hexBE);  // Convert the big-endian hex string directly to a BigInt
    }

    function hexToUint8Array(hexString: any) {
      const bytes = new Uint8Array(hexString.length / 2);
      for (let i = 0; i < bytes.length; i++) {
          bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
      }
      return bytes;
    }

    const hexLEToDecimal = (hex: string): number => {
      // Convert hex to big-endian and then to decimal
      const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
      return parseInt(bigEndianHex, 16);
    };

  if (electrumServer && contractBadgerManager) { // require electrumServer has been setup, connected to blockchain, and contractBadger is compiled

    //input0 = badgerMaster | input1 = badgerNFT | input2 = feeBCH
    //output0 = badgerMaster | output1 = badgerNFT | output2 = bchChange

    // Get NFT's ID
    const badgerID = hexLEToDecimal(badger.token?.nft?.commitment.substring(74, 80) ?? "0");

    //Get all utxos on contract      
    const badgerManagerUTXOs = await contractBadgerManager.getUtxos(); 
    console.log('badgerContract utxos:');
    console.log(badgerManagerUTXOs);

    //Find masterNFT
    const masterBadgerUTXO: Utxo = badgerManagerUTXOs.find(
      utxo => utxo.token?.category === MasterCategoryID
      && utxo.token?.nft?.capability == 'minting' //is the masterBadger
    )!; //'!' assumes will always be found
    console.log('selected masterBadger UTXO: ');
    console.log(masterBadgerUTXO);

    // Get users UTXO for transaction          
    const userUtxos = await electrumServer.getUtxos(usersAddress);
    console.log('get user UTXOs: ');
    console.log(userUtxos);

    //filter to only those that have (listPrice + 1000sats fee) and no tokens/NFTs on them
    const userValidUtxos = userUtxos.filter(
      val => !val.token && val.satoshis >= price + 1000n,
    )
    console.log('valid user UTXOs: ');
    console.log(userValidUtxos);

    //select first valid found utxo
    const userUTXO = userValidUtxos[0];
    if (!userUTXO) {
      console.log('No utxos with listPrice+fee found. You may need to consolidate your utxos.');
      return;
    }
    console.log('selected user UTXO: ');
    console.log(userUTXO);

  //##  Build transaction        
  //##########################################

    //build new commitment
    const restCommitment = badger.token?.nft?.commitment.substring(50, 80);

    const priceHex = price.toString(16);  //Convert price (satoshis) to a hex
    const pricePadded = priceHex.padStart(10, '0');  //Pad the hexadecimal string up to 5bytes if needed
    const priceHexLE = toLittleEndian(pricePadded, 5);

    const newCommitment = pubkeyhash + priceHexLE + restCommitment;

    //badgerNFT output details
    const badgerDetails: TokenDetails = {          
      amount: badger.token?.amount!,  
      category: badger.token?.category!,  
      nft: {
        capability: 'mutable', 
        commitment: newCommitment   
      }
    };
    //masterBadger output details
    const masterDetails: TokenDetails = {          
      amount: masterBadgerUTXO.token?.amount!,  
      category: masterBadgerUTXO.token?.category!,  
      nft: {
        capability: masterBadgerUTXO.token?.nft?.capability!, 
        commitment: masterBadgerUTXO.token?.nft?.commitment!   
      }
    };

    //const privKey = '';     //zapit
    //const userSig = new SignatureTemplate(privKey);

    const userSig = new SignatureTemplate(Uint8Array.from(Array(32)));           // empty signature as placeholder for building process. walletconnect will replace sig later
    const fee = price / 1000n;
    const test = '0x' + pubkeyhash;

    let transaction: any;
    try {
      transaction = contractBadgerManager?.functions.list(price, pubkeyhash)        
        .from(masterBadgerUTXO)              
        .fromP2PKH(badger, userSig)                                                 // badger utxo
        .fromP2PKH(userUTXO, userSig)                                               // user fee utxo
        .to(contractBadgerManager.tokenAddress, masterBadgerUTXO.satoshis + fee, masterDetails) // send badgerUTXO to buyer
        .to(AddressTokensBadgerMarket, badger.satoshis, badgerDetails)              // send buyers BCH to seller (minus tx fee)
        .withoutChange()                                                            // disable automatic change output back to user (see next)
        .withoutTokenChange()

      const changeAmount = (userUTXO.satoshis - (fee + 1200n));               // how many sats remain on users utxo after removing listing fee + 1200sats miner fee
      if (changeAmount >= 1000n) {
        transaction.to(usersAddress, changeAmount);        // if change is over dust limit then send it back to user. if not, miners will keep it
      }
      console.log(transaction);

      //const built = await transaction.build();
      //console.log(built);

      const txid = await transaction.send();
      console.log(txid);

    } catch (error) {
      if (error instanceof Error) {
        console.error('Transaction failed:', error);
      } else {
        console.error('Transaction failed:', error);
      }
    }

    try {                                                                         // build the transaction we created
      const rawTransactionHex = await transaction.build();                                  
      const decodedTransaction = decodeTransaction(hexToBin(rawTransactionHex));            
      if (typeof decodedTransaction === "string") {
        alert("No suitable utxos found for minting. Try to consolidate your utxos!");
        throw ("No suitable utxos found for minting. Try to consolidate your utxos!");
      }
      decodedTransaction.inputs[1].unlockingBytecode = Uint8Array.from([]);     // set the to-be-walletconnect-signed input to empty again
      decodedTransaction.inputs[2].unlockingBytecode = Uint8Array.from([]);     // set the to-be-walletconnect-signed input to empty again
      console.log('decodedTransaction: ');
      console.log(decodedTransaction);

      // construct new transaction object for SourceOutputs, for stringify & not to mutate current network provider 
      const listSourceOutputs = [{
        ...decodedTransaction.inputs[0],
        lockingBytecode: (cashAddressToLockingBytecode(contractBadgerManager.address) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(masterBadgerUTXO.satoshis),
        contract: {
          abiFunction: (transaction as any).abiFunction,        // 'as any' type assertion to bypass typescript checking
          redeemScript: (contractBadgerManager as any).redeemScript,
          artifact: (contractBadgerManager as any).artifact,           // 'as any' type assertion to bypass typescript checking
        }
      }, {
        ...decodedTransaction.inputs[1],
        lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(badger.satoshis),
      }, {
        ...decodedTransaction.inputs[2],
        lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(userUTXO.satoshis),
      }];

      //create transaction object to give for signing
      const wcTransactionObj = {
        transaction: decodedTransaction,
        sourceOutputs: listSourceOutputs,
        broadcast: false,
        userPrompt: "List Badger #" + badgerID + " for " + price + "sats"
      };
      console.log(wcTransactionObj);

      //pass object to walletconnect for user to sign
      const signResult: any = await signTransaction(wcTransactionObj);


/////// TESTING DEBUGGING ///////////////////////////////////////////////////////////////////////////// 
  const signedTransactionHex = signResult.signedTransaction;
  //const signedTransactionHex = rawTransactionHex;
  if (typeof signedTransactionHex !== 'string' || signedTransactionHex.length === 0) {
      throw new Error('Signed transaction hex is not valid.');
  }

  let decodedTransaction2: any;
  try {
      decodedTransaction2 = decodeTransaction(hexToBin(signedTransactionHex));
      console.log('decoded walletconnect return: ');
      console.log(decodedTransaction2);
  } catch (error) {
      console.error('Error decoding transaction:', error);
  }

  const virtualMachine = createVirtualMachineBCH();
  const sourceOutputs = [{
    lockingBytecode: contractLockingBytecodeResult.bytecode,
    valueSatoshis: masterBadgerUTXO.satoshis,
  },
  {
    lockingBytecode: lockingBytecodeResult.bytecode,
    valueSatoshis: badger.satoshis,
  },
  {
    lockingBytecode: lockingBytecodeResult.bytecode,
    valueSatoshis: userUTXO.satoshis,
  }];

  // @ts-ignore
  const authenticationPrograms = decodedTransaction2.inputs.map((input, index) => {
      // Replace this with the actual previous locking bytecode for each input
      return {
        inputIndex: index,
        sourceOutputs: sourceOutputs,
        transaction: decodedTransaction2,
      };
    });
    console.log('program created');
    console.log(authenticationPrograms);

  // @ts-ignore
  const debugResults = authenticationPrograms.map((program) => virtualMachine.debug(program));
  console.log('debug results gotten');

  const summarizedResults = debugResults.map((trace: any) => summarizeDebugTrace(trace));
  console.log(summarizedResults);

  const stringifiedSummaries = summarizedResults.map((summary: any) =>
    stringifyDebugTraceSummary(summary, {
      opcodes: OpcodesBCH, // Replace 'OpcodesBCH' with the actual opcodes enum you have
      padInstruction: 10 // Adjust the padding as needed for alignment
    })
  );
  console.log(stringifiedSummaries.join('\n'));
  /*
  debugResults.forEach((result: any, index: number) => {
      if (!result) {
          console.error(`No result for debugging input ${index}`);
          return;
      }

      console.log(`Debugging input ${index}:`);
      result.forEach((state: any, step: any) => {
        console.log(`Step ${step}:`, state);
      });
    });
  */
/////// END TESTING DEBUGGING //////////////////////////////////////////////////////////////////////////



      return signResult;

    } catch (error) {
      console.log('tx build failed: ' + error);
    }
  }
}
  
export default marketListBadger;