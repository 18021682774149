import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
//import HelpMeLogo from '../../assets/img/helpmelogo.png';
import HelpMeLogo from '../../assets/img/badgercoin.png';
//walletconnect2
import SignClient from '@walletconnect/sign-client'
import { WalletConnectModal } from '@walletconnect/modal';
import TableNFT from '../../components/TableNFT';
import { ElectrumNetworkProvider, Contract, SignatureTemplate, Utxo, TokenDetails, TransactionBuilder, Network, Transaction, Unlocker } from 'cashscript';
import { ElectrumCluster, ElectrumTransport } from 'cashscript/node_modules/electrum-cash';
import { useBlockchainContext } from '../Context/BlockchainContext';

interface MyWindow extends Window {
  googleTranslateElementInit: () => void;
}
declare let window: MyWindow;

declare namespace google {
  namespace translate {
    class TranslateElement {
      constructor(options: any, container: string);
    }
  }
}
type NetworkType = "mainnet" | "testnet4" | "chipnet";

function Header() {
    const [currentPage, setCurrentPage] = useState('Home'); // Use state to track the current page if needed
    const { setBlockchainData } = useBlockchainContext(); //app context
    //context variables - not needed in header?
    const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
    //initial context setup variables
    const [session, setSession] = useState<any>(null);
    const [instance, setInstance] = useState<SignClient>();
    const [address, setAddress] = useState('');
    const [server, setServer] = useState<ElectrumNetworkProvider>();
    const [cluster, setCluster] = useState<ElectrumCluster>();
    //
    const [contractsOK, setContractsOK] = useState(false);
    const [contractMasterNFT, setContractMasterNFT] = useState<Contract>();
    const [contractMasterNFT2, setContractMasterNFT2] = useState<Contract>();
    const [contractCashStarter, setContractCashStarter] = useState<Contract>();
    const [contractCashStarterManager, setContractCashStarterManager] = useState<Contract>();
    const [network, setNetwork] = useState<NetworkType>('mainnet');

    // Function to toggle the current page if needed
    const togglePage = (page: string) => {
      setCurrentPage(page);
    };

    function truncateAddress(address: string, startLength: number, endLength: number) {
      if (typeof address === 'string' && address.length > startLength + endLength) {
        return address.substring(0, startLength) + '...' + address.substring(address.length - endLength);
      }
      return address; // Return the original address if it's too short to truncate
    }

//////////////////////////////////////////////////
//////////Create connection to the blockchain
//////////////////////////////////////////////////
  const setupElectrum = async () => {
      if (network == 'chipnet') {
        const newElectrumCluster = new ElectrumCluster('ChipnetCluster', '1.5.1', 1, 1);
        newElectrumCluster.addServer('chipnet.imaginary.cash', ElectrumTransport.WS.Port, ElectrumTransport.WS.Scheme);
        const newElectrumServer = new ElectrumNetworkProvider(network, newElectrumCluster);
        setBlockchainData({ electrumServer: newElectrumServer, electrumCluster: newElectrumCluster, connectedChain: 'bch:bchtest' });
        console.log('electrum connected to: ' + newElectrumCluster.application);

      } else if (network == 'mainnet') {
        const newElectrumCluster = new ElectrumCluster('MainnetCluster', '1.5.1', 1, 1, 0, 3000, 6000);
        newElectrumCluster.addServer('electrum.imaginary.cash', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        //newElectrumCluster.addServer('fulcrum.greyh.at', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        //newElectrumCluster.addServer('fulcrum.jettscythe.xyz', ElectrumTransport.WSS.Port, ElectrumTransport.WSS.Scheme);
        const newElectrumServer = new ElectrumNetworkProvider(network, newElectrumCluster);
        setBlockchainData({ electrumServer: newElectrumServer, electrumCluster: newElectrumCluster, connectedChain: 'bch:bitcoincash' });
        console.log('electrum connected to: ' + newElectrumCluster.application);
      }
  }

////////////////////////////////////////////////// 
////////// Wallet Connect V2
//////////////////////////////////////////////////
  //connection settings
  const signClient = async () => {
    return await SignClient.init({
      projectId: '4ebaaeb173947c0a23a91ca9ae2a4396',
      // optional parameters
      relayUrl: 'wss://relay.walletconnect.com',
      metadata: {
        name: 'Badgers.Cash',
        description: 'Badgers.Cash Dapp',
        url: 'https://badgers.cash',
        icons: ['https://badgers.cash/logo100.png']
      }
    });
  } 

  //actual connect 
  const setupSignClient = async () => {
    console.log('setupSignClient(): starting');
    // Setup walletconnect client
    if (walletConnectInstance == null) {
      const client = await signClient();  //call connection with above settings, wait for instance response
      if (client != null) {

        // Set listeners on client then save client to state
        console.log('setupSignClient(): walletConnectInstance detected, adding listeners...')
        // Attach event listeners to client instance
        client.on('session_event', ( event ) => {
          console.log('session_event');
          console.log(event);
        });
        console.log('session_event added...');

        client.on('session_update', ({ topic, params }) => {
          console.log('session_update');
          console.log(params);
        });
        console.log('session_update added...');

        client.on('session_delete', () => {
          console.log('Deleting session with topic ' + walletConnectSession.topic);
          client.session.delete(
            walletConnectSession.topic,
            {
              code: 6000,
              message: "User Disconnected",
            })
            setBlockchainData({ usersAddress: '' });
        });
        console.log('session_delete added...');

        //setInstance(client);
        //setupSession(client);
        setBlockchainData({ instance: client });

       //check if session exists in localstorage to use
       console.log('setupSession(): checking for existing walletconnect session...')
       const lastKeyIndex = client.session.getAll().length - 1;
       const lastSession = client.session.getAll()[lastKeyIndex];

       if (lastSession) {
         console.log('setupSession(): session found in localstorage: ');
         console.log(lastSession);
         //setSession(lastSession);

         //set users saved address
         const existingSessionAddress = lastSession.namespaces['bch'].accounts[0].slice(4);
         console.log(existingSessionAddress);
         //setAddress(existingSessionAddress);
         setBlockchainData({ session: lastSession, usersAddress: existingSessionAddress });
       }

    } 
  }
}
//////////////////////////////////////////////////
////////// User-triggered blockchain connect request
//////////////////////////////////////////////////
// Setup Session saved from localstorage
const manualSetupSignClient = async () => {
  console.log('manualSetupSignClient(): starting');

  // Setup walletconnect client
  let client: SignClient;
  if (walletConnectInstance == null) {
    client = await signClient();  //call connection with above settings, wait for instance response
    if (client != null) {

      // Set listeners on client then save client to state
      console.log('manualSetupSignClient(): walletConnectInstance detected, adding listeners...')
      // Attach event listeners to client instance
      client.on('session_event', ( event ) => {
        console.log('session_event');
        console.log(event);
      });
      console.log('session_event added...');

      client.on('session_update', ({ topic, params }) => {
        console.log('session_update');
        console.log(params);
      });
      console.log('session_update added...');

      client.on('session_delete', () => {
        console.log('Deleting session with topic ' + walletConnectSession.topic);
        client.session.delete(
          walletConnectSession.topic,
          {
            code: 6000,
            message: "User Disconnected",
          })
      });
      console.log('session_delete added...');

      setBlockchainData({ instance: client });
    } 
  }

  //load saved session or start a new one
  console.log('manualSetupSignClient(): checking for existing walletconnect session...');
  const lastKeyIndex = walletConnectInstance.session.getAll().length - 1;
  const lastSession = walletConnectInstance.session.getAll()[lastKeyIndex];

  if (lastSession) {
    console.log('manualSetupSignClient(): session found in localstorage');
    //setSession(lastSession);
    setBlockchainData({ session: lastSession });

    //set users saved address
    const existingSessionAddress = lastSession.namespaces['bch'].accounts[0].slice(4);
    console.log(existingSessionAddress);
    //setAddress(existingSessionAddress);
    setBlockchainData({ usersAddress: existingSessionAddress });

  //otherwise launch QR to start a new session
  } else {
    console.log('manualSetupSignClient(): no existing walletconnect session found, making a new one...');
    const { uri, approval } = await walletConnectInstance.connect({ requiredNamespaces });
    console.log('manualSetupSignClient(): uri received:');
    console.log(uri);
  
    // Open login window
    await walletConnectModal.openModal({ uri });
  
    // Await session approval from the wallet.
    console.log('manualSetupSignClient(): waiting for approval');
    const session = await approval();
    //setSession(session);
    setBlockchainData({ session: session });
    console.log('manualSetupSignClient(): WC connected! Session:');
    console.log(session);
    const existingSessionAddress = session.namespaces['bch'].accounts[0].slice(4);
    //setAddress(existingSessionAddress);
    setBlockchainData({ usersAddress: existingSessionAddress });

    // Close the QRCode modal in case it was open.
    walletConnectModal.closeModal();
  }
}
//////////////////////////////////////////////////
////////// Initialize Electrum, WalletConnect, userAddress
//////////////////////////////////////////////////
const initBlockchain = async () => {
  try {
    if (!walletConnectInstance) {
      console.log('initBlockchain(): 1. walletconnect NOT detected, do setupSignClient()');
      try {
        await setupSignClient();
      } catch (error) {
        console.error('initBlockchain(): Error setting up wallet connect:', error);
      }
    }

    if (!electrumServer) {
      console.log('initBlockchain(): 3. electrumServer not detected, do setupElectrum()');
      try {
        await setupElectrum();
      } catch (error) {
        console.error('initBlockchain(): Error setting up electrum:', error);
      }
    }
  } catch (error) {
    console.error('initBlockchain(): Error in blockchain initialization:', error);
  }
}

//////////////////////////////////////////////////
////////// Initial Pageload: initialize blockchain stuff
//////////////////////////////////////////////////
  useEffect(() => {
    if (!walletConnectSession) {
      console.log('WalletConnect session not detected, initializing... ');
      initBlockchain();
    }
  }, []);

//////////////////////////////////////////////////
////////// Get users address from WalletConnect
//////////////////////////////////////////////////
async function getUserAddress() {
  console.log('getUserAddress(): started');
  try {
    //const client = await signClient();
    if (walletConnectInstance) {
      console.log('getUserAddress(): walletConnectInstance true');
      const result: any = await walletConnectInstance.request({
        chainId: connectedChain,
        topic: walletConnectSession.topic,
        request: {
          method: "bch_getAddresses",
          params: {},
        },
      });
      return result[0];
    }
  } catch (error) {
    return undefined;
  }
};

//////////////////////////////////////////////////
////////// Prepare Wallet Connect Modal
//////////////////////////////////////////////////
  const walletConnectModal = new WalletConnectModal({
    projectId: '423bfeef86a795e9525d6013df14db7d',
    themeMode: 'dark',
    themeVariables: {
      '--wcm-background-color': '#20c997',
      '--wcm-accent-color': '#20c997',
    },
    explorerExcludedWalletIds: 'ALL',
  });

  const requiredNamespaces = {
    bch: {
      chains: [connectedChain],
      methods: ['bch_getAddresses', 'bch_signTransaction', 'bch_signMessage'],
      events: ['addressesChanged'],
    },
  };  

const handleDisconnectWC = () => {
  console.log('handleDisconnectWC() started');
  try {
    if (walletConnectInstance && walletConnectSession) {
      walletConnectInstance?.disconnect({
        topic: walletConnectSession.topic,
        reason: {
          code: 6000,
          message: "User Disconnected",
        }
      });
/*
      walletConnectInstance?.session.delete(
        walletConnectSession.topic,
        {
          code: 6000,
          message: "User Disconnected",
        });
*/
console.log('attempting to delete item. localStorage length: ');
console.log(localStorage.length);
localStorage.clear();
console.log(localStorage.length);

console.log(localStorage.getItem(walletConnectSession.topic));

      localStorage.removeItem(walletConnectSession.topic);
      console.log('removing usersAddress');
      setBlockchainData({ usersAddress: '' });
      console.log('Wallet Disconnected');
    }

    let lastKeyIndex: number;
    let lastSession: any;

    if (walletConnectInstance) {
      console.log('walletConnectInstance exists')
      lastKeyIndex = walletConnectInstance.session.getAll().length - 1;
      lastSession = walletConnectInstance.session.getAll()[lastKeyIndex];
    } else if (instance) {
      console.log('instance exists')
      lastKeyIndex = instance.session.getAll().length - 1;
      lastSession = instance.session.getAll()[lastKeyIndex];
    }

    if (lastSession) {
      console.log('detected lastSession, deleting...')
      console.log('lastSession: ');
      console.log(lastSession);
      localStorage.removeItem(lastSession);
    }

  } catch (error) {
    console.log('Error disconnecting:', error);
  }
}

//Google Translate widget
useEffect(() => {
  if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.async = true;
    googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(googleTranslateScript);
  }
  window.googleTranslateElementInit = function() {
    new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
  };
}, []);

    return (
      <StyledHeader>

        <StyledGroup>
          <StyledLink to="/">
            <StyledTitle>Badgers.<span className="cash">Cash</span></StyledTitle>
          </StyledLink>

          <StyledMenu>
            <StyledLink to="/" onClick={() => togglePage('Home')}>
              <MenuButton isactive={currentPage === 'Home'}>Home</MenuButton>
            </StyledLink>

            <StyledLink to="/Mint" onClick={() => togglePage('Mint')}>
              <MenuButton disabled={false} isactive={currentPage === 'Mint'}>Mint</MenuButton>
            </StyledLink>

            <StyledLink to="/Market" onClick={() => togglePage('Market')}>
              <MenuButton disabled={false} isactive={currentPage === 'Market'}>Market</MenuButton>
            </StyledLink>

            {usersAddress ? (
                <></>
              ) : (
                <ConnectButton onClick={manualSetupSignClient}>Connect</ConnectButton>
            )}

            <StyledLink to="/FAQ" onClick={() => togglePage('FAQ')}>
              <MenuButton isactive={currentPage === 'FAQ'}>FAQ</MenuButton>
            </StyledLink>

          </StyledMenu>
        </StyledGroup>

        <StyledLogo />

        {usersAddress ? (
          <StyledGroup>
            <StyledDisconnectButton>
              <DisconnectButton onClick={handleDisconnectWC}>Disconnect</DisconnectButton>
            </StyledDisconnectButton>
            <StyledLink to="/Wallet" onClick={() => togglePage('Wallet')}>
              <WalletButton disabled={false} isactive={currentPage === 'Wallet'}>Wallet</WalletButton>
            </StyledLink>
          </StyledGroup>
          ) : (
            <></>
        )}

        <StyledTranslate>
          <div id="google_translate_element"></div>
        </StyledTranslate>

    </StyledHeader>
);
}


interface MenuButtonProps {
  isactive?: boolean;
}
const sheen = keyframes`
  0% {
    left: -200%;
  }
  100% {
    left: 200%;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;
const StyledHeader = styled.div`
  display: flex;
  background-color: #113c2b;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  border-bottom: 0px solid #0AC18E;
  height: 145px;
  justify-content: center;
`
const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledTitle = styled.div`
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  .cash {
    color: #4caf50;
  }
`
const StyledUserAddress = styled.div`
  position: absolute;
  color: white;
  width: 100%;
  height: 20px;
  top: 38px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`
const StyledDisconnectButton = styled.div`
  position: relative;
  color: white;
  width: 100px;
  height: 35px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  z-index: 1;
`
const StyledLogo = styled.div`
  background-image: url(${HelpMeLogo});
  background-size: cover;
  text-align: center;
  width: 150px;
  height: 150px;
  position: relative;
  top: -10px;
  z-index: 0;
  @media (max-width: 615px) {
    opacity: 0;
  }
`
const StyledMenu = styled.div`
  display: flex;
  position: relative;
  left: 0px;
  gap: 10px;
  @media (max-width: 832px) {
    font-size: 16px;
    gap: 5px;
  }
`
const StyledTranslate = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  right: 15px;
  z-index: 15;
`
const MenuButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#4CAF50' : '#111')};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #6ac76d;
  }
`
const WalletButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#4CAF50' : '#111')};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #6ac76d;
  }
`
const MenuButtonDisabled = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#4CAF50' : '#111')};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: not-allowed;
  &:hover {
    background-color: #d30000;
  }
`
const ConnectButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#4CAF50' : '#111')};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    background-color: #6ac76d;
  }
  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, rgba(10, 193, 142, 0) 25%, rgba(10, 193, 142, 0.5) 50%, rgba(10, 193, 142, 0) 75%);
    animation: ${sheen} 1.5s infinite linear;
  }
`
const DisconnectButton = styled.button`
  background-color: #111;
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  opacity: 1.0;
  &:hover {
    background-color: #d30000;
  }
`
//delete?
const GettingStartedButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;
const FAQButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;
const HomeButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;
const EventsButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;


export default Header;