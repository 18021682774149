import { Contract, Utxo, ElectrumNetworkProvider, SignatureTemplate } from 'cashscript';
import { hexToBin, cashAddressToLockingBytecode, OpcodesBCH, decodeTransaction, createVirtualMachineBCH, summarizeDebugTrace, stringifyDebugTraceSummary, encodeCashAddress } from '@bitauth/libauth';
import { AddressBadgerMarket, AddressTokensBadgerMarket, MasterCategoryID } from '../constants/values'

interface MarketBuyBadgerParams {
  electrumServer: ElectrumNetworkProvider | undefined;
  usersAddress: string;
  contractBadgerMarket: Contract | undefined;
  toTokenAddress: (address: any) => string;
  signTransaction: (options: any) => Promise<unknown>;
  badger: Utxo;
}
interface TokenDetails {
  amount: bigint;
  category: string;
  nft?: {
      capability: 'none' | 'mutable' | 'minting';
      commitment: string;
  };
}

async function marketBuyBadger({ electrumServer, usersAddress, contractBadgerMarket, toTokenAddress, signTransaction, badger }: MarketBuyBadgerParams) {

    const LEtoBE = (hex: string) => { 
      const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
      return bigEndianHex;
    };

    function hexLEToBigInt(hexLE: string): bigint {
      const hexBE = hexLE.match(/.{2}/g)?.reverse().join('') ?? '0';  // Convert the hex string from little-endian to big-endian
      return BigInt('0x' + hexBE);  // Convert the big-endian hex string directly to a BigInt
    }

    function hexToUint8Array(hexString: any) {
      const bytes = new Uint8Array(hexString.length / 2);
      for (let i = 0; i < bytes.length; i++) {
          bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
      }
      return bytes;
    }

    const hexLEToDecimal = (hex: string): number => {
      // Convert hex to big-endian and then to decimal
      const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
      return parseInt(bigEndianHex, 16);
    };

  if (electrumServer && contractBadgerMarket) { // require electrumServer has been setup, connected to blockchain, and contractBadger is compiled

    //input0 = badgerNFT | input1 = paymentBCH
    //output0 = badgerNFT | output2 = sellerPayment | output3 = buyerChange

    // Get NFT's list price from badgerUTXO
    const listPrice = hexLEToBigInt(badger.token?.nft?.commitment.substring(40, 50) ?? "0");
    // Get NFT's ID
    const badgerID = hexLEToDecimal(badger.token?.nft?.commitment.substring(74, 80) ?? "0");
    
    // Get users UTXO for transaction          
    const userUtxos = await electrumServer.getUtxos(usersAddress);
    console.log('get user UTXOs: ');
    console.log(userUtxos);

    //filter to only those that have (listPrice + 2000sats fee) and no tokens/NFTs on them
    const userValidUtxos = userUtxos.filter(
      val => !val.token && val.satoshis >= listPrice + 2000n,
    )
    console.log('valid user UTXOs: ');
    console.log(userValidUtxos);

    //select first valid found utxo
    const userUTXO = userValidUtxos[0];
    if (!userUTXO) {
      console.log('No utxos with listPrice+fee found. You may need to consolidate your utxos.');
      return;
    }
    console.log('selected user UTXO: ');
    console.log(userUTXO);

  //##  Build transaction        
  //##########################################

    const restCommitment = badger.token?.nft?.commitment.substring(50, 80);
    const newCommitment = '00000000000000000000000000000000000000000000000000' + restCommitment;

    //badgerNFT output details
    const badgerDetails: TokenDetails = {          
      amount: badger.token?.amount!,  
      category: badger.token?.category!,  
      nft: {
        capability: 'none', 
        commitment: newCommitment   
      }
    };

    const sellersAddressLE = badger.token?.nft?.commitment.substring(0, 40)!;   // Get sellers pubkeyhash
    //const sellersAddressBE = LEtoBE(sellersAddressLE);                          // Convert to BE
    const sellersAddress = encodeCashAddress("bitcoincash", "p2pkh", hexToUint8Array(sellersAddressLE));  //Encode into bitcoincash: address
    console.log('sending listPrice of ' + (listPrice) + ' sats to sellersAddress: ' + sellersAddress);

    const usersTokenAddress = toTokenAddress(usersAddress);
    const userSig = new SignatureTemplate(Uint8Array.from(Array(32)));           // empty signature as placeholder for building process. walletconnect will replace sig later

    let transaction: any;
    try {
      transaction = contractBadgerMarket?.functions.buy()                      
        .from(badger)                                                               // badger utxo
        .fromP2PKH(userUTXO, userSig)                                               // users chosen utxo + placeholder empty userSignature
        .to(usersTokenAddress, badger.satoshis, badgerDetails)                      // send badgerUTXO to buyer
        .to(sellersAddress, listPrice)                                              // send buyers BCH to seller (minus tx fee)
        .withoutChange()                                                            // disable automatic change output back to user (see next)
        .withoutTokenChange()

      const changeAmount = (userUTXO.satoshis - 2000n);                             // how many sats remain on users utxo after removing 2000sats for NFT + miner fee
      if (userUTXO.satoshis >= listPrice + 3000n) {
        transaction.to(usersAddress, changeAmount);        // if change is over dust limit then send it back to user. if not, miners will keep it
      }
      console.log(transaction);

      const txid = await transaction.send();
      console.log(txid);

    } catch (error) {
      if (error instanceof Error) {
        console.error('Transaction failed:', error);
      } else {
        console.error('Transaction failed:', error);
      }
    }

    try {                                                                         // build the transaction we created
      const rawTransactionHex = await transaction.build();                                  
      const decodedTransaction = decodeTransaction(hexToBin(rawTransactionHex));            
      if (typeof decodedTransaction === "string") {
        alert("No suitable utxos found for minting. Try to consolidate your utxos!");
        throw ("No suitable utxos found for minting. Try to consolidate your utxos!");
      }
      decodedTransaction.inputs[1].unlockingBytecode = Uint8Array.from([]);     // set the to-be-walletconnect-signed input to empty again
      console.log('decodedTransaction: ');
      console.log(decodedTransaction);

      // construct new transaction object for SourceOutputs, for stringify & not to mutate current network provider 
      const listSourceOutputs = [{
        ...decodedTransaction.inputs[0],
        lockingBytecode: (cashAddressToLockingBytecode(contractBadgerMarket.address) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(badger.satoshis),
        contract: {
          abiFunction: (transaction as any).abiFunction,        // 'as any' type assertion to bypass typescript checking
          redeemScript: (contractBadgerMarket as any).redeemScript,
          artifact: (contractBadgerMarket as any).artifact,           // 'as any' type assertion to bypass typescript checking
        }
      }, {
        ...decodedTransaction.inputs[1],
        lockingBytecode: (cashAddressToLockingBytecode(usersAddress) as { bytecode: Uint8Array }).bytecode,
        valueSatoshis: BigInt(userUTXO.satoshis),
      }];

      //create transaction object to give for signing
      const wcTransactionObj = {
        transaction: decodedTransaction,
        sourceOutputs: listSourceOutputs,
        broadcast: false,
        userPrompt: "Buy Badger #" + badgerID + " for " + listPrice + "sats"
      };
      console.log(wcTransactionObj);

      //pass object to walletconnect for user to sign
      const signResult: any = await signTransaction(wcTransactionObj);

      return signResult;

    } catch (error) {
      console.log('tx build failed: ' + error);
    }
  }
}
  
export default marketBuyBadger;