import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Sidebar from '../../components/SideBar'; 


interface Category {
  name: string;
  questions: { question: string; answer: string; answerVisible: boolean }[];
}

interface StyledAnswerProps {
  visible: boolean;
}


const categories: Category[] = [
  {
    name: 'BadgerCoin',
    questions: [
      {
        question: 'What Is BadgerCoin?',
        answer: 'A proof-of-ownership memecoin on BitcoinCash. The MasterBadger has hoarded all the BadgerCoins! Distract him by temporarily locking up some of your shiny BCH! Once unlocked, reclaim your BCH and give some precious BadgerCoins a new home in your wallet. 😊',        
        answerVisible: false,
      },
      {
        question: 'What wallets should I use?',
        answer: 'Wallets that support the freedom of all BadgerCoins from the evil tyranny of the MasterBadger, of course! (CashTokens and WalletConnect support helps, too.) <br/> So far, these wallets have proven to be friendly to the liberation of Badgers: \n\
        <ul><li><a href="https://cashonize.com/">Cashonize</a>__✅locks ✅unlocks ✅show BadgerCoins ✅show HoneyNFTs</li> \n\
        <li><a href="https://www.paytaca.com/">Paytaca</a>_____✅locks ✅unlocks ✅show BadgerCoins ✅show HoneyNFTs</li> \n\
        <li><a href="https://zapit.io/">Zapit</a>________✅locks ✅unlocks ❌show BadgerCoins ❌show HoneyNFTs</li></ul> <br/> \n\
        You can also use <a href="https://electroncash.org/">Electron Cash</a> to manage your CashTokens, but it doesn\'t currently support WalletConnect so you can\'t use it to interact with the <a href="https://badgers.cash">Badgers.Cash</a> website. <p/> \n\
        Note: if approvals don\'t appear on your wallet try fully closing and re-opening the wallet app then opening up the walletconnect section.',
        answerVisible: false,
      },
      {
        question: 'How does it work?',
        answer: 'You lock BCH to the BadgerCoin contract which creates a new UTXO on the contract. That UTXO remembers your wallet address, the amount of BCH you locked, the amount of BadgerCoins you will receive, which block you locked at, and how many blocks it will be locked for. \n\
        When unlocking, the contract only allows sending the locked BCH and BadgerCoins back to the same wallet address that originally locked it.',
        answerVisible: false,
      },
      {
        question: 'How long can you lock for?',
        answer: 'A maximum of 32,768 blocks (roughly 227 days). The contract enforces that locks longer than that cannot be created.',
        answerVisible: false,
      },
      {
        question: 'Can you unlock early? What about extreme circumstances?',
        answer: 'No. It\'s impossible to unlock early.',
        answerVisible: false,
      },
      {
        question: 'What is the minimum and maximum BCH that can be locked?',
        answer: 'Minimum: 0.00005 BCH. This gives enough sats to allow for the miner fees to be covered when you Unlock and for the UTXO\'s required for the contract. However, this amount would take 20,000 blocks (~139 days) before it could be unlocked. <br/>\n\
        Maximum: No limit.',
        answerVisible: false,
      },
      {
        question: 'What can you do with BadgerCoin?',
        answer: 'Bask in the warm, fuzzy feeling of rescuing these critters from the evil MasterBadger! Then burn your BadgerCoins — err, send them off to a luxurious farm — to snag discounts on upcoming HoneyBadger NFTs. Who knows what else these Badgers may learn to do?',
        answerVisible: false,
      },
      {
        question: 'Is minting active?',
        answer: 'No for tokens, yes for NFTs. <p/> \n\
        Tokens (like BadgerCoin) can only be created in their first transaction, the \'genesis\' transaction, and no more can be created. The entire supply of BadgerCoins was given to the masterBadger UTXO on the BadgerCoin staking contract. <p/> \n\
        HoneyBadger NFTs will be the same categoryID as BadgerCoin itself. The admin currently has control of a minting NFT for testing, and eventual release of the HoneyBadger NFT series, the marketplace, and any other contracts that come into existence. \n\
        The long-term plan is for the admin to give up the minting NFT. The plan for this will be decided before launching the HoneyBadgers NFT. <p/> \n\
        Note: HoneyNFTs, which also share the HoneyBadger categoryID, are continously minted by the BadgerCoin contract. However, every HoneyNFT shares ID #0. So the HoneyBadger categoryID will look like this:<br/> \n\
        <ul><li>ID 0: HoneyNFTs</li>\n\
        <li>ID 1-10000: HoneyBadgers</li></ul>',
        answerVisible: false,
      },
      {
        question: 'Is BadgerCoin \'multi-threaded\'?',
        answer: 'No. The BadgerCoin contract has a single address with an evil, greedy UTXO called the \'MasterBadger\'. Every new lock transaction must include the MasterBadger since it holds the remaining undistributed BadgerCoins in its grubby little paws. \n\
        This design means it\'s possible that your lock transaction gets discarded, causing it to never happen, if two competing transaction chains occur and \'your chain\' loses. \n\
        <p /> \n\
        At worst, you try to lock some BCH and it fails, causing your BCH to never leave your wallet. You pay nothing in fees if this happens, and you can simply try locking again.',
        answerVisible: false,
      },
      {
        question: 'So why go \'single-threaded\'?',
        answer: 'For one, it was simpler and easier to learn. <br/> \n\
        I also figured it wouldn\'t hurt to have a simple example of a single-threaded dapp to see just how good or bad the experience was.',
        answerVisible: false,
      },
      {
        question: 'Who created BadgerCoin?',
        answer: 'Sayoshi Nakamario',
        answerVisible: false,
      },
      {
        question: 'What\'s the point?',
        answer: 'To learn! I\'m seeing how far I can push myself and CashTokens.',
        answerVisible: false,
      },
      {
        question: 'BadgerCoin Contract Address?',
        answer: 'Address: bitcoincash:pvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgl3tq982v <br\> \n\
        Token Address: bitcoincash:rvgcl3xk6nwqlngkk09e7g67x5vxs57jv6v2q4qm4ct5yv4d3ppfgdzhpuxn8 <br\>',
        answerVisible: false,
      },
      {
        question: 'BadgerCoin CategoryID?',
        answer: '242f6ecedb404c743477e35b09733a56cacae34f3109d5cee1cbc1d5630affd7',
        answerVisible: false,
      },
      {
        question: 'What are the \'Locked UTXOs\' on the Home page?',
        answer: 'Lock ups (UTXOs) that are currently on the BadgerCoin contract. They have a link to the transactionID that created the UTXO, what block number the UTXO \n\
        is locked until, how much BCH was locked, how much BadgerCoin they will receive, and either a countdown that estimates when the UTXO will be unlockable, or an \'Unlock\' button.',
        answerVisible: false,
      },
      {
        question: 'What happens if I click \'Unlock\' on one of the Locked UTXOs on the Home page?',
        answer: 'You send the BCH and earned BadgerCoin on that UTXO back to whatever address started the lockup. You don\'t need to have a wallet connected to the website to do this. \n\
        You don\'t even need to know what cryptocurrency is.🙂',
        answerVisible: false,
      },
      {
        question: 'How does CashTokens work?',
        answer: 'CashTokens was activated on the BitcoinCash blockchain in May 2023. It created new support for fungible tokens and NFTs inside of BCH transactions, which miners see and validate. \n\
        This is different than other UTXO blockchains, like BTC\'s Ordinals, where token data is not verified as part of the transaction and requires people to run separate indexing servers to keep track of UTXO\'s with token balances. \n\
        BitcoinCash experimented with that method for tokens and NFTs in the past with the Simpleledger protocol (which is still running), but indexing servers have their own scaling problems.',
        answerVisible: false,
      },
      {
        question: 'Where can I find more information about BCH?',
        answer: 'There\'s this great site called <a href="https://helpme.cash">helpme.cash</a> that might help. 😄\n\
        <p />\n\
        Jeremy from the BitcoinCashPodcast has also created a <a href="https://bitcoincashpodcast.com/faqs">large FAQ</a>.',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'Honey',
    questions: [
      {
        question: 'What is Honey?',
        answer: 'A delicious blend of both NFT and fungible token! Kind of. <br />\n\
        Each time you unlock and earn BadgerCoins, you also earn one HoneyNFT. That HoneyNFT will have a balance equal to the amount of BadgerCoins you earned when you did the unlock.',
        answerVisible: false,
      },
      {
        question: 'Is it a separate UTXO?',
        answer: 'Yes, when you unlock you will receive the HoneyNFT and BadgerCoins on different UTXOs.',
        answerVisible: false,
      },
      {
        question: 'Any plans for the HoneyNFTs?',
        answer: 'Nothing promised, but merging multiple HoneyNFT balances together into a single NFT would be easy to setup. It could be effectively used as its own token/currency in other projects. \n\
        It could also be setup to convert into a real fungible CashToken, but that may not be necessary.',
        answerVisible: false,
      },
      {
        question: 'So there ARE plans?',
        answer: 'Quick, look over there! A badger needs some delicious honey!',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'HoneyBadger NFTs',
    questions: [
      {
        question: 'What are the HoneyBadger NFTs?',
        answer: 'A collection of 10,000 NFTs with generative honeybadger art and stats with randomly-assigned values.',
        answerVisible: false,
      },
      {
        question: 'Why?',
        answer: 'It\'s basically a fundraiser to keep me going and learning how to do contracts on BitcoinCash. There are future goals with this and other projects, but nothing that I can guarantee.',
        answerVisible: false,
      },
      {
        question: 'Mint price?',
        answer: '0.03 BCH, or 0.015 BCH if you also pay 10 BadgerCoins which you can obtain by temporarily locking up BCH at <a href="https://badgers.cash">badgers.cash</a>.',
        answerVisible: false,
      },
      {
        question: 'Is there rarity for the art layers? Do they matter?',
        answer: 'Yes, the layers have rarity differences. <br\>\n\
        No, the layer rarities don\'t currently matter. <br\>\n\
        No promises, but the layer rarities <i>might</i> matter in the future.',
        answerVisible: false,
      },
      {
        question: 'What are the level and stats for?',
        answer: 'Nothing! For now. They are saved inside each NFT so they cannot be changed, unless future contracts are created to allow you to spend experience \n\
        you acquire from somewhere to upgrade them...',
        answerVisible: false,
      },
      {
        question: 'How were the stats created for the NFTs?',
        answer: 'Each HoneyBadger NFT was created with a random amount of points (from 15 to 40) which were then randomly spent to increase their stats. This does mean some HoneyBadger NFT\'s have more stat\n\
        points than others. Stats did <b>not</b> take into account the NFT art rarity.',
        answerVisible: false,
      },
      {
        question: 'Any plans for the HoneyBadger NFTs?',
        answer: 'A HoneyBadger marketplace contract which will allow you to list HoneyBadgers for a sale price for a small fee. Nothing else promised currently. <br\>\n\
        Unpromised... I was previously learning some basic game dev (<a href="https://twitter.com/SayoshiHelpMe/status/1686549079101485056">see here</a>) which I was starting \n\
        to integrate into a <a href="https://fogofwar.quest">past EVM project</a>. While not promised, I do want to explore this further on CashTokens. ',
        answerVisible: false,
      },
      {
        question: 'So there ARE plans?',
        answer: 'Quick, look over there! A badger needs adopting!',
        answerVisible: false,
      },
      {
        question: 'Release date?',
        answer: 'August 7, 2024',
        answerVisible: false,
      },
      {
        question: 'Are there stat or level limits?',
        answer: 'Intentions are currently a max level of 100 and a max stat value of 100.',
        answerVisible: false,
      },
      {
        question: 'Is the HoneyBadger art unique to this project?',
        answer: 'No, the base art was taken from <a href="https://honeybadgers.btcmint.app/">BTC HoneyBadgers</a>. Some traits were removed, some updated, and new ones added.',
        answerVisible: false,
      },
      {
        question: 'What license is the art under?',
        answer: 'CC0. Feel free to use the art however you wish, including for commercial uses. No need to attribute.',
        answerVisible: false,
      },
      {
        question: 'Is minting active?',
        answer: 'No for tokens, yes for NFTs. <p/> \n\
        Tokens (like BadgerCoin) can only be created in their first transaction, the \'genesis\' transaction, and no more can be created. The entire supply of BadgerCoins was given to the masterBadger UTXO on the BadgerCoin staking contract. <p/> \n\
        HoneyBadger NFTs will be the same categoryID as BadgerCoin itself. The admin currently has control of a minting NFT for testing, and eventual release of the HoneyBadger NFT series, the marketplace, and any other contracts that come into existence. \n\
        The long-term plan is for the admin to give up the minting NFT. The plan for this will be decided before launching the HoneyBadgers NFT. <p/> \n\
        Note: HoneyNFTs, which also share the HoneyBadger categoryID, are continously minted by the BadgerCoin contract. However, every HoneyNFT shares ID #0. So the HoneyBadger categoryID will look like this:<br/> \n\
        <ul><li>ID 0: Every HoneyNFT</li>\n\
        <li>ID 1-10000: HoneyBadgers</li></ul>',
        answerVisible: false,
      },
    ],
  },
  // Add more categories...
];

const FAQ = () => {
  const defaultSelection = categories[0];
  const [selectedCategory, setSelectedCategory] = useState<null | Category>(defaultSelection);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const toggleQuestion = (index: number) => {
    setSelectedCategory((prevCategory) => {
      if (!prevCategory) return prevCategory; 
  
      const newCategory: Category = {
        ...prevCategory, 
        questions: prevCategory.questions.map((q, idx) => {
          if (idx === index) {
            return { ...q, answerVisible: !q.answerVisible };
          }
          return q;
        }),
      };

      return newCategory;
    });
  };

  return (
    <FAQContainer>
 
      <FAQContent>
        <Sidebar categories={categories} onCategoryClick={handleCategoryClick} />
        {selectedCategory ? (
          <StyledUL>
            {selectedCategory.questions.map((faq, index) => (
              <li key={index}>
                <StyledTitle onClick={() => toggleQuestion(index)}>
                  {faq.question}
                </StyledTitle> 
                <StyledAnswer visible={faq.answerVisible}>
                  <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                </StyledAnswer>
              </li>
            ))}
          </StyledUL>
        ) : (
          <></>
        )}
      </FAQContent>
    </FAQContainer>
  );
};

const StyledUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  word-wrap: break-word;
  @media (max-width: 800px) {
    left: -170px;
  }
`;
const StyledTitle = styled.button`
  display: flex;
  background-color: #113c2b;
  color: white;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px; 
  padding: 5px 10px;  
  cursor: pointer;
  text-align: left;    
  max-width: 1200px;
  min-width: 370px;     
  word-wrap: break-word;
  margin-bottom: 5px;  // Some space between title and answer
  &:hover {
    background-color: #6ac76d; /* Background color on hover */
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
const StyledAnswer = styled.div<StyledAnswerProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  border: 1px solid #fff;
  background-color: #111;
  color: white;
  font-size: 14px;
  margin-bottom: 5px; 
  border-radius: 5px;
  margin-left: 15px;
  max-width: 1200px;
  word-wrap: break-word;
  text-align: left;
  padding-left: 10px;
  line-height: 18px;
  font-size: 16px;
  a {
    color: #09b182;
    &:hover {
      color: #0ad69e;
    }
  }
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
const FAQContainer = styled.div`
background-color: #222;
height: 100%;
min-height: 100vh;
width: 100%;
color: white;
text-align: left;
border-top: 2px solid #fff;
`;
const FAQContent = styled.div`
display: flex;
margin-left: 220px; /* Adjust this value to match the width of the sidebar */
padding: 20px;
background-color: #222;
border: 0px solid red;
@media (max-width: 800px) {
  margin-left: 120px;
}
`;

export default FAQ;
